import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { ReplaceStep } from "prosemirror-transform";
import { useAbbreviations } from "contextproviders/Abbreviations";
import { saveStatisticsOnTypedWord } from "contextproviders/AbbreviationStatistics";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    abbreviations: {
      /**
       * Abbreviate the word currently behind the cursor if possible
       */
      abbreviateAtSelection: () => ReturnType;
      /**
       * Toggles between adding a space or word-joiner without abbreviating
       * 1 click = space, 2 clicks = word-joiner
       */
      toggleWordBreakWithoutAbbreviating: () => ReturnType;
    };
  }
}

export const expandTriggerCharacter = new RegExp(
  /[.,!?:;"\-()'@/\u2060\u00A0 ]/
);
const noneExpandTriggerCharacters = new RegExp(
  /([^.,!?:;"\-()'@/\u2060\u00A0 ]+)/
);
const startOfWord = new RegExp(`(^|${expandTriggerCharacter.source})`);

const inputRuleRegexp = new RegExp(
  `${startOfWord.source}${noneExpandTriggerCharacters.source}(${expandTriggerCharacter.source})$`
);

const abbreviateAtSelectionRegExp = new RegExp(
  `${startOfWord.source}${noneExpandTriggerCharacters.source}()$`
);

function getWordAndCapitalize(
  match: RegExpMatchArray,
  textBeforeCursor: string
) {
  const abbreviation = match[2];
  if (textBeforeCursor) {
    saveStatisticsOnTypedWord(textBeforeCursor, abbreviation);
  }
  let word = useAbbreviations
    .getState()
    .mergedList.abb2expanded.get(abbreviation?.toLowerCase())?.[1];

  if (word) {
    if (
      abbreviation.length > 1 &&
      abbreviation.search(/.+[a-zA-ZåäöÅÄÖ]/) !== -1 &&
      abbreviation.toUpperCase() === abbreviation
    ) {
      word = word.toUpperCase();
    } else if (abbreviation[0].toLowerCase() !== abbreviation[0]) {
      word = word[0].toUpperCase() + word.substring(1);
    }
    return match[1] + word + match[3];
  }
  return "";
}

export const Abbreviations = Extension.create({
  name: "abbreviations",
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("abbreviationsPlugin"),
        appendTransaction(transactions, oldState, newState) {
          const transaction = transactions[0];
          if (transaction.getMeta("avoidAbbreviation")) {
            return;
          }
          const step = transaction.steps[0];
          if (step instanceof ReplaceStep) {
            if (
              step.slice.size === 1 &&
              oldState.doc.content.size + 1 === newState.doc.content.size
            ) {
              const text = step.slice.content.firstChild?.text;
              if (text?.length === 1 && text.match(expandTriggerCharacter)) {
                const currentLine = newState.selection.$from.node(1);
                const textBeforeCursor = currentLine?.textContent.slice(
                  0,
                  newState.selection.$from.parentOffset
                );
                const match = textBeforeCursor.match(inputRuleRegexp);
                if (match) {
                  const replace = getWordAndCapitalize(match, textBeforeCursor);
                  if (replace) {
                    const current = match[0];
                    return newState.tr
                      .insertText(
                        replace,
                        newState.selection.from - current.length,
                        newState.selection.from
                      )
                      .setMeta("avoidAbbreviation", true)
                      .setMeta(
                        "appendTransactionHighlight",
                        transactions.reduce<boolean>(
                          (prev, cur) =>
                            !!prev ||
                            !!cur.getMeta("appendTransactionHighlight"),
                          false
                        )
                      )
                      .setMeta(
                        "appendTransactionTimestamp",
                        transactions.reduce<boolean>(
                          (prev, cur) =>
                            !!prev ||
                            !!cur.getMeta("appendTransactionTimestamp"),
                          false
                        )
                      );
                  }
                }
              }
            }
          }
        },
      }),
    ];
  },
  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        editor.commands.abbreviateAtSelection();
        return false;
      },
      Tab: ({ editor }) => editor.commands.toggleWordBreakWithoutAbbreviating(),
    };
  },
  addCommands() {
    return {
      abbreviateAtSelection:
        () =>
        ({ commands, editor }) => {
          const currentLine = editor.state.selection.$from.node(1);
          const textBeforeCursor = currentLine?.textContent.slice(
            0,
            editor.state.selection.$from.parentOffset
          );
          const match = textBeforeCursor?.match(abbreviateAtSelectionRegExp);
          if (match) {
            const word = getWordAndCapitalize(match, textBeforeCursor);
            if (word === "") {
              return true;
            }

            const pos = editor.state.selection.$from.pos;
            commands.insertContentAt(
              { from: pos - match[0].length, to: pos },
              word
            );
          }
          return true;
        },
      toggleWordBreakWithoutAbbreviating:
        () =>
        ({ commands, editor, chain }) => {
          const pos = editor.state.selection.from;
          if (pos === 0) {
            return commands.insertContent(" ");
          }

          const previousCharacter = editor.state.doc.textBetween(pos - 1, pos);
          if (previousCharacter === "\u00A0" || previousCharacter === " ") {
            return chain()
              .insertContentAt({ from: pos - 1, to: pos }, "\u2060")
              .setMeta("avoidAbbreviation", true)
              .run();
          } else if (previousCharacter === "\u2060") {
            return chain()
              .insertContentAt({ from: pos - 1, to: pos }, "\u00A0", {
                parseOptions: { preserveWhitespace: true },
              })
              .setMeta("avoidAbbreviation", true)
              .run();
          }

          return chain()
            .insertContent("\u00A0", {
              parseOptions: { preserveWhitespace: true },
            })
            .setMeta("avoidAbbreviation", true)
            .run();
        },
    };
  },
});
