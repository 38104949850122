import { ReactElement } from "react";
import type { AbbrevationStats } from "contextproviders/AbbreviationStatistics";
import {
  Typography,
  Box,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { avoidedKeyStrokes, avoidableKeyStrokes } from "./utils";
import { useFilterOptions } from "./FilterOptions";

interface Props {
  stats: AbbrevationStats;
}

export default function Summary({ stats }: Props): ReactElement {
  const { t } = useTranslation();
  const selectedSession = useFilterOptions((state) => state.selectedSession);
  const fromDate = useFilterOptions((state) => state.fromDate);
  const toDate = useFilterOptions((state) => state.toDate);
  const Navoided = avoidedKeyStrokes(stats);
  const Navoidable = avoidableKeyStrokes(stats);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h6">{`${t("Summary for session")}: ${t(
        selectedSession
      )}`}</Typography>
      <TableContainer>
        <Table size="medium">
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>{t("From date")}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" noWrap>{`${fromDate.toLocaleString(
                  undefined,
                  { dateStyle: "short", timeStyle: "short" }
                )}`}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography>{t("To date")}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" noWrap>
                  {toDate.toLocaleString(undefined, {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  {t("Avoided keystrokes thanks to abbreviations")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  {Navoided}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  {t(
                    "Unnessesary keystrokes where abbreviations could have been used"
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>{Navoidable}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
