import { useState, useContext, useEffect } from "react";
import {
  emptyStatsChunk,
  useAbbreviationsLocalChunk,
  useAbbreviationStats,
} from "contextproviders/AbbreviationStatistics";
import { mergeStats, uniqueSessionNames } from "./utils";
import FilterOptions, { useFilterOptions } from "./FilterOptions";
import Summary from "./Summary";
import UsedAbbreviations from "./UsedAbbreviations";
import MissedAbbreviations from "./MissedAbbreviations";

export default function AbbreviationStatistics() {
  const stats = useAbbreviationStats((state) => state.stats);
  const localStatsChunk = useAbbreviationsLocalChunk(
    (state) => state.localStatsChunk
  );
  const selectedSession = useFilterOptions((state) => state.selectedSession);
  const fromDate = useFilterOptions((state) => state.fromDate);
  const toDate = useFilterOptions((state) => state.toDate);
  const [filteredStats, setFilteredStats] = useState(emptyStatsChunk());

  useEffect(() => {
    let filtered = stats.filter(
      (stat) =>
        new Date(stat.createdAt) > fromDate && new Date(stat.createdAt) < toDate
    );
    const now = new Date();
    if (now > fromDate && now < toDate) {
      filtered.push(localStatsChunk);
    }
    filtered = filtered.filter(
      (stat) =>
        selectedSession === "all" || stat.sessionName === selectedSession
    );
    setFilteredStats(mergeStats(filtered));
  }, [fromDate, toDate, stats, selectedSession]);

  useEffect(() => {
    const allSessions = ["all", ...uniqueSessionNames(stats)];
    useFilterOptions.setState({ allSessions });
  }, [stats]);

  return (
    <>
      <FilterOptions>
        <Summary stats={filteredStats} />
      </FilterOptions>
      <UsedAbbreviations stats={filteredStats} />
      <MissedAbbreviations stats={filteredStats} />
    </>
  );
}
