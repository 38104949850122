import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteProps,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  GridDirection,
  GridSize,
  Radio,
  RadioGroup,
  TextField,
  TextFieldProps,
  Checkbox as MuiCheckbox,
  Chip,
  GridProps,
  Typography,
  FormHelperText,
  IconButton,
  styled,
  darken,
} from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system/styleFunctionSx";
import {
  Formik,
  useFormikContext,
  FormikContextType,
  Field as FormikField,
  FormikValues,
  FormikConfig,
  FieldArray as FormikFieldArray,
  FastField,
  FieldArrayRenderProps,
  useField,
} from "formik";
import React, {
  InputHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

/**
 * `<input type="datetime-local">` wants a particular string format in local time such as
 *
 * "2021-12-15T20:15"
 *
 * or
 *
 * "2021-12-15T20:15:34"
 *
 * which is almost just date.toISOString() but not quite.
 */
export function toLocaleDateString(input?: Date | number | string) {
  if (!input) {
    return localIsoString(new Date()).slice(0, -8);
  } else if (typeof input === "string") {
    return localIsoString(new Date(Date.parse(input))).slice(0, -8);
  } else if (typeof input === "number") {
    return localIsoString(new Date(input)).slice(0, -8);
  } else {
    return localIsoString(input).slice(0, -8);
  }
}

function localIsoString(d: Date) {
  return new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString();
}

export function fromLocaleDateString(date: string) {
  return Date.parse(date.replace("T", " "));
}

type PathsToStringOrNumberProps<T> = T extends string | number | Array<any>
  ? []
  : {
      [K in Extract<keyof T, string>]: [K, ...PathsToStringOrNumberProps<T[K]>];
    }[Extract<keyof T, string>];

type Join<T extends string[], D extends string> = T extends []
  ? never
  : T extends [infer F]
  ? F
  : T extends [infer F, ...infer R]
  ? F extends string
    ? `${F}${D}${Join<Extract<R, string[]>, D>}`
    : never
  : string;

type DottedLanguageObjectStringPaths<I extends Record<string, any>> = Join<
  PathsToStringOrNumberProps<I>,
  "."
>;

interface ExtraProps<T> {
  direction?: ResponsiveStyleValue<GridDirection>;
  children: ReactNode;
  onChange?: (values: T) => void;
}

type FormikOnChangeProps<T> = {
  onChange: (values: T) => void;
};

function FormikOnChange<T>({ onChange }: FormikOnChangeProps<T>) {
  const formik = useFormikContext<T>();
  useEffect(() => {
    onChange(formik.values);
  }, [formik.values]);

  return null;
}

export function Form<Values extends FormikValues = FormikValues>({
  onSubmit,
  direction,
  onChange,
  ...props
}: FormikConfig<Values> & ExtraProps<Values>): JSX.Element {
  const mountRef = useRef(true);
  useEffect(() => {
    return () => {
      mountRef.current = false;
    };
  }, []);
  return (
    <Formik<Values>
      {...props}
      onSubmit={async (values, helpers) => {
        helpers.setStatus("");
        const error = await onSubmit(values, helpers);
        if (!mountRef.current) {
          return;
        }
        helpers.setSubmitting(false);
        if (error) {
          helpers.setStatus(error);
        }
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} padding={1} direction={direction}>
            {props.children}
          </Grid>
          {onChange ? <FormikOnChange onChange={onChange} /> : null}
        </form>
      )}
    </Formik>
  );
}

export function Status({ width }: { width?: boolean | GridSize }) {
  const { status, isSubmitting } = useFormikContext();
  if (!status && !isSubmitting) {
    return null;
  }

  const error = status?.error;
  const info = status?.info;
  const text = status && typeof status === "string" && status;
  const success = status?.success;
  /*  (
    <SuccessMessage loading={isSubmitting} message={status?.success} />
  ); */

  return (
    <Grid
      item
      xs={12}
      sm={width}
      sx={{ color: status?.error ? "red" : "inherit" }}
    >
      {error || info || text || success}
    </Grid>
  );
}

export function Dirty({ children, ...props }: PropsWithChildren<GridProps>) {
  const { dirty } = useFormikContext();

  if (!dirty) {
    return null;
  }

  return (
    <Grid container item {...props}>
      {children}
    </Grid>
  );
}

export function SubmitButton({
  label,
  width,
  variant = "contained",
}: {
  label: string;
  width?: boolean | GridSize;
  variant?: "text" | "outlined" | "contained" | undefined;
}) {
  const formik = useFormikContext();

  return (
    <Grid item xs="auto" sm={width}>
      <Button variant={variant} type="submit" disabled={formik.isSubmitting}>
        {label} {formik.isSubmitting && <CircularProgress size={15} />}
      </Button>
    </Grid>
  );
}

interface BaseFieldProps {
  name: string;
  label?: string;
  width?: boolean | GridSize;
  hide?: (formik: FormikContextType<any>) => boolean;
}

interface FieldProps extends BaseFieldProps {
  readonly?: boolean;
  type?: InputHTMLAttributes<unknown>["type"];
  valueTransform?: (val: any) => any;
  onValueChange?: (
    formik: FormikContextType<any>,
    e: React.ChangeEvent<any>
  ) => void;
}

interface RadioSelectProps extends BaseFieldProps {
  options: string[];
}

interface CheckboxProps extends BaseFieldProps {
  onValueChanged?: (checked: boolean, formik: FormikContextType<any>) => void;
}

interface SelectFieldProps<
  T extends string | { name: string; _id: string | number }
> extends BaseFieldProps {
  options: T[];
  onValueChanged?: (value: T, formik: FormikContextType<any>) => void;
  nullable?: boolean;
  autoFocus?: boolean;
}

interface FieldArrayProps extends BaseFieldProps {
  defaultValue: any;
  children: (
    name: (name: string) => string,
    remove: () => void,
    helpers: FieldArrayRenderProps,
    index: number,
    arr: any[]
  ) => ReactNode;
  highlightFirst?: boolean;
}

/**
 * You can use onValueChanged(formik, event) to trigger a callback when the fields
 * value is changed
 */
export function Field({
  name,
  label,
  width,
  readonly,
  onValueChange,
  hide,
  valueTransform,
  ...props
}: FieldProps & TextFieldProps) {
  const formik = useFormikContext();

  if (hide?.(formik)) {
    return null;
  }

  if (readonly) {
    const { value } = formik.getFieldMeta(name);

    return (
      <Grid item xs={12} sm={width}>
        <Typography color="GrayText" variant="caption">
          {label}
        </Typography>
        <Typography>
          {props.type === "datetime-local"
            ? new Date(value as number).toLocaleString().slice(0, -3)
            : valueTransform?.(value) || (value as string)}
        </Typography>
      </Grid>
    );
  }

  const handleChange = (e: React.ChangeEvent<any>) => {
    onValueChange?.(formik, e);
    if (props.type === "datetime-local") {
      const value = Date.parse(e.target.value);
      formik.setFieldValue(name as string, value);
    } else {
      formik.handleChange(e);
    }
  };

  if (props.type === "datetime-local") {
    return (
      <Grid item xs={12} sm={width}>
        <FastField name={name}>
          {({ field, form, meta }: any) => (
            <TextField
              {...field}
              {...props}
              label={label}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              value={
                props.type === "datetime-local"
                  ? toLocaleDateString(meta.value)
                  : meta.value
              }
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
            />
          )}
        </FastField>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sm={width}>
      <FastField name={name}>
        {({ field, meta }: any) => (
          <TextField
            {...field}
            {...props}
            label={label}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            value={meta.value}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
          />
        )}
      </FastField>
    </Grid>
  );
}

export function RadioSelect({ label, name, options, width }: RadioSelectProps) {
  const formik = useFormikContext();
  const field = formik.getFieldMeta(name as string);

  return (
    <Grid item xs={12} sm={width}>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name={name as string}
          value={field.value}
          onChange={formik.handleChange}
        >
          {options.map((value) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio />}
              label={value}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}

export function Checkbox({
  label,
  name,
  width,
  hide,
  onValueChanged,
}: CheckboxProps) {
  const formik = useFormikContext();
  const field = formik.getFieldMeta(name as string);

  if (hide?.(formik)) {
    return null;
  }

  return (
    <Grid item xs={12} sm={width}>
      <FormGroup>
        <FormControlLabel
          control={
            <MuiCheckbox
              checked={field.value as boolean}
              onChange={(e, value) => {
                formik.setFieldValue(name, value);
                onValueChanged?.(value, formik);
              }}
            />
          }
          label={label || name}
        />
      </FormGroup>
    </Grid>
  );
}

interface ArrayFieldProps<T extends { name: string; _id: string }>
  extends BaseFieldProps {
  options: T[];
}

export function ArrayField<T extends { name: string; _id: string }>({
  name,
  label,
  options,
  width,
  ...props
}: ArrayFieldProps<T> & Partial<AutocompleteProps<T, true, boolean, false>>) {
  const formik = useFormikContext();

  return (
    <Grid item xs={12} sm={width}>
      <FormikField name={name}>
        {({ field }: any) => (
          <Autocomplete
            {...props}
            multiple
            id="tags-standard"
            options={options}
            getOptionLabel={(option) => option.name}
            value={options.filter((val) => field.value.includes(val._id))}
            onChange={(_e, value) => {
              formik.setFieldValue(
                name as string,
                value.map((val) => val._id)
              );
            }}
            renderInput={(params) => <TextField {...params} label={label} />}
            isOptionEqualToValue={(a, b) => a._id === b._id}
            filterSelectedOptions
          />
        )}
      </FormikField>
    </Grid>
  );
}

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.text.primary,
  backgroundColor: darken(theme.palette.background.default, 0.2),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

interface ArrayFieldWithSearchProps<T extends PartOption>
  extends BaseFieldProps {
  mainGroupName?: string;
  searchGroupName?: string;
  visibleOptions: T[];
  searchableOptions: T[];
  minSearchLength?: number;
  onSearchSelected?: (selected: T) => void;
}

type PartOption = { name: string; _id: string };

type AllOption = {
  group: string;
  disabled?: boolean;
};

export function ArrayFieldWithSearch<T extends PartOption>({
  name,
  label,
  visibleOptions,
  searchableOptions,
  width,
  mainGroupName,
  searchGroupName,
  minSearchLength,
  onSearchSelected,
  ...props
}: ArrayFieldWithSearchProps<T> &
  Partial<
    Omit<AutocompleteProps<T & AllOption, true, boolean, false>, "options">
  >) {
  const [field, meta, helpers] = useField<string[]>(name);

  const mainName = mainGroupName || "Favoriter";
  const searchName = searchGroupName || "Lägg till ny";

  const options = useMemo(
    () =>
      [
        ...visibleOptions.map((visible) => ({
          ...visible,
          group: mainName,
        })),
        ...searchableOptions.map((opt) => ({
          ...opt,
          group: searchName,
        })),
      ] as Array<T & AllOption>,
    [visibleOptions, searchableOptions]
  );

  const value = useMemo(
    () => options.filter((val) => field.value.includes(val._id)),
    [field.value]
  );

  return (
    <Grid item xs={12} sm={width}>
      <FormikField name={name}>
        {() => (
          <Autocomplete
            {...props}
            multiple
            options={options}
            getOptionLabel={(option) => option.name}
            value={value}
            onChange={(_e, value) => {
              const newOptions = value.filter(
                (val) => val.group === searchName
              );
              if (newOptions.length) {
                if (onSearchSelected) {
                  newOptions.forEach(onSearchSelected);
                }
              }
              helpers.setValue(value.map((val) => val._id));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
              />
            )}
            filterOptions={(options, state) => {
              if (!state.inputValue) {
                return options.filter((opt) => opt.group === mainName);
              } else if (state.inputValue.length < (minSearchLength ?? 5)) {
                return [
                  ...options.filter(
                    (opt) =>
                      opt.group === mainName &&
                      opt.name
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                  ),
                  {
                    name: `Sökning kräver minst ${minSearchLength ?? 5} tecken`,
                    _id: "info",
                    group: searchName,
                    disabled: true,
                  },
                ] as any;
              } else {
                return [
                  ...options.filter((opt) =>
                    opt.name
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  ),
                ];
              }
            }}
            groupBy={(option) => option.group}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            getOptionDisabled={(option) => !!option.disabled}
            isOptionEqualToValue={(a, b) => a._id === b._id}
            filterSelectedOptions
          />
        )}
      </FormikField>
    </Grid>
  );
}

function getId(value: string | { name: string; _id: string | number }) {
  if (typeof value === "string") {
    return value;
  }
  return value._id;
}

export function SelectField<
  T extends string | { name: string; _id: string | number }
>({
  name,
  label,
  options,
  width,
  nullable,
  onValueChanged,
  hide,
  autoFocus,
  ...props
}: SelectFieldProps<T> &
  Partial<
    AutocompleteProps<
      string | { name: string; _id: string | number },
      false,
      boolean,
      boolean
    >
  >) {
  const formik = useFormikContext();

  if (hide?.(formik)) {
    return null;
  }

  return (
    <Grid item xs={12} sm={width}>
      <FormikField name={name}>
        {({ field, meta }: any) => (
          <Autocomplete
            disableClearable={!nullable}
            {...props}
            id="tags-standard"
            options={options}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            value={options.find((val) => field.value === getId(val)) || null}
            onChange={(e, value) => {
              formik.setFieldValue(
                name,
                typeof value === "string"
                  ? value
                  : value?._id
                  ? value._id
                  : typeof getId(options[0]) === "number"
                  ? 0
                  : nullable
                  ? null
                  : ""
              );
              onValueChanged?.(value as unknown as T, formik);
            }}
            onInputChange={(_e, value) => {
              if (props.freeSolo) {
                formik.setFieldValue(name, value);
                onValueChanged?.(value as unknown as T, formik);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                autoFocus={autoFocus}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
              />
            )}
          />
        )}
      </FormikField>
    </Grid>
  );
}

export function FieldArray({
  name,
  label,
  width,
  defaultValue,
  children,
  highlightFirst,
}: FieldArrayProps) {
  const formik = useFormikContext();

  const meta = formik.getFieldMeta<Array<any>>(name);

  return (
    <FormikFieldArray
      name={name}
      render={(helpers) => {
        return (
          <Grid
            item
            container
            xs={12}
            sm={width}
            spacing={1}
            padding={1}
            direction="column"
          >
            <Grid item xs padding={1}>
              {label}
              <IconButton
                color="success"
                onClick={() => helpers.push(defaultValue)}
              >
                <Add />
              </IconButton>
            </Grid>
            {meta.value.map((value, groupIndex) => {
              return (
                <Grid
                  key={groupIndex}
                  container
                  item
                  xs
                  spacing={1}
                  padding={1}
                  sx={{
                    outline:
                      highlightFirst && groupIndex === 0
                        ? "black solid 3px"
                        : "grey solid 2px",
                    outlineOffset: -7,
                    borderRadius: 2,
                  }}
                  direction="column"
                  position="relative"
                >
                  <Grid
                    item
                    xs
                    container
                    padding={1}
                    spacing={1}
                    direction="row"
                  >
                    {children(
                      (field: string) => `${name}.${groupIndex}.${field}`,
                      () => helpers.remove(groupIndex),
                      helpers,
                      groupIndex,
                      meta.value
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        );
      }}
    />
  );
}

interface StringArrayProps extends BaseFieldProps {
  outline?: string;
}

export function StringArray({
  name,
  label,
  width,
  outline,
  children,
}: PropsWithChildren<StringArrayProps>) {
  const formik = useFormikContext();

  const meta = formik.getFieldMeta<Array<string>>(name);

  const [newValue, setNewValue] = useState("");

  const add = (helpers: FieldArrayRenderProps) => {
    if (newValue) {
      helpers.push(newValue);
      setNewValue("");
    }
  };

  return (
    <FormikFieldArray
      name={name}
      render={(helpers) => {
        return (
          <Grid
            item
            container
            xs={12}
            sm={width}
            spacing={1}
            padding={1}
            direction="row"
            sx={{
              outline: outline || "grey solid 2px",
              outlineOffset: -7,
              borderRadius: 2,
            }}
          >
            <Grid item container xs={12} spacing={1}>
              <Grid item xs="auto">
                {label}:
              </Grid>
              <Grid item xs>
                {meta.value.map((value, index) => (
                  <Chip
                    key={`${value}${index}`}
                    draggable={true}
                    onDragStart={(event) => {
                      event.dataTransfer.setData("index", `${index}`);
                      event.dataTransfer.dropEffect = "move";
                    }}
                    onDragEnter={(event) => event.preventDefault()}
                    onDragOver={(event) => event.preventDefault()}
                    onDrop={(event) => {
                      event.preventDefault();
                      const dragIndex = parseInt(
                        event.dataTransfer.getData("index")
                      );
                      const tmp = meta.value[dragIndex];
                      const newArray = meta.value.filter(
                        (v, i) => i !== dragIndex
                      );
                      newArray.splice(index, 0, tmp);
                      formik.setFieldValue(name, newArray);
                    }}
                    label={value}
                    onDelete={() => helpers.remove(index)}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item xs>
              <TextField
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                    add(helpers);
                  }
                }}
              />
            </Grid>
            <Grid item xs>
              <Button onClick={() => add(helpers)}>Lägg till</Button>
            </Grid>
            <Grid item xs>
              {children}
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

interface OptionsArrayProps extends StringArrayProps {}

export function OptionsArray({
  name,
  label,
  width,
  outline,
}: OptionsArrayProps) {
  const formik = useFormikContext();
  const [newValue, setNewValue] = useState("");

  const { value } = formik.getFieldMeta<Record<string, string[]>>(name);

  const options = useMemo(() => Object.keys(value || {}), [value]);

  const add = useCallback(() => {
    if (newValue) {
      formik.setFieldValue(name, { ...value, [newValue]: [] });
      setNewValue("");
    }
  }, [value, newValue]);

  return (
    <Grid container item xs={12} spacing={1}>
      {options.map((option, index) => (
        <StringArray
          key={`${option}${index}`}
          label={option}
          name={`${name}.${option}`}
        >
          <Button
            onClick={() => {
              const newValue = { ...value };
              delete newValue[option];
              formik.setFieldValue(name, newValue);
            }}
          >
            Ta bort
          </Button>
        </StringArray>
      ))}
      <Grid item xs>
        <TextField
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          label="Valbart alternativ"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
              add();
            }
          }}
        />
      </Grid>
      <Grid item xs>
        <Button onClick={add}>Lägg till</Button>
      </Grid>
    </Grid>
  );
}

export function FormFile({
  label,
  preview,
  name,
  accept,
}: Partial<HTMLInputElement> & {
  preview?: { image: string };
  label?: string;
}) {
  const [field, meta, helpers] = useField(name || "");
  const error = meta.touched && meta.error;

  delete field.value;

  if (preview?.image) {
    return <img src={preview.image} />;
  }

  return (
    <Grid item xs={12}>
      <FormGroup>
        {label && <FormLabel>{label}</FormLabel>}
        <input
          {...field}
          accept={accept}
          type="file"
          onChange={(event) => {
            helpers.setValue(event.currentTarget.files?.[0]);
          }}
        />
        <FormHelperText error={!!error}>{error}</FormHelperText>
      </FormGroup>
    </Grid>
  );
}

export function Row({
  children,
  heading,
  subHeading,
  border,
}: PropsWithChildren<{
  heading?: string;
  subHeading?: string;
  border?: boolean;
}>) {
  return (
    <>
      {(!!heading || !!subHeading) && (
        <Grid container item xs={12} direction="column">
          {!!heading && <Typography mt={2}>{heading}</Typography>}
          {!!subHeading && (
            <Typography variant="body2">{subHeading}</Typography>
          )}
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        spacing={1}
        pb={border ? 2 : undefined}
        sx={
          border
            ? {
                outline: "grey solid 2px",
                borderRadius: 2,
                outlineOffset: -7,
              }
            : undefined
        }
      >
        {children}
      </Grid>
    </>
  );
}
