import { Check, Clear, Edit } from "@mui/icons-material";
import { IconButton, TextField, useTheme } from "@mui/material";
import React, { useState } from "react";

export function EditInPlace({
  text,
  onEdit,
  size,
  color,
  id,
}: {
  text: string;
  onEdit: (text: string) => void;
  size?: "small" | "medium";
  color?: string;
  id?: string;
}) {
  const [newText, setNewText] = useState<string | null>(null);

  const onEnter = () => {
    if (newText) {
      onEdit(newText);
    }
    setNewText(null);
  };

  const theme = useTheme();

  return (
    <div id={id}>
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setNewText(text);
        }}
      >
        <Edit htmlColor={theme.palette.text.primary} />
      </IconButton>
      {newText === null ? (
        text
      ) : (
        <>
          <TextField
            size={size || "small"}
            value={newText}
            onChange={(e) => setNewText(e.target.value)}
            onBlur={() => setNewText(null)}
            autoFocus
            onKeyDown={(e) => {
              switch (e.key) {
                case "Escape":
                  setNewText(null);
                  break;
                case "Enter":
                  onEnter();
                  break;
              }
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
          <IconButton onMouseDown={onEnter}>
            <Check color="success" />
          </IconButton>
          <IconButton onClick={() => setNewText(null)}>
            <Clear color="warning" />
          </IconButton>
        </>
      )}
    </div>
  );
}
