import { EditorContent } from "@tiptap/react";
import { ChatModal } from "./Chat";
import "./styles.css";
import { useEffect, useMemo } from "react";
import { useSettingsStore } from "settings/Appearance";
import { useRemoteInterpretation } from "settings/RemoteInterpretation";
import { blueGrey, red, yellow } from "@mui/material/colors";
import { Grid, Icon, IconButton, Paper, Stack, useTheme } from "@mui/material";
import { t } from "i18next";
import { PostUrlDialog } from "./PostUrlDialog";
import backgroundSvg from "./livetext-block-lines.svg";
import { HotkeyBar } from "hotkeybar/HotkeyBar";
import { useSettingsMenuStore } from "settings/SettingsMenuStore";
import { PrewrittenText } from "PreWritten";
import { useEditor } from "./EditorStore";
import { Close, FormatBold, FormatItalic } from "@mui/icons-material";

export default function TipTapEditor() {
  const room = useRemoteInterpretation((state) => state.room);
  const status = useEditor((state) => state.status);
  const active = useEditor((state) => state.active);
  const change = useEditor((state) => state.change);
  const fontSize = useSettingsStore((state) => state.fontSize);
  const padding = useSettingsStore((state) => state.padding);
  const paddingTop = useSettingsStore((state) => state.paddingTop);
  const paddingBottom = useSettingsStore((state) => state.paddingBottom);
  const paddingLeft = useSettingsStore((state) => state.paddingLeft);
  const paddingRight = useSettingsStore((state) => state.paddingRight);
  const separatePadding = useSettingsStore((state) => state.separatePadding);
  const showActiveInterpreter = useSettingsStore(
    (state) => state.showActiveInterpreter
  );
  const fontFace = useSettingsStore((state) => state.fontFace);
  const showSessionStatus = useSettingsStore(
    (state) => state.showSessionStatus
  );
  const interpreters = useEditor((state) => state.interpreters);
  const listeners = useEditor((state) => state.listeners);
  const showSettingsMenu = useSettingsMenuStore(
    (state) => state.showSettingsMenu
  );

  const editor = useEditor((state) => state.editor);

  useEffect(() => {
    if (!showSettingsMenu) {
      editor.commands.refocus();
    }
  }, [editor, showSettingsMenu]);

  const paddingString = useMemo(() => {
    if (separatePadding === "separate") {
      return `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`;
    } else {
      return `${padding}px`;
    }
  }, [
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    separatePadding,
  ]);

  return (
    <div
      style={{
        display: showSettingsMenu ? "none" : "flex",
        flexDirection: "column",
        fontSize,
        fontFamily: fontFace,
        height: "100vh",
      }}
    >
      {showSessionStatus && room !== null && (
        <Grid
          container
          sx={{
            backgroundColor:
              status === "connected"
                ? blueGrey["500"]
                : status === "disconnected"
                ? red["600"]
                : yellow["900"],
            fontSize: 12,
          }}
        >
          <Grid item xs>
            {room.name}: {status}
          </Grid>
          <Grid item xs>
            <TimeSinceLastChange />
          </Grid>
          <Grid item xs>
            {t<string>("{n} interpreters", { count: interpreters.length })}:{" "}
            {interpreters.join(", ")}
          </Grid>
          <Grid item xs>
            {t<string>("{n} listeners", { count: listeners.length })}:{" "}
            {listeners.join(", ")}
          </Grid>
        </Grid>
      )}
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            backgroundImage:
              fontFace === "Monospace" ? `url("${backgroundSvg}")` : undefined,
            backgroundSize: 0,
            borderColor: !room
              ? "transparent"
              : change
              ? "yellow"
              : !showActiveInterpreter
              ? "transparent"
              : active
              ? "blue"
              : "red",
            padding: paddingString,
            flex: 1,
            maxWidth: fontFace === "Monospace" ? "40ch" : undefined,
          }}
          className={"editorWrapper highlight-none"}
        >
          <EditorContent
            className="ProseMirrorDiv"
            spellCheck={false}
            editor={editor}
          />
        </div>
        <ActiveTextStyle />
        <PrewrittenText />
        <HotkeyBar />
      </div>
      {room !== null && <PostUrlDialog />}
      {room !== null && <ChatModal />}
    </div>
  );
}

function TimeSinceLastChange() {
  const timeSinceLastChange = useEditor((state) => state.timeSinceLastChange);
  const date = new Date(timeSinceLastChange);
  return (
    <>
      {t<string>("Time since last change {duration}", {
        duration: date.toISOString().substring(11, 19),
      })}
    </>
  );
}

function ActiveTextStyle() {
  const showActiveTextStyle = useSettingsStore(
    (state) => state.showActiveTextStyle
  );
  const bold = useEditor((state) => state.bold);
  const italic = useEditor((state) => state.italic);

  if (!showActiveTextStyle) {
    return null;
  }

  const theme = useTheme();

  return (
    <div className="text-style-indicators">
      {(bold || italic) && (
        <Paper>
          <Stack
            direction="row"
            alignItems="center"
            padding={0.4}
            spacing={0.2}
          >
            {bold && (
              <Icon
                sx={{
                  border: "1px solid",
                  borderColor: theme.palette.primary.main,
                }}
              >
                <FormatBold sx={{ fontSize: 20 }} />
              </Icon>
            )}
            {italic && (
              <Icon
                sx={{
                  border: "1px solid",
                  borderColor: theme.palette.primary.main,
                }}
              >
                <FormatItalic sx={{ fontSize: 20 }} />
              </Icon>
            )}
            <IconButton
              onClick={() =>
                useSettingsStore.setState({ showActiveTextStyle: false })
              }
            >
              <Close sx={{ fontSize: 15 }} />
            </IconButton>
          </Stack>
        </Paper>
      )}
    </div>
  );
}
