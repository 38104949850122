import { Clear } from "@mui/icons-material";
import {
  Checkbox,
  Grid,
  IconButton,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

export type GridColumns<T> = Column<T>[];

interface Column<T = any> {
  name: string;
  noHeader?: boolean;
  disableSort?: boolean;
  field?: keyof T;
  get?: (row: T) => string | number | boolean;
  Component?: FC<{ row: T }>;
  width?: number;
  sx?: SxProps;
}

type Props<T, K extends keyof T> =
  | {
      defaultSortBy?: string;
      rows: T[];
      columns: Column<T>[];
      filter?: (row: T) => boolean;
      jumpToPage?: boolean;
      selected?: T[];
      setSelected?: Dispatch<SetStateAction<T[]>>;
      singleSelect?: false;
      idField: K;
    }
  | {
      defaultSortBy?: string;
      rows: T[];
      columns: Column<T>[];
      filter?: (row: T) => boolean;
      jumpToPage?: boolean;
      selected?: T;
      setSelected?: Dispatch<SetStateAction<T>>;
      singleSelect: true;
      idField: K;
    };

function getHeader(col: Column) {
  return col.noHeader ? "" : col.name;
}

export function DataGrid<T, K extends keyof T>({
  defaultSortBy,
  rows,
  columns,
  jumpToPage,
  singleSelect,
  selected,
  setSelected,
  filter,
  idField,
}: Props<T, K>) {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState(defaultSortBy || "");
  const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const sortByProperty = useCallback(
    (by: string) => {
      if (sortBy === by) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortBy(by);
      }
    },
    [sortBy, sortOrder, setSortBy, setSortOrder]
  );

  const sortedRows = useMemo(() => {
    const column = columns.find((col) => col.name === sortBy);
    if (!column) {
      return rows;
    }
    const sorted = rows.slice();
    sorted.sort((a, b) => {
      const left = column.field
        ? a[column.field]
        : column.get
        ? column.get(a)
        : 0;
      const right = column.field
        ? b[column.field!]
        : column.get
        ? column.get(b)
        : 0;
      if (left < right) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (left > right) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
    return sorted;
  }, [rows, columns, sortBy, sortOrder]);

  const filteredRows = useMemo(() => {
    if (filter) {
      return sortedRows.filter(filter);
    }
    return sortedRows;
  }, [sortedRows, filter]);

  const visibleRows = useMemo(
    () =>
      filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredRows, page, rowsPerPage]
  );

  const totalPages = useMemo(
    () => Math.ceil(filteredRows.length / rowsPerPage),
    [filteredRows, rowsPerPage]
  );

  const [allSelected, anySelected] = useMemo(() => {
    if (singleSelect || !selected || !selected.length || !visibleRows.length) {
      return [false, false];
    }
    let all = true;
    let any = false;
    for (const row of visibleRows) {
      if (!!(selected as T[]).find((sel) => sel[idField] === row[idField])) {
        if (!all) {
          return [false, true];
        }
        any = true;
      } else {
        if (any) {
          return [false, true];
        }
        all = false;
      }
    }
    return [all, any];
  }, [filteredRows, selected, page, rowsPerPage]);

  return (
    <>
      <TableContainer>
        <Table sx={{ width: "100%" }} size="medium">
          <TableHead>
            <TableRow>
              {selected && !singleSelect && (
                <TableCell width={20} padding="checkbox">
                  <Checkbox
                    checked={!!allSelected}
                    indeterminate={!allSelected && anySelected}
                    onClick={(e) => {
                      if (!anySelected) {
                        setSelected?.((prev) => [...prev, ...visibleRows]);
                      } else {
                        setSelected?.((prev) =>
                          prev.filter(
                            (p: T) =>
                              !visibleRows.find(
                                (row) => row[idField] === p[idField]
                              )
                          )
                        );
                      }
                    }}
                  />
                </TableCell>
              )}
              {columns.map((col) => (
                <TableCell key={col.name} sx={col.sx}>
                  {col.disableSort ? (
                    getHeader(col)
                  ) : (
                    <TableSortLabel
                      active={sortBy === col.name}
                      direction={sortOrder}
                      onClick={() => sortByProperty(col.name)}
                    >
                      {getHeader(col)}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row) => (
              <TableRow
                key={row[idField] as any}
                selected={singleSelect && selected?.[idField] === row[idField]}
                onClick={
                  singleSelect
                    ? () => {
                        setSelected?.(row);
                      }
                    : () => {
                        if (selected) {
                          if (selected.includes(row)) {
                            setSelected?.((prev) =>
                              prev.filter(
                                (val: T) => val[idField] !== row[idField]
                              )
                            );
                          } else {
                            setSelected?.((prev) => [...prev, row]);
                          }
                        }
                      }
                }
              >
                {selected && !singleSelect && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        !!selected.find((val) => val[idField] === row[idField])
                      }
                      onChange={(e) => {
                        setSelected?.((prev) =>
                          e.target.checked
                            ? [...prev, row]
                            : prev.filter(
                                (val) => val[idField] !== row[idField]
                              )
                        );
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </TableCell>
                )}
                {columns.map(({ Component, get, field, name }) => (
                  <TableCell key={name}>
                    {Component ? (
                      <Component row={row} />
                    ) : get ? (
                      get(row)
                    ) : (
                      (row[field!] as unknown as string)
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container alignItems="center" spacing={1} padding={1}>
        {!singleSelect && selected && (
          <>
            <Grid item xs="auto">
              {selected.length} valda
            </Grid>
            {!!selected.length && (
              <Grid item xs="auto">
                <IconButton onClick={() => setSelected?.([])}>
                  <Clear />
                </IconButton>
              </Grid>
            )}
          </>
        )}
        <Grid item xs>
          <TablePagination
            labelRowsPerPage={t("Rows per page")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${t("of")} ${count}`
            }
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={(e) => {
              setRowsPerPage((prev) => {
                const newVal = parseInt(e.target.value);
                const newPage = Math.floor((page * prev) / newVal);
                if (newPage !== page) {
                  setPage(newPage);
                }
                return newVal;
              });
            }}
            onPageChange={(e, newPage) => setPage(newPage)}
          />
        </Grid>
        {jumpToPage && (
          <>
            <Grid item xs="auto">
              {t("Page")}
            </Grid>
            <Grid item xs="auto">
              <TextField
                sx={{ width: 100 }}
                value={page + 1}
                onChange={(e) => {
                  const val = parseInt(e.target.value);
                  const page = Math.max(Math.min(totalPages, val), 1);
                  setPage(isNaN(page) ? 0 : page - 1);
                }}
                type="number"
              />
            </Grid>
            <Grid item xs="auto">
              {t("of")} {totalPages}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
