import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import { addHotkey, removeHotkey } from "keybindings/KeyBindings";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Doc } from "yjs";
import { useEditor } from "./EditorStore";

const defaultSettings = {
  teams: {
    charsPerLine: 80,
    linesPerBlock: 1,
    rollingText: false,
    format: "",
    delay: 3000,
    delayOnEnd: true,
  },
  zoom: {
    charsPerLine: 50,
    linesPerBlock: 1,
    rollingText: false,
    format: "",
    delay: 3000,
    delayOnEnd: true,
  },
  malmo: {
    charsPerLine: 40,
    linesPerBlock: 2,
    rollingText: false,
    format: "",
    delay: 25000,
    delayOnEnd: false,
  },
  youtube: {
    charsPerLine: 1,
    linesPerBlock: 1,
    rollingText: false,
    format: "",
    delay: 5000,
    delayOnEnd: true,
  },
};

function updateSettings(doc: Doc, newSettings: typeof defaultSettings.zoom) {
  const settings = doc.getMap("postUrlSettings");
  settings.set("charsPerLine", newSettings.charsPerLine);
  settings.set("linesPerBlock", newSettings.linesPerBlock);
  settings.set("rollingText", newSettings.rollingText);
  settings.set("delay", newSettings.delay);
  settings.set("delayOnEnd", newSettings.delayOnEnd);
}

const charsPerLineTooltip =
  "Characters per line in the block. If a word is too long to fit in a line it will be chopped up, unless this is set to 1. Then it will send one word at a time.";

const delayTooltip =
  "The amount of time in ms to wait before sending out a block of text. This settings is not used when sending captions to zoom.";

const delayOnEndTooltip =
  "If this is checked, the delay is counted from the time a block was last modified. If it's not checked the delay is counted from when you started typing the block. Unless you know what you're doing you shouldn't change this.";

export function PostUrlDialog() {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [urlArray, setUrlArray] = useState<string[]>([]);
  const doc = useEditor((state) => state.ydoc);
  const editor = useEditor((state) => state.editor);

  const [charsPerLine, setCharsPerLine] = useState(
    defaultSettings.zoom.charsPerLine
  );
  const [linesPerBlock, setLinesPerBlock] = useState(
    defaultSettings.zoom.linesPerBlock
  );
  const [rollingText, setRollingText] = useState(
    defaultSettings.zoom.rollingText
  );
  const [delay, setDelay] = useState(defaultSettings.zoom.delay);
  const [delayOnEnd, setDelayOnEnd] = useState(defaultSettings.zoom.delayOnEnd);

  const { t } = useTranslation();

  const onClose = useCallback(() => {
    setOpen(false);
    setUrl("");
    editor.commands.refocus();
  }, [editor, setUrl, setOpen]);

  const addURL = useCallback(() => {
    if (url) {
      const postURLs = doc.getArray("postURL");
      postURLs.insert(0, [url]);

      if (!urlArray.length) {
        if (url.includes(".zoom.")) {
          updateSettings(doc, defaultSettings.zoom);
        } else if (url.includes("microsoft")) {
          updateSettings(doc, defaultSettings.teams);
        } else if (url.includes("youtube")) {
          updateSettings(doc, defaultSettings.youtube);
        } else {
          updateSettings(doc, defaultSettings.malmo);
        }
      }
    }
    onClose();
  }, [url, doc, urlArray]);

  useEffect(() => {
    const postURLs = doc.getArray("postURL");
    postURLs.observe(() => {
      const newURLs = doc.getArray("postURL");
      setUrlArray((newURLs.toArray() as string[]).reverse());
    });

    const settings = doc.getMap("postUrlSettings");
    settings.observe(() => {
      const newSettings = doc.getMap("postUrlSettings");
      setCharsPerLine(
        (newSettings.get("charsPerLine") as number) ||
          defaultSettings.zoom.charsPerLine
      );
      setLinesPerBlock(
        (newSettings.get("linesPerBlock") as number) ||
          defaultSettings.zoom.linesPerBlock
      );
      setRollingText(
        (newSettings.get("rollingText") as boolean) ||
          defaultSettings.zoom.rollingText
      );
      setDelay(
        (newSettings.get("delay") as number) || defaultSettings.zoom.delay
      );
      setDelayOnEnd(
        (newSettings.get("delayOnEnd") as boolean) ||
          defaultSettings.zoom.delayOnEnd
      );
    });
  }, [doc]);

  useEffect(() => {
    if (!open && urlArray.length) {
      updateSettings(doc, {
        charsPerLine,
        linesPerBlock,
        rollingText,
        delay,
        delayOnEnd,
        format: "",
      });
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    addHotkey("mod+p", () => {
      setOpen(true);
    });

    return () => removeHotkey("mod+p");
  }, []);

  return (
    <Dialog open={open} onClose={onClose} disableRestoreFocus>
      <DialogTitle>{t("Add post URL")}</DialogTitle>
      <DialogContent>
        <Grid container padding={1} spacing={1}>
          {urlArray.map((text, idx) => (
            <Grid
              item
              xs={12}
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              key={idx}
            >
              {text}
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              onClick={() => {
                const postURLs = doc.getArray("postURL");
                postURLs.delete(0, 1);
              }}
              color="warning"
              variant="contained"
            >
              {t("Remove latest")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  addURL();
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              label={t("URL")}
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </Grid>
          <Grid item xs>
            <Accordion>
              <AccordionSummary>{t("Settings")}</AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} padding={1} direction="column">
                  <Grid container gap={1} item xs>
                    <Tooltip title={t(charsPerLineTooltip)}>
                      <TextField
                        type="number"
                        value={charsPerLine}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                          if (!isNaN(newValue)) {
                            setCharsPerLine(parseInt(e.target.value));
                          }
                        }}
                        label={t("Characters per line")}
                      />
                    </Tooltip>
                    <TextField
                      type="number"
                      value={linesPerBlock}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value);
                        if (!isNaN(newValue)) {
                          setLinesPerBlock(parseInt(e.target.value));
                        }
                      }}
                      label={t("Lines per block")}
                    />
                  </Grid>
                  <Grid item xs>
                    <FormControlLabel
                      label={t("Rolling text")}
                      control={
                        <Checkbox
                          checked={rollingText}
                          onChange={(e) => setRollingText(e.target.checked)}
                        />
                      }
                    />
                  </Grid>
                  <Grid container gap={1} item xs>
                    <Tooltip title={t(delayTooltip)}>
                      <TextField
                        type="number"
                        value={delay}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                          if (!isNaN(newValue)) {
                            setDelay(parseInt(e.target.value));
                          }
                        }}
                        label={t("Delay")}
                      />
                    </Tooltip>
                    <Tooltip title={t(delayOnEndTooltip)}>
                      <FormControlLabel
                        label={t("Delay on end")}
                        control={
                          <Checkbox
                            checked={delayOnEnd}
                            onChange={(e) => setDelayOnEnd(e.target.checked)}
                          />
                        }
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={addURL}>{t("Add")}</Button>
      </DialogActions>
    </Dialog>
  );
}
