import { InputRule, ExtendedRegExpMatchArray } from "@tiptap/core";

export default function regexpInputRule(config: {
  find: RegExp;
  replace: (match: ExtendedRegExpMatchArray) => string;
}) {
  return new InputRule({
    find: config.find,
    handler: ({ state, range, match }) => {
      const start = range.from;
      const end = range.to;
      const insert = config.replace(match);
      if (insert) {
        state.tr.insertText(insert, start, end);
      }
    },
  });
}
