import { ReactElement, useContext } from "react";
import { MenuItem } from "@mui/material";
import { DropdownButton } from "ui";
import { listToJSON, ProgramJSONFormat } from "hooks/persisting-storage";
import { useTranslation } from "react-i18next";
import { AbbreviationList } from "contextproviders/Abbreviations";
import { useLogin } from "ui";

interface Props {
  lists: AbbreviationList[];
}

export function clickHref(name: string, blob: Blob) {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = name;
  a.click();
  a.remove();
}

export default function ExportButton({ lists }: Props): ReactElement {
  const { t } = useTranslation();
  const user = useLogin((state) => state.user);

  return (
    <DropdownButton color="inherit" label={t("Export")}>
      <MenuItem
        color="inherit"
        onClick={() => {
          const downloadName = `${lists
            .map((list) => list.name)
            .toString()}.json`;
          const blob = new Blob(
            [JSON.stringify(lists.map((list) => listToJSON(list)))],
            {
              type: "application/json",
            }
          );
          clickHref(downloadName, blob);
        }}
      >
        Web Illumi format
      </MenuItem>
      <MenuItem
        onClick={() => {
          const downloadName = `${lists
            .map((list) => list.name)
            .toString()}-old-format.json`;

          let tempListId = Date.now();
          const oldListObject = {
            lists: [] as Partial<ProgramJSONFormat["lists"][number]>[],
            abbreviations: [] as Partial<
              ProgramJSONFormat["abbreviations"][number]
            >[],
          };
          for (const list of lists) {
            tempListId++;
            oldListObject.lists!.push({
              id: tempListId,
              name: list.name,
              type: 1,
            });
            for (const [abbreviation, [updatedAt, word]] of list.abb2expanded) {
              oldListObject.abbreviations.push({
                listId: tempListId,
                abbreviation,
                word,
                updatedAt: new Date(updatedAt).toUTCString(),
                createdAt: new Date(updatedAt).toUTCString(),
              });
            }
          }
          const blob = new Blob([JSON.stringify(oldListObject)], {
            type: "application/json",
          });
          clickHref(downloadName, blob);
        }}
      >
        Program Illumi format
      </MenuItem>
      {!user?.roles.includes("noTextListExport") && (
        <MenuItem
          onClick={() => {
            const downloadName = `${lists
              .map((list) => list.name)
              .toString()}.txt`;

            const combinedMaps = [
              ...lists
                .reduce<Map<string, [number, string]>>(
                  (prev, cur) => new Map([...prev, ...cur.abb2expanded]),
                  new Map()
                )
                .entries(),
            ];

            const asText = combinedMaps
              .map(([abb, [_updatedAt, word]]) => `${abb}=${word}`)
              .join("\n");
            const blob = new Blob([asText], {
              type: "application/json",
            });
            clickHref(downloadName, blob);
          }}
        >
          Text format
        </MenuItem>
      )}
    </DropdownButton>
  );
}
