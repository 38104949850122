import { create } from "zustand";

type InstallPromptStore = {
  beforeInstall?: BeforeInstallEvent;
};

type BeforeInstallEvent = {
  prompt: () => void;
};

export const useInstallPrompt = create<InstallPromptStore>((set) => ({
  beforeInstall: undefined,
}));

window.addEventListener("beforeinstallprompt", (e) => {
  useInstallPrompt.setState({
    beforeInstall: e as unknown as BeforeInstallEvent,
  });
});

if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    if (newModule) {
      newModule.useInstallPrompt.setState(useInstallPrompt.getState());
    }
  });
}
