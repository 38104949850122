import { useAbbreviations } from "contextproviders/Abbreviations";
import { useRemoteInterpretation } from "settings/RemoteInterpretation";
import { wordSeparators } from "word-lookup/wordLookup";
import { AbbrevationStats, useAbbreviationsLocalChunk } from "./index";

/**
 * update stats if typedWord is an abbreviation or if
 * end of typedSentence could have been written with an abbreviation.
 */
export function onTypedWord(typedSentence: string, typedWord: string) {
  const { mergedList, mergedListexpanded2abbs } = useAbbreviations.getState();
  const { localStatsChunk } = useAbbreviationsLocalChunk.getState();
  const sessionName = useRemoteInterpretation.getState().room?.name || "none";
  const typedWordLc = typedWord.toLowerCase();
  const expand =
    mergedList.abb2expanded.get(typedWord)?.[1] ||
    mergedList.abb2expanded.get(typedWordLc)?.[1];
  if (expand) {
    //an abbreviation was typed. record it.
    if (typedWordLc !== expand.toLowerCase()) {
      //avoid catching stuff like "hej"=>"hej"
      onTypedAbb(localStatsChunk, typedWordLc, expand);
    }
  } else if (mergedListexpanded2abbs.has(typedWordLc)) {
    //There exists an expanded that ends with typedWord
    //check if there was an abb that could have been used
    //might be multiple, record the one that would Avoid The Most chars.
    //example:
    //typedWord="helg"
    //abbs = expandedlastword2abbs["helg"] = ["telga", "ntelg", "telg", "nelg", "ntvh"]
    //expandeds ["ha en trevlig helg", "nu tar vi helg", "trevlig helg", "nästa helg", "nu tar vi helg"]

    let lineLowercase = typedSentence.toLowerCase().replace(/\u2060/g, "");
    while (lineLowercase.at(-1)?.match(wordSeparators)) {
      lineLowercase = lineLowercase.substring(0, lineLowercase.length - 1);
    }
    const abbs = mergedListexpanded2abbs
      .get(typedWordLc)
      ?.map((abb) => [
        abb,
        mergedList.abb2expanded.get(abb)?.[1]?.toLowerCase(),
      ])
      ?.filter(
        ([_abb, expanded]) => expanded && lineLowercase.endsWith(expanded)
      ) as Array<[string, string]> | undefined;
    if (abbs?.length) {
      abbs.sort((a, b) => {
        const expandedDiff = b[1].length - a[1].length;
        return expandedDiff === 0 ? a[0].length - b[0].length : expandedDiff;
      });
      if (abbs.length) {
        onTypedExpanded(localStatsChunk, abbs[0][1], abbs[0][0]);
      }
    }
  }

  return { ...localStatsChunk, sessionName }; //shallow copy to update ref
}

/**
 * When the user just typed abb, instead of typing expanded.
 *
 * Avoided some keypresses, save it to stats
 */
export function onTypedAbb(
  stats: AbbrevationStats,
  typedAbb: string,
  expanded: string
) {
  if (stats.abb[typedAbb]) {
    stats.abb[typedAbb].count += 1;
  } else {
    stats.abb[typedAbb] = { expanded, count: 1 };
  }
}

/**
 * When the user just typed expanded, instead of typing abb.
 *
 * Wasted some keypresses, save it to stats.
 */
export function onTypedExpanded(
  stats: AbbrevationStats,
  typedExpanded: string,
  abb: string
) {
  if (stats.expanded[typedExpanded]) {
    stats.expanded[typedExpanded].count += 1;
  } else {
    stats.expanded[typedExpanded] = { abb, count: 1 };
  }
}
