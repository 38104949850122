import { createTheme } from "@mui/material";
import { red, amber, cyan, pink, grey } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1440,
  },
};

const fullWhite = "rgba(255, 255, 255, 1)";

const fade = (val: number) => `rgba(255, 255, 255, ${val})`;

const palette = {
  illuminate: {
    primary: amber,
    secondary: red,
    backgroundColor: "#F5F5F5",
    borderColor: "#E0E0E0",
    rowHoverColor: "#EEEEEE",
  },
  darkChapel: {
    primary: cyan,
    secondary: pink,
    text: {
      primary: fullWhite,
      secondary: fade(0.7),
      disabled: fade(0.3),
      hint: fade(0.5),
    },
    background: {
      paper: grey["600"],
      default: grey["700"],
    },
    backgroundColor: "#303030",
    canvasColor: "#424242",
    borderColor: "#7A7A7A",
    rowHoverColor: "#555555",
  },
};

export const IlluminateTheme = createTheme({ palette: palette.illuminate, breakpoints });
export const DarkChapelTheme = createTheme({ palette: palette.darkChapel, breakpoints });
