import { mergeLists, newList } from "./lists";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { get, set, del } from "idb-keyval";
import { JSONtoList, listToJSON } from "hooks/persisting-storage";
import { Dispatch, SetStateAction } from "react";
import { ToQuery } from "query-client";
import { syncQuery } from "serversync";
import { createDeferredPromise } from "utility";

// changed around 2023-02-07
export interface AbbreviationListV0 {
  name: string;
  id: string;
  createdAt: number;
  updatedAt: number;
  abb2expanded: Map<string, [number, string]>;
}

export interface AbbreviationList {
  name: string;
  _id: string;
  createdAt: number;
  updatedAt: number;
  abb2expanded: Map<string, [number, string]>;
}

export interface ListHotkey {
  id: string;
  createdAt: string;
  listIds: string[];
}

export type ModifyKey = ToQuery<typeof syncQuery, "modifyLists">["add"][number];

type ListStore = {
  abbreviationLists: AbbreviationList[];
  user: string;
};

type ListStoreV0 = {
  abbreviationLists: AbbreviationListV0[];
  user: string;
};

type AbbreviationsStoreV0 = {
  activeListIds: string[];
  mergedList: AbbreviationList;
  listHotkeys: Array<string[]>;
  activeHotkeyId: number;
  mergedListexpanded2abbs: Map<string, string[]>;
  accordion: boolean;
  selected: AbbreviationList[];
  setSelected: Dispatch<SetStateAction<AbbreviationList[]>>;
  activateHotkey: (index: number) => void;
};

export type AbbreviationsStore = {
  activeListIds: string[];
  mergedList: AbbreviationList;
  listHotkeys: Array<{ name: string; ids: string[] }>;
  activeHotkeyId: number;
  mergedListexpanded2abbs: Map<string, string[]>;
  accordion: boolean;
  selected: AbbreviationList[];
  setSelected: Dispatch<SetStateAction<AbbreviationList[]>>;
  activateHotkey: (index: number) => void;
};

export const listAreLoaded = createDeferredPromise();

export const useLists = create<ListStore>()(
  persist(
    (set) => ({
      abbreviationLists: [] as AbbreviationList[],
      user: "",
    }),
    {
      name: "lists",
      version: 1,
      migrate(persistedState: any, version) {
        const oldState = persistedState as ListStoreV0;
        if (version === 0) {
          return {
            abbreviationLists: oldState.abbreviationLists.map((list) => ({
              name: list.name,
              abb2expanded: list.abb2expanded,
              updatedAt: list.updatedAt,
              createdAt: list.createdAt,
              _id: list.id,
            })),
            user: oldState.user,
          };
        }
        return persistedState;
      },
      storage: {
        async getItem(name) {
          const stored = await get(name);
          if (stored) {
            const data = JSON.parse(stored);
            data.state.abbreviationLists = JSONtoList(
              data.state.abbreviationLists
            );
            return data;
          }
          return null;
        },
        async setItem(name, value) {
          const newValue = JSON.stringify({
            state: {
              ...value.state,
              abbreviationLists: value.state.abbreviationLists.map((list) =>
                listToJSON(list)
              ),
            },
          });
          await set(name, newValue);
        },
        removeItem: async (name: string): Promise<void> => {
          await del(name);
        },
      },
      onRehydrateStorage() {
        return () => {
          mergeLists();
          listAreLoaded.resolve();
        };
      },
    }
  )
);

export const useAbbreviations = create<AbbreviationsStore>()(
  persist(
    (set) => ({
      activeListIds: [],
      mergedList: newList("merged"),
      listHotkeys: Array(10)
        .fill(1)
        .map((_) => ({ name: "", ids: [] })),
      activeHotkeyId: 0,
      mergedListexpanded2abbs: new Map(),
      accordion: true as boolean,
      selected: [],
      activateHotkey(index) {
        set(({ listHotkeys }) => ({
          activeListIds: listHotkeys[index].ids,
          activeHotkeyId: index,
        }));
      },
      setSelected(lists: SetStateAction<AbbreviationList[]>) {
        if (typeof lists === "function") {
          set(({ selected }) => ({ selected: lists(selected) }));
        } else {
          set({ selected: lists });
        }
      },
    }),
    {
      version: 1,
      name: "abbreviations",
      partialize(state) {
        return {
          activeListIds: state.activeListIds,
          listHotkeys: state.listHotkeys,
          activeHotkeyId: state.activeHotkeyId,
        };
      },
      migrate(persistedState: any, version) {
        const oldState = persistedState as AbbreviationsStoreV0;
        if (version === 0) {
          return {
            ...oldState,
            listHotkeys: oldState.listHotkeys.map((hotkey) => ({
              name: "",
              ids: [...hotkey],
            })),
          };
        }
        return persistedState;
      },
    }
  )
);

if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    if (newModule) {
      newModule.useAbbreviations.setState(useAbbreviations.getState());
      newModule.useLists.setState(useLists.getState());
    }
  });
}
