import { JWK, KeyLike, importJWK, jwtVerify } from "jose";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export let publicKey: KeyLike;

export interface UserData {
  _id: string;
  email: string;
  first_name: string;
  last_name: string;
  organization: string;
  roles: string[];
  groups: string[];
}

type LoginStoreInit = {
  key: JWK;
  onLogin?: (user: UserData) => void;
  allowOfflineUsage?: boolean;
};

type LoginStore = {
  user: UserData | null;
  loading: boolean;
  rememberMe: boolean;
  token: string;
  offlineToken: string;
  allowOfflineUsage: boolean;
  refreshTokenTimeout?: NodeJS.Timeout;
  getUser: (remember: boolean, auth?: string) => Promise<string>;
  logout: () => void;
  onLogin: (user: UserData) => any;
  initialize: (config: LoginStoreInit) => Promise<void>;
};

export const useLogin = create<LoginStore>()(
  persist(
    (set, get) => ({
      user: null as UserData | null,
      loading: true as boolean,
      rememberMe: false as boolean,
      token: "",
      offlineToken: "",
      allowOfflineUsage: false as boolean,
      refreshTokenTimeout: undefined as undefined | NodeJS.Timeout,
      async getUser(remember, auth) {
        const headers: Record<string, string> = {};
        if (auth) {
          headers["Authorization"] = auth;
        }
        if (remember) {
          headers["Illumitype-Remembers"] = "indeed";
        }
        try {
          const response = await fetch("/auth", {
            method: "GET",
            headers,
          });

          if (response.status === 200) {
            const data = await response.json();
            if (data.token) {
              const { payload } = await jwtVerify(data.token, publicKey, {
                audience: "illumitype:backend",
                issuer: "illumitype:accounts",
              });
              const { getUser, rememberMe, user, onLogin } = get();
              set({
                token: data.token,
                user: payload as unknown as UserData,
                offlineToken: data.offline,
                refreshTokenTimeout: setTimeout(async () => {
                  await getUser(
                    rememberMe,
                    rememberMe ? undefined : `Bearer ${data.token}`
                  );
                }, 1000 * 60 * 60),
              });
              if (user?._id !== payload._id) {
                onLogin(payload as unknown as UserData);
              }
            }
            return "";
          } else if (response.status === 401) {
            set({ token: "", user: null });
            return "Invalid username or password";
          }
        } catch (err: any) {
          if (!auth) {
            try {
              const { offlineToken } = get();
              if (typeof offlineToken === "string") {
                const { payload } = await jwtVerify(offlineToken, publicKey, {
                  audience: "illumitype:offline",
                  issuer: "illumitype:accounts",
                });
                set({ user: payload as unknown as UserData });
              }
            } catch (err2) {
              console.log("Error while checking offline token", err2);
            }
          }
          console.log(err);
        }
        return "";
      },
      async logout() {
        set({ loading: true });
        try {
          const response = await fetch("/logout");
          if (response.status === 200) {
            clearTimeout(get().refreshTokenTimeout);
            set({
              user: null,
              token: "",
              offlineToken: "",
              refreshTokenTimeout: undefined,
            });
          }
        } catch (err) {}
        set({ loading: false });
      },
      onLogin() {},
      async initialize({ key, onLogin, allowOfflineUsage }) {
        publicKey = (await importJWK(key, "ES256")) as KeyLike;
        set({
          allowOfflineUsage: !!allowOfflineUsage,
          onLogin: onLogin ? onLogin : () => {},
        });
        const { getUser } = get();
        await getUser(true);
        set({ loading: false });
      },
    }),
    {
      name: "login",
      partialize(state) {
        const { offlineToken, rememberMe } = state;
        return { offlineToken, rememberMe };
      },
    }
  )
);

export type { JWK } from "jose";
