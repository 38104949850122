import { ReactElement, useEffect, useState, useMemo } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  TableCell,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { confirm, useLogin, UserData } from "ui";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import SyncIcon from "@mui/icons-material/Sync";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import {
  AbbreviationListInfo,
  getList,
  getListsInfo,
  deleteList,
} from "./utils";
import { JSONtoList } from "hooks/persisting-storage";
import { datetime } from "utility";
import { useLists } from "contextproviders/Abbreviations";
import { addLists } from "contextproviders/Abbreviations/lists";

interface Props {
  searchstr?: string;
}

export default function ManageServerLists({
  searchstr = "",
}: Props): ReactElement {
  const { t } = useTranslation();
  const token = useLogin((state) => state.token);
  const user = useLogin((state) => state.user);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const abbreviationLists = useLists((state) => state.abbreviationLists);
  const [serverListsInfo, setServerListsInfo] = useState(
    [] as AbbreviationListInfo[]
  );
  const [fetching, setFetching] = useState(false);

  const [downloadedIds, setDownloadedIds] = useState([] as string[]);
  const [downloadedAndUpToDateIds, setDownloadedAndUpToDateIds] = useState(
    [] as string[]
  );
  const [isDownloadingId, setIsDownloadingId] = useState("");

  useEffect(() => {
    if (token) {
      setFetching(true);
      getListsInfo(token)
        .then(setServerListsInfo)
        .catch(() => toast.error(t("Could not find lists")))
        .finally(() => setFetching(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleDownload = (list: AbbreviationListInfo) => {
    setIsDownloadingId(list._id);
    getList(token, list._id)
      .then((obj) => {
        addLists(JSONtoList(obj));
        toast.success(`${t("Downloaded")} ${list.name}`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`${t("Could not download")} ${list.name}`);
      })
      .finally(() => {
        setTimeout(() => setIsDownloadingId(""), 1000);
      });
  };

  const handleDelete = async (list: AbbreviationListInfo) => {
    deleteList(token, list._id)
      .then((res) => {
        if (res.ok) {
          toast.info(`${t("Deleted")} ${list.name}`);
          //list is now deleted from server. fetch listinfo again:
          getListsInfo(token)
            .then(setServerListsInfo)
            .catch((err) => console.log(err));
        } else {
          toast.error(`${t("Could not delete")} ${list.name}`);
        }
      })
      .catch((err) => {
        toast.error(`${t("Could not delete")} ${list.name}`);
      });
  };

  useEffect(() => {
    const serverListIds = serverListsInfo.map((list) => list._id);

    const downloadedIds: string[] = [];
    const downloadedAndUpToDateIds: string[] = [];

    for (const list of abbreviationLists) {
      if (serverListIds.includes(list._id)) {
        const serverlist = serverListsInfo.find((l) => l._id === list._id);
        if (serverlist) {
          downloadedIds.push(list._id);
          if (list.updatedAt === serverlist.updatedAt) {
            downloadedAndUpToDateIds.push(list._id);
          }
        }
      }
    }
    setDownloadedIds(downloadedIds);
    setDownloadedAndUpToDateIds(downloadedAndUpToDateIds);
  }, [serverListsInfo, abbreviationLists]);

  const filteredServerListsInfo = useMemo(() => {
    return serverListsInfo.filter((list) =>
      list.name.toLowerCase().includes(searchstr.toLowerCase())
    );
  }, [serverListsInfo, searchstr]);

  const listLength = filteredServerListsInfo.length;
  const NemptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listLength) : 0; // Avoid a table size change on last page

  if (!token) {
    return (
      <Typography>
        {t(
          "You are in offline mode. To run in online mode, make sure you have internet access and restart the app."
        )}
      </Typography>
    );
  }

  if (fetching) {
    return (
      <Grid container justifyContent="center" padding={1}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <TableContainer>
        <Table sx={{ width: "100%" }} size="medium">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t("Name")}</TableCell>
              <TableCell>{t("Modified")}</TableCell>
              <TableCell>{t("Download")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredServerListsInfo
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((list) => {
                const isDownloaded = downloadedIds.includes(list._id);
                const isDownloadedAndUpToDate =
                  downloadedAndUpToDateIds.includes(list._id);
                const isDownloading = isDownloadingId === list._id;
                return (
                  <TableRow key={list._id}>
                    <TableCell></TableCell>
                    <TableCell>{list.name}</TableCell>
                    <TableCell>{datetime(list.updatedAt)}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleDownload(list)}
                        disabled={isDownloadedAndUpToDate || isDownloading}
                      >
                        {isDownloading ? (
                          <CircularProgress size="1.5em" />
                        ) : isDownloadedAndUpToDate ? (
                          <DownloadDoneIcon color="success" />
                        ) : isDownloaded ? (
                          <SyncIcon color="info" />
                        ) : (
                          <DownloadIcon color="info" />
                        )}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {userHasWriteRights(user, list) && (
                        <Button
                          onClick={async () => {
                            const confirmed = await confirm({
                              title: t("Delete list from cloud"),
                              content: `${t("Do you want to delete")} ${
                                list.name
                              }`,
                            });
                            if (confirmed) {
                              handleDelete(list);
                            }
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            {NemptyRows > 0 && (
              <TableRow
                style={{
                  height: 75 * NemptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={t("Rows per page")}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t("of")} ${count}`
        }
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={listLength}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
        onPageChange={(e, newPage) => setPage(newPage)}
      />
    </>
  );
}

/**
 * true if any element of v1 is in v2
 */
function includesAny(v1: string[], v2: string[]) {
  return v1.some((x) => v2.indexOf(x) >= 0);
}

/**
 * Dont show delete button if user wont be able to delete list on server anyway.
 */
function userHasWriteRights(user: UserData | null, list: AbbreviationListInfo) {
  if (user) {
    return includesAny(list.writeRights, [user._id, user.organization]);
  } else {
    return false;
  }
}
