import { addHotkey, removeHotkey } from "keybindings/KeyBindings";
import { PrewrittenTextDialog } from "./Dialog";
import { PrewrittenTextPreview } from "./Preview";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { useEditor } from "tiptap/EditorStore";
import { createDeferredPromise } from "utility";
import { syncQuery, useSync } from "serversync";
import { FromQuery } from "query-client";
import { get, set, del } from "idb-keyval";

const sentenceBreakers = [".", "?", "!", ":"];

export interface PrewrittenTextV0 {
  id: string;
  name: string;
  text: string;
}

export type PrewrittenText = Omit<
  FromQuery<typeof syncQuery, "getText">,
  "user"
>;

type PrewrittenTextStore = {
  text: string[][];
  active: boolean;
  currentLine: number;
  currentWord: number;
  preview: boolean;
  collection: PrewrittenText[];
  dialogOpen: boolean;
  user: string;
  edit: PrewrittenText | null;
};

export const textsAreLoaded = createDeferredPromise();

export const usePrewrittenText = create<PrewrittenTextStore>()(
  persist(
    (set) => ({
      text: [[]] as string[][],
      active: false as boolean,
      currentLine: 0,
      currentWord: 0,
      preview: false as boolean,
      collection: [] as PrewrittenText[],
      dialogOpen: false as boolean,
      user: "",
      edit: null,
    }),
    {
      name: "prewrittentext",
      version: 1,
      storage: {
        async getItem(name) {
          return (await get(name)) || null;
        },
        async setItem(name, value) {
          await set(name, value);
        },
        async removeItem(name) {
          await del(name);
        },
      },
      partialize(state) {
        return {
          collection: state.collection,
        } as any;
      },
      migrate(persistedState, version) {
        const oldState = persistedState as PrewrittenTextStore;
        if (version === 0) {
          const oldCollection =
            oldState.collection as unknown as PrewrittenTextV0[];
          const now = Date.now();
          oldState.collection = oldCollection.map(({ name, id, text }) => ({
            name,
            text,
            _id: id,
            updatedAt: now,
          }));
        }
        return oldState;
      },
      onRehydrateStorage() {
        return () => {
          textsAreLoaded.resolve();
        };
      },
    }
  )
);

if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    if (newModule) {
      newModule.usePrewrittenText.setState(usePrewrittenText.getState());
    }
  });
}

export function toggleDialog() {
  const { editor } = useEditor.getState();
  usePrewrittenText.setState(({ dialogOpen }) => {
    if (dialogOpen) {
      editor.commands.refocus();
    }
    return { dialogOpen: !dialogOpen };
  });
}

export function togglePreview() {
  usePrewrittenText.setState(({ preview, active }) => ({
    preview: active && !preview,
  }));
}

export function start(text: string) {
  const rows = text.replace(/\r/g, "").split("\n");
  while (rows.at(-1) === "") {
    rows.pop();
  }
  const rowsAndWords = rows.map((row) =>
    row.split(" ").filter((word) => word.length)
  );

  usePrewrittenText.setState({
    text: rowsAndWords,
    currentLine: 0,
    currentWord: 0,
    active: true,
    preview: true,
  });

  addHotkey("Escape", stop);
  addHotkey("F3", togglePreview);
}

export function stop() {
  usePrewrittenText.setState({
    text: [[]],
    currentLine: 0,
    currentWord: 0,
    active: false,
    preview: false,
  });

  removeHotkey("Escape");
  removeHotkey("F3");
}

export function goBack(textToRemove: string) {
  const words = textToRemove
    .trim()
    .split(" ")
    .filter((val) => !!val)
    .reverse();
  if (words.length === 0 && textToRemove.includes("\n")) {
    words.push("\n");
  }

  let { currentLine, currentWord, text } = usePrewrittenText.getState();

  for (const word of words) {
    if (word === "\n") {
      if (currentWord === 0 && currentLine > 0) {
        currentWord = text[--currentLine].length;
      } else {
        break;
      }
    } else if (text[currentLine][currentWord - 1] === word) {
      if (--currentWord < 1) {
        if (currentLine > 0) {
          currentLine--;
          currentWord = text[currentLine].length;
        } else {
          currentWord = 0;
        }
      }
    } else {
      break;
    }
  }

  usePrewrittenText.setState({ currentLine, currentWord });
}

function peek(lineIndex: number, wordIndex: number): string {
  const { text } = usePrewrittenText.getState();
  const line = text[lineIndex];
  if (line) {
    const word = line[wordIndex];
    if (word) {
      return word;
    } else if (!text[lineIndex + 1]) {
      return "";
    }

    return "\n";
  }

  return "";
}

export function nextWord(): string {
  const { text, currentLine, currentWord } = usePrewrittenText.getState();
  const line = text[currentLine];
  if (line) {
    const word = line[currentWord];
    if (word) {
      usePrewrittenText.setState({ currentWord: currentWord + 1 });
      return word;
    } else if (!text[currentLine + 1]) {
      return "";
    }

    const nextWord = peek(currentLine + 1, 0);
    if (nextWord && nextWord !== "\n") {
      usePrewrittenText.setState({
        currentLine: currentLine + 1,
        currentWord: 1,
      });
      return "\n" + nextWord;
    }

    usePrewrittenText.setState({
      currentLine: currentLine + 1,
      currentWord: 0,
    });
    return "\n";
  }

  return "";
}

export function nextSentence(): string {
  let { currentLine, currentWord } = usePrewrittenText.getState();
  let nextWord = peek(currentLine, currentWord);
  if (!nextWord) {
    return "";
  } else if (nextWord === "\n") {
    currentLine++;
    currentWord = 0;
  } else {
    currentWord++;
  }

  let sentence = nextWord;
  let lastWord = nextWord;
  nextWord = peek(currentLine, currentWord);

  while (nextWord && nextWord !== "\n") {
    nextWord = peek(currentLine, currentWord);
    if (lastWord !== "\n") {
      sentence += " " + nextWord;
    } else {
      sentence += nextWord;
    }

    if (sentenceBreakers.includes(nextWord[nextWord.length - 1])) {
      currentWord++;
      break;
    }

    lastWord = nextWord;
    nextWord = peek(currentLine, ++currentWord);
  }

  usePrewrittenText.setState({ currentLine, currentWord });

  return sentence;
}

export function jump(line: number, word: number) {
  usePrewrittenText.setState({ currentLine: line, currentWord: word });
}

export function jumpBackwardWord() {
  const { currentLine, currentWord, text } = usePrewrittenText.getState();
  if (currentWord === 0) {
    if (currentLine > 0) {
      usePrewrittenText.setState({
        currentLine: currentLine - 1,
        currentWord: text[currentLine - 1].length,
      });
    }
  } else {
    usePrewrittenText.setState({ currentWord: currentWord - 1 });
  }
}

export function jumpForwardWord() {
  const { currentLine, currentWord, text } = usePrewrittenText.getState();
  if (text[currentLine]) {
    if (text[currentLine][currentWord]) {
      usePrewrittenText.setState({ currentWord: currentWord + 1 });
    } else if (text[currentLine + 1]) {
      usePrewrittenText.setState({
        currentWord: 0,
        currentLine: currentLine + 1,
      });
    }
  }
}

export function jumpBackwardSentence() {
  const { currentLine, currentWord } = usePrewrittenText.getState();
  if (currentWord > 0) {
    usePrewrittenText.setState({ currentWord: 0 });
  } else if (currentLine > 0) {
    usePrewrittenText.setState({
      currentWord: 0,
      currentLine: currentLine - 1,
    });
  }
}

export function jumpForwardSentence() {
  let { currentLine, currentWord, text } = usePrewrittenText.getState();
  if (text[currentLine + 1]) {
    usePrewrittenText.setState({
      currentWord: 0,
      currentLine: currentLine + 1,
    });
  } else if (text[currentLine]) {
    usePrewrittenText.setState({
      currentWord: text[currentLine].length,
    });
  }
}

export function removeText(id: string) {
  usePrewrittenText.setState(({ collection }) => ({
    collection: collection.filter((text) => text._id !== id),
  }));
  if (useSync.getState().active) {
    syncQuery.query("removeText", { id });
  }
}

export function updateTextId(oldId: string, newId: string) {
  usePrewrittenText.setState(({ collection }) => ({
    collection: collection.map((text) =>
      text._id === oldId ? { ...text, _id: newId } : text
    ),
  }));
}

export async function addText(newText: PrewrittenText) {
  usePrewrittenText.setState(({ collection }) => ({
    collection: [...collection, newText],
  }));
  if (useSync.getState().active) {
    const id = await syncQuery.query("createText", newText);
    updateTextId(newText._id, id);
  }
}

export function PrewrittenText() {
  return (
    <>
      <PrewrittenTextDialog />
      <PrewrittenTextPreview />
    </>
  );
}
