import {
  useState,
  useCallback,
  SetStateAction,
  Dispatch,
  useMemo,
  useEffect,
} from "react";
import { confirm } from "ui";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { useLogin } from "ui";
import { useTranslation } from "react-i18next";
import { uploadList } from "./utils";
import { toast } from "react-toastify";
import { CheckCircle } from "@mui/icons-material";
import {
  DataGrid,
  GridColumns,
  EditInPlace,
  ButtonPreventDefault,
  IconButtonPreventDefault,
} from "ui";
import {
  AbbreviationList,
  useAbbreviations,
  useLists,
} from "contextproviders/Abbreviations";
import {
  isActive,
  removeList,
  toggleActive,
  updateListName,
} from "contextproviders/Abbreviations/lists";

interface Props {
  selected: AbbreviationList[];
  setSelected: Dispatch<SetStateAction<AbbreviationList[]>>;
  searchstr: string;
}

export default function ManageLocalLists({
  selected,
  setSelected,
  searchstr,
}: Props) {
  const { t } = useTranslation();
  const token = useLogin((state) => state.token);
  const user = useLogin((state) => state.user);
  const abbreviationLists = useLists((state) => state.abbreviationLists);
  const activeListIds = useAbbreviations((state) => state.activeListIds);

  const filter = useCallback(
    (list: AbbreviationList) =>
      list.name.toLowerCase().includes(searchstr.toLowerCase()),
    [searchstr]
  );

  useEffect(() => {
    setSelected(
      selected.filter(
        (list) =>
          !!abbreviationLists.find(
            (existingList) => existingList._id === list._id
          )
      )
    );
  }, [abbreviationLists]);

  const columns = useMemo<GridColumns<AbbreviationList>>(
    () => [
      {
        name: t("Name"),
        field: "name",
        sx: { width: 250 },
        Component({ row }) {
          return (
            <EditInPlace
              id={`edit${row.name}`}
              text={row.name}
              onEdit={(text) => updateListName(row._id, text)}
            />
          );
        },
      },
      {
        name: t("Active"),
        sx: { width: 150 },
        get(row) {
          return activeListIds.includes(row._id);
        },
        Component({ row }) {
          const [isUploading, setIsUploading] = useState(false);
          return (
            <>
              <IconButtonPreventDefault
                size="small"
                onClick={() => toggleActive(row._id)}
                id={`activate${row.name}`}
              >
                <CheckCircle
                  sx={{ fontSize: 32 }}
                  color={isActive(row._id) ? "success" : "disabled"}
                />
              </IconButtonPreventDefault>
              <IconButtonPreventDefault
                color="inherit"
                onClick={async () => {
                  try {
                    setIsUploading(true);
                    await uploadList(
                      token,
                      row,
                      [user!._id, user!.organization],
                      [user!._id]
                    );
                    toast.success(`${t("Uploaded")} ${row.name}`);
                  } catch (err) {
                    toast.error(`${t("Could not upload")} ${row.name}`);
                  }
                  setIsUploading(false);
                }}
                disabled={isUploading}
              >
                {isUploading ? (
                  <CircularProgress size="1.5em" />
                ) : (
                  <CloudUploadIcon />
                )}
              </IconButtonPreventDefault>
              <ButtonPreventDefault
                id={`delete${row.name}`}
                onClick={async () => {
                  const confirmed = await confirm({
                    title: t("Delete list"),
                    content: `${t("Do you want to delete")} ${row.name}`,
                  });
                  if (confirmed) {
                    removeList(row._id);
                  }
                }}
              >
                <DeleteIcon />
              </ButtonPreventDefault>
            </>
          );
        },
      },
    ],
    [activeListIds]
  );

  return (
    <DataGrid
      rows={abbreviationLists}
      defaultSortBy={t("Active")}
      selected={selected}
      setSelected={setSelected}
      filter={filter}
      columns={columns}
      idField="_id"
    />
  );
}
