import type { AbbrevationStats } from "./index";

const statsServer = import.meta.env.REACT_APP_STATISTICS_URL;

export async function postStatsChunk(
  statsChunk: AbbrevationStats,
  token: string
) {
  return await fetch(`${statsServer}/statistics`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...statsChunk }),
  });
}

/**
 * Post multiple statsChunks, return list of chunks (if any) that failed to send.
 */
export async function postStatsChunks(
  stats: AbbrevationStats[],
  token: string
) {
  const unSentStats: AbbrevationStats[] = [];
  for (const chunk of stats) {
    try {
      const res = await postStatsChunk(chunk, token);
      if (!res.ok) {
        unSentStats.push(chunk);
      }
    } catch (e) {
      unSentStats.push(chunk);
    }
  }
  return unSentStats;
}

export async function getStats(token: string) {
  try {
    const res = await fetch(`${statsServer}/statistics`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.json();
  } catch (err) {
    console.log("Failed to get stats", err);
  }
}
