import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Grid,
  useTheme,
  Typography,
  Hidden,
  IconButton,
  Tooltip,
} from "@mui/material";
import { red, grey } from "@mui/material/colors";
import {
  Spellcheck,
  HelpOutline,
  Brush,
  Translate,
  SettingsRemote,
  BarChart,
  Logout,
  InstallDesktop,
  Keyboard,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ManageLists from "./manage-abbreviations/ManageLists";
import { Language } from "./Language";
import { Appearance } from "./Appearance";
import ListPriority from "./manage-abbreviations/ListPriority";
import { RemoteInterpretation } from "./RemoteInterpretation";
import { useLogin } from "ui";
import AbbreviationStatistics from "./AbbreviationStatistics";
import { Paper, Toolbar } from "ui";
import { useInstallPrompt } from "installprompt/InstallPrompt";
import { KeybindingsSettings } from "./KeybindingsSettings";
import { useSettingsMenuStore } from "./SettingsMenuStore";

const SidebarMenuItems = [
  {
    text: "Manage abbreviations",
    Icon: Spellcheck,
    component: ManageLists,
  },
  /* {
    text: "CloudStore",
    Icon: Spellcheck,
    component: CloudStore,
  }, */
  {
    text: "Appearance",
    Icon: Brush,
    component: Appearance,
  },
  {
    text: "Remote Interpretation",
    Icon: SettingsRemote,
    component: RemoteInterpretation,
  },
  {
    text: "Language",
    Icon: Translate,
    component: Language,
  },
  {
    text: "Abbreviation Statistics",
    Icon: BarChart,
    component: AbbreviationStatistics,
  },
  {
    text: "Key bindings",
    Icon: Keyboard,
    component: KeybindingsSettings,
  },
] as const;

export default function Sidebar() {
  const { t } = useTranslation();
  const page = useSettingsMenuStore((state) => state.page);
  const logout = useLogin((state) => state.logout);
  const beforeInstall = useInstallPrompt((state) => state.beforeInstall);

  return (
    <>
      <Hidden mdDown>
        <Paper md={12}>
          <Toolbar>
            <Typography variant="h4">Illumitype</Typography>
          </Toolbar>
          <List>
            {SidebarMenuItems.map(({ text, Icon }) => (
              <ListItem
                key={text}
                button
                onClick={() => useSettingsMenuStore.setState({ page: text })}
              >
                <ListItemIcon>
                  <Icon htmlColor={page === text ? red["600"] : grey["500"]} />
                </ListItemIcon>
                <ListItemText primary={t(text)} />
              </ListItem>
            ))}
            <ListItem disablePadding>
              <ListItemButton
                component="a"
                href="https://illumitype.se/help"
                target="_blank"
              >
                <ListItemIcon>
                  <HelpOutline htmlColor={grey["500"]} />
                </ListItemIcon>
                <ListItemText primary={t("Help")} />
              </ListItemButton>
            </ListItem>
            {beforeInstall && (
              <ListItem button onClick={() => beforeInstall.prompt()}>
                <ListItemIcon>
                  <InstallDesktop htmlColor={grey["500"]} />
                </ListItemIcon>
                <ListItemText primary={t("Install")} />
              </ListItem>
            )}
            <ListItem button onClick={() => logout()}>
              <ListItemIcon>
                <Logout htmlColor={grey["500"]} />
              </ListItemIcon>
              <ListItemText primary={t("Log out")} />
            </ListItem>
          </List>
        </Paper>
        {page === "Manage abbreviations" && <ListPriority />}
      </Hidden>
      <Hidden mdUp>
        <Paper xs>
          <Toolbar>
            <Typography variant="h6">Illumitype</Typography>
          </Toolbar>
          {SidebarMenuItems.map(({ text, Icon }) => (
            <Tooltip key={text} title={t(text)} placement="top">
              <IconButton
                onClick={() => useSettingsMenuStore.setState({ page: text })}
              >
                <Icon htmlColor={page === text ? red["600"] : grey["500"]} />
              </IconButton>
            </Tooltip>
          ))}
          <Tooltip title={t("Help")} placement="top">
            <IconButton
              component="a"
              href="https://illumitype.se/help"
              target="_blank"
            >
              <HelpOutline htmlColor={grey["500"]} />
            </IconButton>
          </Tooltip>
          {beforeInstall && (
            <Tooltip title={t("Install")} placement="top">
              <IconButton onClick={() => beforeInstall.prompt()}>
                <InstallDesktop htmlColor={grey["500"]} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t("Log out")} placement="top">
            <IconButton onClick={() => logout()}>
              <Logout htmlColor={grey["500"]} />
            </IconButton>
          </Tooltip>
        </Paper>
        {page === "Manage abbreviations" && <ListPriority />}
      </Hidden>
    </>
  );
}

export function Settings() {
  const page = useSettingsMenuStore((state) => state.page);
  const showSettingsMenu = useSettingsMenuStore(
    (state) => state.showSettingsMenu
  );
  const theme = useTheme();

  if (!showSettingsMenu) {
    return null;
  }

  const Page = SidebarMenuItems.find((val) => val.text === page)
    ?.component as React.FunctionComponent;

  return (
    <div style={{ display: "flex" }}>
      <Grid
        style={{
          backgroundColor: theme.palette.background.default,
          overflowY: "auto",
        }}
        spacing={1}
        padding={1}
        height="100vh"
        container
        alignContent="start"
      >
        <Grid
          container
          spacing={1}
          item
          sm={12}
          md="auto"
          sx={{ width: { md: 270 } }}
          alignContent="start"
        >
          <Sidebar />
        </Grid>
        <Grid container item xs spacing={1}>
          {Page ? <Page /> : null}
        </Grid>
      </Grid>
    </div>
  );
}
