import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  colors,
  FormControlLabel,
  Grid,
  GridProps,
  GridSize,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DropdownButton } from "./DropdownButton";

export type SummaryListColumn<T> = {
  label: string;
  getValue: (row: T) => ReactNode;
  width?: GridProps["xs"];
  total?: (rows: T[]) => ReactNode;
  minWidth?: number;
  order?: (lhs: T, rhs: T) => number;
  hidable?: boolean;
  action?: ReactNode;
};

type SummaryListProps<T> = PropsWithChildren<{
  title?: string;
  columns: SummaryListColumn<T>[];
  data: T[];
  selected?: T[];
  setSelected?: Dispatch<SetStateAction<T[]>>;
  details?: (row: T) => ReactNode;
  defaultSelectAll?: boolean;
  backgroundColor?: string;
  onClick?: (row: T) => any;
  search?: (row: T, text: string) => boolean;
  manageColumns?: boolean;
  order?: OrderData<T>;
  setOrder?: Dispatch<SetStateAction<OrderData<T>>>;
  searchString?: string;
  setSearchString?: Dispatch<SetStateAction<string>>;
  shownColumns?: boolean[];
  setShownColumns?: Dispatch<SetStateAction<boolean[]>>;
  dense?: boolean;
  labelSize?: number;
  hideIfEmpty?: boolean;
}>;

export type OrderData<T> = {
  order?: (lhs: T, rhs: T) => number;
  asc: boolean;
  name?: "";
};

/**
 * Using minWidth required width: "auto"
 * If onClick is passed in, details are ignored
 */
export function SummaryList<T extends { _id: string }>(
  props: SummaryListProps<T>
) {
  const {
    title,
    columns,
    data,
    selected,
    setSelected,
    children,
    details,
    defaultSelectAll,
    backgroundColor,
    onClick,
    search,
    manageColumns,
    dense,
    labelSize,
    hideIfEmpty,
  } = props;

  useEffect(() => {
    if (defaultSelectAll) {
      setSelected?.([...data]);
    } else {
      setSelected?.((prev) =>
        prev.filter(
          (selected) => !!data.find((val) => val._id === selected._id)
        )
      );
    }
  }, [data]);

  const allSelected = useMemo(
    () => data.length > 0 && selected?.length === data.length,
    [selected, data]
  );
  const noneSelected = useMemo(() => !selected?.length, [selected]);
  const indeterminate = data.length > 0 && !allSelected && !noneSelected;
  const [order, setOrder] = useOrMakeState(
    { asc: true },
    props.order,
    props.setOrder
  );
  const [searchString, setSearchString] = useOrMakeState(
    "",
    props.searchString,
    props.setSearchString
  );
  const [shownColumns, setShownColumns] = useOrMakeState(
    columns.map(() => true),
    props.shownColumns,
    props.setShownColumns
  );

  const columnRatio = useMemo(() => {
    if (!manageColumns) {
      return 12;
    }
    const total = columns.reduce((prev, cur) => {
      return prev + (typeof cur.width === "number" ? cur.width : 0);
    }, 0);

    const current = columns.reduce(
      (prev, cur, idx) =>
        prev +
        ((cur.hidable && !shownColumns[idx]) || typeof cur.width !== "number"
          ? 0
          : cur.width),
      0
    );

    return total / current;
  }, [columns, shownColumns]);

  const orderedData = useMemo(() => {
    const orderFunc = order.order;
    if (orderFunc) {
      if (order.asc) {
        return data.slice().sort(order.order);
      } else {
        return data.slice().sort((lhs, rhs) => orderFunc(rhs, lhs));
      }
    }
    return data;
  }, [order, data]);

  const filteredData = useMemo(
    () =>
      search
        ? orderedData.filter((val) => search(val, searchString))
        : orderedData,
    [orderedData, search, searchString]
  );

  const getColumnWidth = useCallback(
    (width?: boolean | GridSize) => {
      if (!manageColumns) {
        return width ?? true;
      }
      if (typeof width === "number") {
        return width * columnRatio;
      }
      return width ?? true;
    },
    [manageColumns, columnRatio]
  );

  if (hideIfEmpty && data?.length === 0) {
    return null;
  }

  return (
    <Grid
      item
      xs
      container
      sx={{ backgroundColor: backgroundColor ?? colors.grey["300"] }}
    >
      <Grid container gap={1} xs={12}>
        <Grid item xs={4}>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        {search && (
          <Grid item xs>
            <TextField
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              label="Sök"
            />
          </Grid>
        )}
        {manageColumns && (
          <Grid item xs>
            <DropdownButton label="Kolumner" disableCloseOnPress>
              {columns.map((col, idx) =>
                col.hidable ? (
                  <MenuItem key={idx}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={shownColumns[idx]}
                          onChange={(e) => {
                            setShownColumns((prev) =>
                              prev.map((val, i) =>
                                i === idx ? e.target.checked : val
                              )
                            );
                          }}
                        />
                      }
                      label={col.label}
                    />
                  </MenuItem>
                ) : null
              )}
            </DropdownButton>
          </Grid>
        )}
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <List dense={dense}>
          <ListItem>
            {!!setSelected && (
              <Checkbox
                size="small"
                checked={allSelected}
                indeterminate={indeterminate}
                onChange={() => setSelected(noneSelected ? [...data] : [])}
              />
            )}
            <Grid
              container
              gap={1}
              alignItems="center"
              paddingX={details ? "16px" : undefined}
            >
              {columns.map((col, idx) =>
                manageColumns && col.hidable && !shownColumns[idx] ? null : (
                  <Grid
                    key={idx}
                    item
                    xs={getColumnWidth(col.width)}
                    minWidth={col.minWidth}
                    container
                    gap={1}
                    alignItems="center"
                  >
                    <ColumnLabel
                      column={col}
                      order={order}
                      setOrder={setOrder}
                      fontSize={labelSize}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </ListItem>
          {filteredData.map((row) => (
            <MaybeExandableListItem
              key={row._id}
              row={row}
              columns={columns}
              details={details}
              selected={selected}
              setSelected={setSelected}
              onClick={onClick}
              manageColumns={manageColumns}
              shownColumns={shownColumns}
              getColumnWidth={getColumnWidth}
            />
          ))}
          {columns.find((col) => !!col.total) && (
            <ListItem sx={{ borderTop: 2 }}>
              <Grid container gap={1}>
                {!!setSelected && <Grid item xs="auto" minWidth="38px" />}
                {columns.map((cell, idx) => (
                  <Grid key={idx} item xs={getColumnWidth(cell.width)}>
                    {cell.total?.(data)}
                  </Grid>
                ))}
              </Grid>
            </ListItem>
          )}
        </List>
      </Grid>
    </Grid>
  );
}

function useOrMakeState<T>(
  stateInit: T,
  state?: T,
  setState?: Dispatch<SetStateAction<T>>
) {
  if (state !== undefined && setState !== undefined) {
    return [state, setState] as [T, Dispatch<SetStateAction<T>>];
  }
  return useState<T>(stateInit);
}

const StyledButton = styled(Button)({
  textTransform: "none",
  justifyContent: "left",
});

function ColumnLabel<T>({
  column,
  order,
  setOrder,
  fontSize,
}: {
  column: SummaryListColumn<T>;
  order: OrderData<T>;
  setOrder: Dispatch<SetStateAction<OrderData<T>>>;
  fontSize?: number;
}) {
  if (column.order) {
    return (
      <>
        <StyledButton
          sx={{ fontSize }}
          variant="text"
          color="info"
          onClick={() => {
            if (order.name === column.label) {
              setOrder({
                order: column.order,
                asc: !order.asc,
                name: column.label,
              } as OrderData<T>);
            } else {
              setOrder({
                asc: true,
                name: column.label,
                order: column.order,
              } as OrderData<T>);
            }
          }}
        >
          {column.label}
          {order.name === column.label &&
            (order.asc ? <ArrowDropDown /> : <ArrowDropUp />)}
        </StyledButton>
        {column.action}
      </>
    );
  }
  return (
    <>
      <Typography variant="body2" fontSize={fontSize}>
        {column.label}
      </Typography>
      {column.action}
    </>
  );
}

type MaybeExandableListItemProps<T extends { _id: string }> = {
  row: T;
  columns: SummaryListColumn<T>[];
  details?: (row: T) => ReactNode;
  selected?: T[];
  setSelected?: Dispatch<SetStateAction<T[]>>;
  onClick?: (row: T) => any;
  shownColumns: boolean[];
  manageColumns?: boolean;
  getColumnWidth: (
    width?: boolean | GridSize
  ) => boolean | GridSize | undefined;
};

function MaybeExandableListItem<T extends { _id: string }>({
  columns,
  details,
  row,
  selected,
  setSelected,
  onClick,
  manageColumns,
  shownColumns,
  getColumnWidth,
}: MaybeExandableListItemProps<T>) {
  if (!details) {
    if (onClick) {
      return (
        <ListItemButton onClick={() => onClick(row)}>
          {!!selected && !!setSelected && (
            <Checkbox
              size="small"
              checked={!!selected.find((val) => val._id === row._id)}
              onChange={(_e, checked) => {
                if (!checked) {
                  setSelected(selected.filter((val) => val._id !== row._id));
                } else if (
                  checked &&
                  !selected.find((val) => val._id === row._id)
                ) {
                  setSelected([...selected, row]);
                }
              }}
            />
          )}
          <Grid container gap={1} alignItems="center">
            {columns.map((cell, idx) =>
              manageColumns && cell.hidable && !shownColumns[idx] ? null : (
                <Grid
                  key={idx}
                  item
                  xs={getColumnWidth(cell.width)}
                  minWidth={cell.minWidth}
                  container
                >
                  {cell.getValue(row)}
                </Grid>
              )
            )}
          </Grid>
        </ListItemButton>
      );
    } else {
      return (
        <ListItem>
          {!!selected && !!setSelected && (
            <Checkbox
              size="small"
              checked={!!selected.find((val) => val._id === row._id)}
              onChange={(_e, checked) => {
                if (!checked) {
                  setSelected(selected.filter((val) => val._id !== row._id));
                } else if (
                  checked &&
                  !selected.find((val) => val._id === row._id)
                ) {
                  setSelected([...selected, row]);
                }
              }}
            />
          )}
          <Grid container gap={1} alignItems="center">
            {columns.map((cell, idx) =>
              manageColumns && cell.hidable && !shownColumns[idx] ? null : (
                <Grid
                  key={idx}
                  item
                  xs={getColumnWidth(cell.width)}
                  minWidth={cell.minWidth}
                  container
                >
                  {cell.getValue(row)}
                </Grid>
              )
            )}
          </Grid>
        </ListItem>
      );
    }
  }

  return (
    <ListItem key={row._id}>
      {!!selected && !!setSelected && (
        <Grid item xs="auto">
          <Checkbox
            size="small"
            checked={!!selected.find((val) => val._id === row._id)}
            onChange={(_e, checked) => {
              if (!checked) {
                setSelected(selected.filter((val) => val._id !== row._id));
              } else if (
                checked &&
                !selected.find((val) => val._id === row._id)
              ) {
                setSelected([...selected, row]);
              }
            }}
          />
        </Grid>
      )}
      <Grid container gap={1}>
        <Grid item xs>
          <Accordion>
            <AccordionSummary>
              <Grid container alignItems="center" gap={1}>
                {columns.map((cell, idx) =>
                  manageColumns && cell.hidable && !shownColumns[idx] ? null : (
                    <Grid
                      key={idx}
                      item
                      xs={getColumnWidth(cell.width)}
                      minWidth={cell.minWidth}
                      container
                    >
                      {cell.getValue(row)}
                    </Grid>
                  )
                )}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>{details(row)}</AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </ListItem>
  );
}
