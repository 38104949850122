import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useSync } from "serversync";
import { DataGrid, GridColumns, MakeSetStateActionFromZustandStore } from "ui";
import { ChooseDiffingLists, useListSync } from "./lists";
import { ChooseDiffingTexts, useTextSync } from "./texts";

const setListUpload = MakeSetStateActionFromZustandStore(useListSync, "upload");
const setListDownload = MakeSetStateActionFromZustandStore(
  useListSync,
  "download"
);
const setTextUpload = MakeSetStateActionFromZustandStore(useTextSync, "upload");
const setTextDownload = MakeSetStateActionFromZustandStore(
  useTextSync,
  "download"
);

export function SyncDialog() {
  const listsNotOnServer = useListSync((state) => state.listsNotOnServer);
  const listsNotOnLocal = useListSync((state) => state.listsNotOnLocal);
  const listUpload = useListSync((state) => state.upload);
  const listDownload = useListSync((state) => state.download);

  const textsNotOnServer = useTextSync((state) => state.textsNotOnServer);
  const textsNotOnLocal = useTextSync((state) => state.textsNotOnLocal);
  const textUpload = useTextSync((state) => state.upload);
  const textDownload = useTextSync((state) => state.download);

  const open = useSync((state) => state.open);
  const startSync = useSync((state) => state.startSync);
  const { t } = useTranslation();

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>{t("Choose lists and texts to keep")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary>{t("What's this?")}</AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {t(
                    "This dialog is here because the synced lists/texts on the server and the lists/texts you have locally does not match. It gives you a chance to choose which lists/texts to keep, and which to discard."
                  )}
                </Typography>
                <Typography>
                  {t(
                    "If a list/text has been updated since your last sync or you have multiple lists/texts with the same name you will be asked to choose which of the versions to keep."
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <ChoosingList
            rows={listsNotOnServer}
            selected={listUpload}
            setSelected={setListUpload}
            idField="_id"
            text="Local lists that are not on the server"
          />
          <ChoosingList
            rows={listsNotOnLocal}
            selected={listDownload}
            setSelected={setListDownload}
            idField="_id"
            text="Server lists that are not on this computer"
          />
          <ChooseDiffingLists />
          <ChoosingList
            rows={textsNotOnServer}
            selected={textUpload}
            setSelected={setTextUpload}
            idField="_id"
            text="Local texts that are not on the server"
          />
          <ChoosingList
            rows={textsNotOnLocal}
            selected={textDownload}
            setSelected={setTextDownload}
            idField="_id"
            text="Server texts that are not on this computer"
          />
          <ChooseDiffingTexts />
          <Grid item xs>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                startSync();
              }}
            >
              {t("Save selected")}
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                useSync.setState({ open: false, active: false });
              }}
            >
              {t("Not now!")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

type ChoosingListProps<T> = {
  rows: T[];
  selected: T[];
  setSelected: Dispatch<SetStateAction<T[]>>;
  idField: keyof T;
  text: string;
};

export function ChoosingList<T>({
  rows,
  selected,
  setSelected,
  idField,
  text,
}: ChoosingListProps<T>) {
  const { t } = useTranslation();

  const columns: GridColumns<T> = [
    {
      name: t("Name"),
      field: "name" as keyof T,
    },
  ];

  if (!rows.length) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Accordion>
        <AccordionSummary>
          {t(text)}{" "}
          {t("({selected} of {total} saved)", {
            selected: selected.length,
            total: rows.length,
          })}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs>
              <Button onClick={() => setSelected(rows)}>
                {t("Select all")}
              </Button>
            </Grid>
            <Grid item xs>
              <Button onClick={() => setSelected([])}>
                {t("Deselect all")}
              </Button>
            </Grid>
          </Grid>
          <DataGrid
            rows={rows}
            defaultSortBy={t("Name")}
            selected={selected}
            setSelected={setSelected}
            columns={columns}
            idField={idField}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
