import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Settings } from "./settings/SettingsMenu";
import Editor from "tiptap/Editor";
import "./App.css";
import { colors, useSettingsStore } from "./settings/Appearance";
import { QuickAddAbbreviation } from "quick-add-abbreviation/QuickAddAbbreviation";
import { i18nInit } from "translations";
import { ErrorBoundary } from "ErrorBoundary/ErrorBoundary";
import { Dialogs, useLogin } from "ui";
import { LoginPage } from "login/Login";
import { ThemeProvider } from "@mui/material";
import { DarkChapelTheme, IlluminateTheme } from "theme/Theme";
import { ServiceWorkerWrapper } from "ServiceWorkerWrapper";
import { SyncDialog } from "serversync/Dialog";

i18nInit();

function App() {
  const user = useLogin((state) => state.user);
  const loading = useLogin((state) => state.loading);
  const theme = useSettingsStore((state) => state.theme);

  return (
    <ErrorBoundary>
      <ThemeProvider
        theme={theme === "Illuminate" ? IlluminateTheme : DarkChapelTheme}
      >
        {user && !loading ? <AppContent /> : <LoginPage />}
        <ServiceWorkerWrapper />
      </ThemeProvider>
    </ErrorBoundary>
  );
}

function AppContent() {
  const currentColorScheme = useSettingsStore(
    (state) => state.currentColorScheme
  );
  const theme = useSettingsStore((state) => state.theme);

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: colors[currentColorScheme.background],
        color: colors[currentColorScheme.text],
      }}
    >
      <ToastContainer theme={theme === "Illuminate" ? "light" : "dark"} />
      <Editor />
      <QuickAddAbbreviation />
      <Settings />
      <Dialogs />
      <SyncDialog />
    </div>
  );
}

export default App;
