export function datetime(time: number) {
  return new Date(time).toLocaleString(undefined, {
    timeStyle: "short",
    dateStyle: "short",
  });
}

export function datetimeduration(from: number, to: number) {
  return `${new Date(from).toLocaleString(undefined, {
    timeStyle: "short",
    dateStyle: "short",
  })} - ${new Date(to).toLocaleTimeString(undefined, { timeStyle: "short" })}`;
}

export function removeWordJoiner(str: string) {
  return str.replaceAll("\u2060", "");
}

export function formatAmountNumber(value: number) {
  const val: any = value;
  if (typeof val !== "number") {
    if (typeof val === "string") {
      parseFloat(val).toLocaleString(undefined, { maximumFractionDigits: 2 });
    }
    return "-";
  }
  return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
}
