import { v4 as uuidv4 } from "uuid";
import { AbbreviationList } from "contextproviders/Abbreviations";
import { removeWordJoiner } from "utility";

export type ProgramJSONFormat = {
  lists: {
    id: number;
    name: string;
    type: number;
    active: boolean;
    createdAt: string;
    updatedAt: string;
    _id: string;
    priority: number;
  }[];
  abbreviations: {
    listId: number;
    abbreviation: string;
    word: string;
    createdAt: string;
    updatedAt: string;
    _id: string;
    id: string;
  }[];
};

function isPrePWAFormat(obj: any): obj is ProgramJSONFormat {
  return !!obj?.lists;
}

//its possible to clear entire localforage with
//indexedDB.deleteDatabase('localforage').onsuccess=(function(e){console.log("Delete OK");})

export type AbbreviationListJSON = ReturnType<typeof listToJSON> & {
  id?: string;
};

export function listToJSON(list: AbbreviationList) {
  return { ...list, abb2expanded: Object.fromEntries(list.abb2expanded) };
}

export function JSONtoList(
  jsonList:
    | AbbreviationListJSON
    | AbbreviationListJSON[]
    | ProgramJSONFormat
    | VeloNoteSettings
): AbbreviationList[] {
  const now = Date.now();
  if (isVelonoteSettings(jsonList)) {
    return JSONtoList(velonoteToIllumitype(jsonList));
  } else if (isPrePWAFormat(jsonList)) {
    const lists = jsonList.lists.map((list) => ({
      name: list.name,
      _id: uuidv4(),
      createdAt:
        Date.parse(list.createdAt) || Date.parse(list.updatedAt) || now,
      updatedAt: Date.parse(list.updatedAt) || now,
      abb2expanded: new Map<string, [number, string]>(
        jsonList.abbreviations
          .filter((abb) => abb.listId === list.id)
          .map((abb) => [
            abb.abbreviation,
            [Date.parse(abb.updatedAt), removeWordJoiner(abb.word)],
          ])
      ),
    }));
    return lists;
  } else if (Array.isArray(jsonList)) {
    return jsonList.map((list) => {
      const [subList] = JSONtoList(list);
      return subList;
    });
  } else {
    let abb2expanded = Object.entries(jsonList.abb2expanded);
    if (abb2expanded.length && typeof abb2expanded[0][1] === "string") {
      abb2expanded = abb2expanded.map((value) => [
        value[0],
        [now, removeWordJoiner(value[1] as unknown as string)],
      ]);
    }
    return [
      {
        name: jsonList.name,
        _id: jsonList.id || jsonList._id,
        createdAt:
          typeof jsonList.createdAt === "string"
            ? Date.parse(jsonList.createdAt)
            : jsonList.createdAt,
        updatedAt:
          typeof jsonList.updatedAt === "string"
            ? Date.parse(jsonList.updatedAt)
            : jsonList.updatedAt,
        abb2expanded: new Map(abb2expanded),
      },
    ];
  }
}

function isVelonoteSettings(data: any): data is VeloNoteSettings {
  return !!data.autocorrect || !!data.shortform;
}

interface VeloNoteSettings {
  autocorrect: Record<string, { list: Record<string, string> }>;
  shortform: Record<string, { shortforms: Record<string, string> }>;
}

function velonoteToIllumitype(json: VeloNoteSettings): AbbreviationListJSON[] {
  const now = Date.now();
  let standard: AbbreviationListJSON[] = [];
  if (json.autocorrect !== undefined) {
    standard =
      Object.entries(json.autocorrect).map(([name, list]) => ({
        name,
        _id: uuidv4(),
        createdAt: now,
        updatedAt: now,
        abb2expanded: Object.fromEntries(
          Object.entries(list.list).map(([abbreviation, word]) => [
            abbreviation,
            [now, word],
          ])
        ),
      })) || [];
  }

  let tillagg: AbbreviationListJSON[] = [];

  if (json.shortform !== undefined) {
    tillagg =
      Object.entries(json.shortform).map(([name, list], idx) => ({
        name,
        _id: uuidv4(),
        createdAt: now,
        updatedAt: now,
        abb2expanded: Object.fromEntries(
          Object.entries(list.shortforms).map(([abbreviation, word]) => [
            abbreviation,
            [now, word],
          ])
        ),
      })) || [];
  }

  return [...standard, ...tillagg];
}
