import type { AbbrevationStats } from "contextproviders/AbbreviationStatistics";
import { emptyStatsChunk } from "contextproviders/AbbreviationStatistics";

export function uniqueSessionNames(stats: AbbrevationStats[]) {
  return stats
    .map((stat) => stat.sessionName)
    .filter((v, i, a) => a.indexOf(v) === i);
}

/**
 * Add up counts for a list of AbbrevationStats
 */
export function mergeStats(stats: AbbrevationStats[]) {
  const mergedStat = emptyStatsChunk();
  for (const stat of stats) {
    for (const [key, { expanded, count }] of Object.entries(stat.abb)) {
      if (mergedStat.abb[key]) {
        mergedStat.abb[key].count += count;
      } else {
        mergedStat.abb[key] = { expanded, count };
      }
    }

    for (const [key, { abb, count }] of Object.entries(stat.expanded)) {
      if (mergedStat.expanded[key]) {
        mergedStat.expanded[key].count += count;
      } else {
        mergedStat.expanded[key] = { abb, count };
      }
    }
  }
  return mergedStat;
}

/**
 * `<input type="datetime-local">` wants a particular string format in local time such as
 *
 * "2021-12-15T20:15"
 *
 * or
 *
 * "2021-12-15T20:15:34"
 *
 * which is almost just date.toISOString() but not quite.
 */
export function datetimelocalString(date: Date) {
  return localIsoString(date).slice(0, 16);
}

function localIsoString(d: Date) {
  return new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString();
}

export function avoidedKeyStrokes(stats: AbbrevationStats) {
  let N = 0;
  Object.entries(stats.abb).forEach(
    ([abb, { expanded, count }]) =>
      (N += count * (expanded.length - abb.length))
  );
  return N;
}

export function avoidableKeyStrokes(stats: AbbrevationStats) {
  let N = 0;
  Object.entries(stats.expanded).forEach(
    ([expanded, { abb, count }]) =>
      (N += count * (expanded.length - abb.length))
  );
  return N;
}

/**
 * utility for table sorting
 */
export function getComparator<Key extends keyof any>(
  order: "asc" | "desc",
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
