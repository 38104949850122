import { Component, PropsWithChildren } from "react";

interface State {
  hasError: boolean;
  error: any;
  errorInfo: any;
  showStack: boolean;
  status: string;
}

export class ErrorBoundary extends Component<PropsWithChildren<{}>, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      showStack: false,
      status: "",
    };
    this.resetIndexDb = this.resetIndexDb.bind(this);
  }

  static getDerivedStateFromError(error: any) {
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error, errorInfo, status: "" });
  }

  resetIndexDb() {
    const idb = indexedDB.deleteDatabase("localforage");
    idb.onsuccess = (e) => {
      window.location.reload();
    };
    idb.onerror = (e) => {
      this.setState({ status: "Error deleting database" });
    };
    idb.onblocked = (e) => {
      this.setState({
        status: "Deleting database was blocked by open connections",
      });
    };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: "center" }}>
          <div>
            <h1>Something went wrong.</h1>
          </div>
          <div style={{ marginBottom: 75 }}>
            <button onClick={() => window.location.reload()}>Reload app</button>
          </div>
          {this.state.errorInfo?.componentStack && (
            <div>
              <button
                onClick={() =>
                  this.setState({ showStack: !this.state.showStack })
                }
              >
                {this.state.showStack ? "Hide stack" : "Show stack"}
              </button>
            </div>
          )}
          {this.state.error && <div>{this.state.error.toString()}</div>}
          {this.state.showStack && (
            <div style={{ fontSize: 12, whiteSpace: "pre-wrap" }}>
              {this.state.errorInfo.componentStack}
            </div>
          )}
          <div style={{ marginTop: 50 }}>
            <div>You can reset the app with this button.</div>
            <div>
              WARNING! This will remove all your settings and word-lists stored
              in the app
            </div>
            <div>{this.state.status}</div>
            <div>
              <button onClick={this.resetIndexDb}>Reset app</button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
