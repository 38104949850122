import Highlight from "@tiptap/extension-highlight";
import { Plugin, PluginKey, Selection } from "prosemirror-state";
import { ySyncPluginKey } from "y-prosemirror";

const markDistance = 7;
let writesWithEmptyLineAtEnd = 0;

export default Highlight.extend({
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("highlightHandler"),
        appendTransaction(transactions, oldState, newState) {
          if (!transactions.length) {
            return null;
          }

          const transaction = transactions[transactions.length - 1];
          const isRemoteChange =
            transaction.getMeta(ySyncPluginKey)?.isChangeOrigin;
          const alreadyTriggered = transactions.reduce<boolean>(
            (prev, cur) =>
              !!prev || !!cur.getMeta("appendTransactionHighlight"),
            false
          );

          if (
            alreadyTriggered ||
            isRemoteChange ||
            !transaction.docChanged ||
            !newState.selection.empty
          ) {
            return;
          }

          const anchor = newState.selection.$anchor;
          const spaceUntilEnd =
            anchor.parent.nodeSize - 2 - anchor.parentOffset;

          const node = newState.selection.$from.node(1);

          const highlightActive = !!newState.selection.$anchor
            .marks()
            ?.find((mark) => mark.type.name === "highlight");

          const lastChild = newState.doc.lastChild;

          if (spaceUntilEnd > 10 || lastChild !== node) {
            if (
              lastChild !== node &&
              lastChild?.textContent.trim().length === 0
            ) {
              if (++writesWithEmptyLineAtEnd > 10) {
                writesWithEmptyLineAtEnd = 0;
                const end = Selection.atEnd(newState.doc);
                return newState.tr.deleteRange(
                  end.from - 1 - end.$from.parentOffset,
                  end.to
                );
              }
            }
            if (!highlightActive) {
              const extendBack = Math.min(markDistance, anchor.parentOffset);
              const extendForward = Math.min(markDistance, spaceUntilEnd);
              return newState.tr
                .addMark(
                  anchor.pos - extendBack,
                  anchor.pos + extendForward,
                  // @ts-ignore
                  newState.schema.marks.highlight.instance
                )
                .setMeta("appendTransactionHighlight", true)
                .setMeta(
                  "appendTransactionTimestamp",
                  transactions.reduce<boolean>(
                    (prev, cur) =>
                      !!prev || !!cur.getMeta("appendTransactionTimestamp"),
                    false
                  )
                )
                .setMeta(
                  "avoidAbbreviation",
                  transactions.reduce<boolean>(
                    (prev, cur) => !!prev || !!cur.getMeta("avoidAbbreviation"),
                    false
                  )
                )
                .setStoredMarks(transaction.storedMarks);
            }
          } else if (highlightActive) {
            return newState.tr.removeStoredMark(
              // @ts-ignore
              newState.schema.marks.highlight.instance
            );
          } else {
            writesWithEmptyLineAtEnd = 0;
          }
        },
      }),
    ];
  },
});
