import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import type { AbbrevationStats } from "contextproviders/AbbreviationStatistics";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { getComparator } from "./utils";
import { Paper, Toolbar } from "ui";

interface Data {
  expanded: string;
  abb: string;
  count: number;
  avoidable: number;
}

const headings = [
  { numeric: false, id: "expanded" as keyof Data, label: "Word" },
  { numeric: false, id: "abb" as keyof Data, label: "Abbreviation" },
  { numeric: true, id: "count" as keyof Data, label: "Count" },
  {
    numeric: true,
    id: "avoidable" as keyof Data,
    label: "Unnessesary keystrokes",
  },
];

interface Props {
  stats: AbbrevationStats;
}

export default function SortableTable({ stats }: Props) {
  const { t } = useTranslation();
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<keyof Data>("count");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<Data[]>([]);

  useEffect(() => {
    const tablerows = Object.entries(stats.expanded).map(
      ([expanded, { abb, count }]) => ({
        expanded,
        abb,
        count,
        avoidable: count * (expanded.length - abb.length),
      })
    );
    setRows(tablerows);
  }, [stats]);

  const createSortHandler = (property: keyof Data) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const NemptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Paper xs={12} md={12} lg={6}>
      <Toolbar>
        <Typography variant="h6">{t("Missed abbreviations")}</Typography>
      </Toolbar>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headings.map((heading) => (
                <TableCell
                  key={heading.id}
                  align={heading.numeric ? "right" : "left"}
                >
                  <TableSortLabel
                    active={orderBy === heading.id}
                    direction={orderBy === heading.id ? order : "asc"}
                    onClick={createSortHandler(heading.id)}
                  >
                    {t(heading.label)}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice()
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow key={row.expanded}>
                    <TableCell align={headings[0].numeric ? "right" : "left"}>
                      {row.expanded}
                    </TableCell>
                    <TableCell align={headings[1].numeric ? "right" : "left"}>
                      {row.abb}
                    </TableCell>
                    <TableCell align={headings[2].numeric ? "right" : "left"}>
                      {row.count}
                    </TableCell>
                    <TableCell align={headings[3].numeric ? "right" : "left"}>
                      {row.avoidable}
                    </TableCell>
                  </TableRow>
                );
              })}
            {NemptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * NemptyRows,
                }}
              >
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={t("Rows per page")}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t("of")} ${count}`
        }
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
    </Paper>
  );
}
