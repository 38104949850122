import { toggleDialog } from "PreWritten";
import { useAbbreviations } from "contextproviders/Abbreviations";
import { t } from "i18next";
import { addHotkey, removeHotkey } from "keybindings/KeyBindings";
import { toast } from "react-toastify";
import { defaultColorSchemes, useSettingsStore } from "settings/Appearance";
import { useEditor } from "tiptap/EditorStore";
import { editorSelectedWordAndLine } from "word-lookup/wordLookup";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type Keybindings = {
  abbreviateWithoutSpace: string;
  //spaceWithoutAbbreviating: string;
  lookupWord: string;
  togglePrewrittenDialog: string;
  cycleColorSchema: string;
  decreaseFontSize: string;
  increaseFontSize: string;
  clearEditor: string;
};

type KeyCallbacks<T> = {
  [K in keyof T]: () => void;
};

const bindingFunctions: KeyCallbacks<Keybindings> = {
  abbreviateWithoutSpace() {
    useEditor.getState().editor.commands.addWordJoiner();
  },
  /* spaceWithoutAbbreviating() {
    useEditor.getState().editor.commands.toggleWordBreakWithoutAbbreviating();
  }, */
  lookupWord() {
    const [word, line] = editorSelectedWordAndLine();
    console.log(`"${word}"`, line);
    const wordLowercase = word.toLowerCase();
    const lineLowercase = line.toLowerCase();
    const { mergedListexpanded2abbs, mergedList } = useAbbreviations.getState();
    const abbs = mergedListexpanded2abbs
      .get(wordLowercase)
      ?.map((abb) => [
        abb,
        mergedList.abb2expanded.get(abb)?.[1]?.toLowerCase(),
      ])
      ?.filter(
        ([_abb, expanded]) => expanded && lineLowercase.endsWith(expanded)
      ) as Array<[string, string]> | undefined;
    if (abbs?.length) {
      abbs.sort((a, b) => b[1].length - a[1].length);
      console.log(abbs);
      while (abbs[0][1].length > abbs.at(-1)![1].length) {
        abbs.pop();
      }
      toast.success(
        abbs.reduce<string>((prev, cur) => `${prev} ${cur[0]}`, "").trim()
      );
    }
  },
  cycleColorSchema() {
    const { colorSchemes, currentColorScheme } = useSettingsStore.getState();
    const index = colorSchemes.findIndex(
      (val) => val.id === currentColorScheme.id
    );
    if (index === -1) {
      useSettingsStore.setState({ currentColorScheme: defaultColorSchemes[0] });
    } else if (index + 1 < colorSchemes.length) {
      useSettingsStore.setState({
        currentColorScheme: colorSchemes[index + 1],
      });
    } else {
      useSettingsStore.setState({ currentColorScheme: colorSchemes[0] });
    }
  },
  togglePrewrittenDialog() {
    toggleDialog();
  },
  increaseFontSize() {
    useSettingsStore.setState(({ fontSize }) => ({
      fontSize: Math.min(fontSize + 1, 120),
    }));
  },
  decreaseFontSize() {
    useSettingsStore.setState(({ fontSize }) => ({
      fontSize: Math.max(fontSize - 1, 12),
    }));
  },
  clearEditor() {
    useEditor.getState().editor.commands.clearAllText();
  },
} as const;

type KeybindingsStore = Keybindings & {
  showModal: boolean;
  updateKeybinding: (binding: keyof Keybindings, key: string) => void;
  catchKeyForBinding: (binding: keyof Keybindings) => void;
};

const modifiers = ["Control", "Alt", "Shift", "Mod"];

export const useKeybindings = create<KeybindingsStore>()(
  persist(
    (set, get) => ({
      abbreviateWithoutSpace: "mod+space",
      //spaceWithoutAbbreviating: "Tab",
      lookupWord: "F1",
      togglePrewrittenDialog: "F2",
      cycleColorSchema: "F8",
      decreaseFontSize: "F6",
      increaseFontSize: "F7",
      clearEditor: "F4",
      showModal: false as boolean,
      updateKeybinding(binding, key) {
        const current = get();
        removeHotkey(current[binding]);
        for (const clashing in bindingFunctions) {
          if (
            clashing !== binding &&
            current[clashing as keyof Keybindings] === key
          ) {
            removeHotkey(key);
            set({ [clashing]: " - " });
          }
        }
        addHotkey(key, bindingFunctions[binding]);
        set({ [binding]: key });
      },
      catchKeyForBinding(binding) {
        const { catchKeyForBinding, updateKeybinding } = get();
        window.addEventListener(
          "keydown",
          (e: KeyboardEvent) => {
            if (modifiers.includes(e.key)) {
              catchKeyForBinding(binding);
              return;
            }
            const key = `${e.altKey ? "alt+" : ""}${
              e.shiftKey ? "shift+" : ""
            }${e.ctrlKey ? "mod+" : ""}${e.metaKey ? "mod+" : ""}${
              e.key === " " ? "space" : e.key
            }`;
            updateKeybinding(binding, key);
            set({ showModal: false });
          },
          { once: true }
        );
        set({ showModal: true });
      },
    }),
    {
      name: "KeyBindingsStore",
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([name, val]) => name !== "showModal")
        ),
      onRehydrateStorage() {
        return (state, error) => {
          if (error) {
            toast.error(t("Could not load your keybindings from localStorage"));
          } else if (state) {
            for (const key in bindingFunctions) {
              const typedKey = key as keyof typeof bindingFunctions;
              addHotkey(state[typedKey], bindingFunctions[typedKey]);
            }
          } else {
            toast.error(
              t(
                "Failed to find any keybindings. Some hotkeys may not work as expected."
              )
            );
          }
        };
      },
    }
  )
);
