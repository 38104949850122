import { v4 as uuidv4 } from "uuid";
import { removeWordJoiner } from "utility";
import { ModifyKey, useAbbreviations, AbbreviationList, useLists } from ".";
import { createList, useSync } from "serversync";
import { syncQuery } from "serversync";

export function newList(listname: string): AbbreviationList {
  const now = Date.now();
  return {
    name: listname,
    _id: uuidv4(),
    createdAt: now,
    updatedAt: now,
    abb2expanded: new Map(),
  };
}

const listIds = () =>
  useLists.getState().abbreviationLists.map((list) => list._id);
export const hasListId = (listId: string) => listIds().includes(listId);
export const listFromId = (listId: string) =>
  useLists.getState().abbreviationLists.find((list) => list._id === listId);

export function nameFromId(listId: string) {
  const list = listFromId(listId);
  if (list) {
    return list.name;
  } else {
    return "no list";
  }
}

export function addEmptyList(listname: string) {
  if (listname) {
    const list = newList(listname);
    useLists.setState(({ abbreviationLists }) => ({
      abbreviationLists: [...abbreviationLists, list],
    }));
    createList(list);
  }
}

export function addListFromMerged(listname: string) {
  const { mergedList } = useAbbreviations.getState();
  if (listname) {
    const newlist = newList(listname);
    // sloppy deep-copy of the map since it holds arrays
    newlist.abb2expanded = new Map(
      JSON.parse(JSON.stringify(Array.from(mergedList.abb2expanded)))
    );
    useLists.setState(({ abbreviationLists }) => ({
      abbreviationLists: [...abbreviationLists, newlist],
    }));
    createList(newlist);
  }
}

export function addLists(lists: AbbreviationList[]) {
  const { abbreviationLists } = useLists.getState();
  const newLists = lists.filter((list) => {
    const existingList = abbreviationLists.find(
      (existing) => existing._id === list._id
    );
    if (existingList) {
      existingList.name = list.name;
      existingList.createdAt = list.createdAt;
      existingList.updatedAt = list.updatedAt;
      existingList.abb2expanded = list.abb2expanded;
      return false;
    }
    return true;
  });
  useLists.setState(({ abbreviationLists }) => ({
    abbreviationLists: [...abbreviationLists, ...newLists],
  }));
  for (const list of newLists) {
    createList(list);
  }
}

export function removeList(listId: string) {
  const { activeListIds } = useAbbreviations.getState();
  useAbbreviations.setState(({ activeListIds }) => ({
    activeListIds: activeListIds.filter((id) => id !== listId),
  }));
  useLists.setState(({ abbreviationLists }) => ({
    abbreviationLists: abbreviationLists.filter((list) => list._id !== listId),
  }));
  if (activeListIds.includes(listId)) {
    mergeLists();
  }
  if (useSync.getState().active) {
    syncQuery.query("removeList", { id: listId });
  }
}

export function updateListName(id: string, name: string) {
  const list = listFromId(id);
  if (list) {
    list.name = name;
    list.updatedAt = Date.now();
    useLists.setState(({ abbreviationLists }) => ({
      abbreviationLists: [...abbreviationLists],
    }));
    if (useSync.getState().active) {
      syncQuery.query("changeListName", {
        id,
        name,
        updatedAt: list.updatedAt,
      });
    }
  }
}

export function updateListId(oldId: string, newId: string) {
  const list = listFromId(oldId);
  if (list) {
    list._id = newId;
    useLists.setState(({ abbreviationLists }) => ({
      abbreviationLists: [...abbreviationLists],
    }));

    useAbbreviations.setState(({ listHotkeys, activeListIds }) => ({
      listHotkeys: listHotkeys.map((list) => ({
        name: list.name,
        ids: list.ids.map((id) => (id === oldId ? newId : id)),
      })),
      activeListIds: activeListIds.map((id) => (id === oldId ? newId : id)),
    }));
  }
}

export function modifyKeys({
  add,
  remove,
}: {
  add?: ModifyKey[];
  remove?: ModifyKey[];
}) {
  const now = Date.now();
  const { abbreviationLists } = useLists.getState();
  for (const key of remove || []) {
    const list = abbreviationLists.find((list) => list._id === key.listId);
    if (list) {
      list.abb2expanded.delete(key.abb);
      list.updatedAt = now;
    }
  }
  const removeWordJoinersAdd =
    add?.map(({ listId, abb, word }) => ({
      listId,
      abb: removeWordJoiner(abb),
      word: removeWordJoiner(word),
    })) || [];
  for (const key of removeWordJoinersAdd) {
    const list = abbreviationLists.find((list) => list._id === key.listId);
    if (list) {
      list.abb2expanded.set(key.abb, [now, key.word]);
      list.updatedAt = now;
    }
  }
  useLists.setState({ abbreviationLists: [...abbreviationLists] });
  mergeLists();
  if (useSync.getState().active) {
    syncQuery.query("modifyLists", {
      add: removeWordJoinersAdd || [],
      remove: remove || [],
      updatedAt: now,
    });
  }
}

export function activate(listId: string) {
  const { activeListIds } = useAbbreviations.getState();
  if (hasListId(listId) && !activeListIds.includes(listId)) {
    useAbbreviations.setState(({ listHotkeys, activeHotkeyId }) => {
      listHotkeys[activeHotkeyId].ids.unshift(listId);
      return {
        activeListIds: [...listHotkeys[activeHotkeyId].ids],
        listHotkeys: [...listHotkeys],
      };
    });
    mergeLists();
  }
}

export function deactivate(listId: string) {
  useAbbreviations.setState(({ listHotkeys, activeHotkeyId }) => {
    listHotkeys[activeHotkeyId].ids = listHotkeys[activeHotkeyId].ids.filter(
      (id) => id !== listId
    );
    return {
      activeListIds: [...listHotkeys[activeHotkeyId].ids],
      listHotkeys: [...listHotkeys],
    };
  });
  mergeLists();
}

export function toggleActive(listId: string) {
  const { activeListIds } = useAbbreviations.getState();
  if (activeListIds.includes(listId)) {
    deactivate(listId);
  } else {
    activate(listId);
  }
}

export function isActive(listId: string) {
  return useAbbreviations.getState().activeListIds.includes(listId);
}
// priority

/**
 * move the index element with index`fromIndex` to position `toIndex` and shift the rest accordingly.
 */
function movePositionByIndex(fromIndex: number, toIndex: number) {
  const { listHotkeys, activeHotkeyId } = useAbbreviations.getState();
  const [element] = listHotkeys[activeHotkeyId].ids.splice(fromIndex, 1);
  listHotkeys[activeHotkeyId].ids.splice(toIndex, 0, element);
  useAbbreviations.setState({
    activeListIds: [...listHotkeys[activeHotkeyId].ids],
    listHotkeys: [...listHotkeys],
  });
  mergeLists();
}

/**
 * move listIdA to where listIdB is and shift the rest accordingly.
 */
export function movePosition(listIdA: string, listIdB: string) {
  const { activeListIds } = useAbbreviations.getState();
  const a = activeListIds.indexOf(listIdA);
  const b = activeListIds.indexOf(listIdB);
  if (a !== -1 && b !== -1) {
    return movePositionByIndex(a, b);
  }
}

/**
 * Return a new merged list with name listname. Created from listNames prioritized in order.
 *
 * Prioritized in order means first list is king if for example key k exist in all lists.
 */
export async function mergeLists() {
  setTimeout(() => {
    const merged = newList("merged");
    const { activeListIds } = useAbbreviations.getState();
    for (let id of activeListIds.slice().reverse()) {
      const list = listFromId(id);
      if (list) {
        merged.abb2expanded = new Map([
          ...merged.abb2expanded.entries(),
          ...list.abb2expanded.entries(),
        ]);
      }
    }
    useAbbreviations.setState({
      mergedList: merged,
      mergedListexpanded2abbs: reverseMappingFromLastWord(merged.abb2expanded),
    });
  }, 10);
}

export function nextActiveListId(id: string): string {
  const { activeListIds } = useAbbreviations.getState();
  const i = activeListIds.indexOf(id);
  if (i >= 0 && activeListIds.length > 0) {
    return activeListIds[(i + 1) % activeListIds.length]; //wrap around
  } else {
    return activeListIds[0] || "";
  }
}

export function nextActiveListIdExceptLast(id: string): string {
  const { activeListIds } = useAbbreviations.getState();
  const i = activeListIds.indexOf(id);
  if (activeListIds.length === 1 && i === 0) {
    return activeListIds[0];
  } else if (i >= 0 && activeListIds.length > 0) {
    return activeListIds[(i + 1) % (activeListIds.length - 1)]; //wrap around
  } else {
    return activeListIds[0] || "";
  }
}

/**
 * invert object {key: value} => {value: [key]} but only use last word of value string
 * ```
 * const obj = { k: "kek", telg: "trevlig helg", delg: "dålig helg" };
 * reverseMappingFromLastWord(obj)
 * { kek: ["k"], helg: ["telg", "delg"] };
 * ```
 */
export function reverseMappingFromLastWord(
  obj: AbbreviationList["abb2expanded"]
): Map<string, string[]> {
  const lastElement = (v: string[]) => v[v.length - 1];

  const result: Map<string, string[]> = new Map();
  for (const [key, [_updatedAt, expanded]] of obj.entries()) {
    const k = lastElement(expanded.split(" ")).toLowerCase(); //key is last word in "expanded"
    const resultArr = result.get(k) || [];
    result.set(k, [...resultArr, key]);
  }
  return result;
}

export function activateHotkey(index: number) {
  useAbbreviations.setState(({ listHotkeys }) => ({
    activeListIds: listHotkeys[index].ids,
    activeHotkeyId: index,
  }));
  mergeLists();
}
