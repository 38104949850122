import { Extension } from "@tiptap/core";
import { Doc } from "yjs";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { addHotkey, removeHotkey } from "keybindings/KeyBindings";
import { useEditor } from "./EditorStore";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    Chat: {
      /**
       * Move cursor to end of document
       */
      sendMessage: (to: string, message: string) => ReturnType;
    };
  }
}

export interface ChatMessage {
  to: string;
  message: string;
}

export default Extension.create({
  name: "Chat",
  onCreate() {
    const { ydoc, provider } = useEditor.getState();
    const chatArray = ydoc.getArray("chat");
    chatArray.observe((event, tr) => {
      if (tr.local || !provider?.synced) {
        return;
      }
      const added = event.changes.added;

      added.forEach((addition) => {
        const content = addition.content.getContent() as Array<ChatMessage>;
        for (const line of content) {
          toast.success(line.message);
        }
      });
    });
  },
  addCommands() {
    return {
      sendMessage: (to: string, message: string) => () => {
        const { ydoc, provider } = useEditor.getState();
        if (provider) {
          const localState = provider.awareness.getLocalState();
          const name = localState
            ? localState["interpreter"] || localState["listener"] || "unknown"
            : "unknown";

          ydoc.getArray("chat").push([{ to, message: `${name}: ${message}` }]);
        }
        return true;
      },
    };
  },
});

export function ChatModal() {
  const editor = useEditor((state) => state.editor);
  const messages = useEditor((state) => state.messages);
  const [message, setMessage] = useState("");
  const [to, setTo] = useState("All interpreters");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const onClose = () => {
    setOpen(false);
    editor.commands.refocus();
  };

  useEffect(() => {
    addHotkey("mod+r", () => setOpen(!open));

    return () => removeHotkey("mod+r");
  }, [open]);

  const send = () => {
    if (message.trim() !== "") {
      editor?.commands.sendMessage(to, message.trim());
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setMessage("");
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={{ verticalAlign: "flex-start", maxHeight: "60vh", minHeight: 400 }}
      disableRestoreFocus
    >
      <DialogTitle>Chat</DialogTitle>
      <DialogContent>
        {messages.map((message, idx) => (
          <div key={idx}>{message.message}</div>
        ))}
        <TextField
          size="small"
          select
          value={to}
          onChange={(e) => setTo(e.target.value)}
        >
          <MenuItem value="All interpreters">{t("All interpreters")}</MenuItem>
          <MenuItem value="All">{t("All")}</MenuItem>
        </TextField>
        <TextField
          size="small"
          autoFocus
          fullWidth
          autoComplete="off"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "ArrowUp" && to === "All") {
              setTo("All interpreters");
              e.preventDefault();
              e.stopPropagation();
            } else if (e.key === "ArrowDown" && to === "All interpreters") {
              setTo("All");
              e.preventDefault();
              e.stopPropagation();
            } else if (e.key === "Enter") {
              send();
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button size="small" onClick={send}>
          {t("Send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
