export function createDeferredPromise() {
  let resolve: () => void = () => {};
  let reject: () => void = () => {};

  const promise = new Promise<void>((thisResolve, thisReject) => {
    resolve = thisResolve;
    reject = thisReject;
  });

  return Object.assign(promise, { resolve, reject });
}
