import { FromQuery } from "query-client";
import { AbbreviationList, useLists } from "../contextproviders/Abbreviations";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { datetime } from "utility";
import { Delete } from "@mui/icons-material";
import { syncQuery } from "serversync";
import { create } from "zustand";
import { Fragment } from "react";

export type ServerList = FromQuery<typeof syncQuery, "getListInfo">[number];

export type RemovedAbbreviations = FromQuery<typeof syncQuery, "createList">;

export type DiffingLists = {
  lists: (AbbreviationList | ServerList)[];
  selected: number;
};

type ListSyncStore = {
  serverListInfo: ServerList[];
  listsNotOnLocal: ServerList[];
  listsNotOnServer: AbbreviationList[];
  diffingLists: DiffingLists[];
  download: ServerList[];
  upload: AbbreviationList[];
  removedAbbreviations: RemovedAbbreviations[];
};

export const useListSync = create<ListSyncStore>((set, get) => ({
  serverListInfo: [],
  listsNotOnLocal: [],
  listsNotOnServer: [],
  diffingLists: [],
  download: [],
  upload: [],
  removedAbbreviations: [],
}));

if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    if (newModule) {
      newModule.useListSync.setState(useListSync.getState());
    }
  });
}

export function isServerList(
  list: AbbreviationList | ServerList
): list is ServerList {
  return (list as any).abbreviationCount != null;
}

export async function getDiffingLists() {
  const { serverListInfo } = useListSync.getState();
  const { abbreviationLists } = useLists.getState();

  const diffingLists: DiffingLists[] = [];

  abbreviationLists.forEach((localList) => {
    if (
      !!diffingLists.find(
        (existingDiff) =>
          !!existingDiff.lists.find(
            (existing) => existing._id === localList._id
          )
      )
    ) {
      return;
    }
    const diff: DiffingLists = {
      lists: [],
      selected: 0,
    };

    diff.lists.push(
      ...serverListInfo.filter(
        (serverList) =>
          serverList.name === localList.name || serverList._id === localList._id
      )
    );
    diff.lists.push(
      ...abbreviationLists.filter(
        (otherLocal) =>
          otherLocal._id !== localList._id && otherLocal.name === localList.name
      )
    );

    if (
      diff.lists.length === 1 &&
      diff.lists[0]._id === localList._id &&
      diff.lists[0].updatedAt === localList.updatedAt
    ) {
      return;
    }

    if (diff.lists.length) {
      diff.lists.push(localList);
      diff.lists.forEach((list, idx) => {
        if (list.updatedAt > diff.lists[diff.selected].updatedAt) {
          diff.selected = idx;
        }
      });
      diffingLists.push(diff);
    }
  });

  const listsNotOnServer = abbreviationLists.filter(
    (list) =>
      !diffingLists.find(
        (diff) => !!diff.lists.find((existing) => existing._id === list._id)
      ) && !serverListInfo.find((serverList) => serverList._id === list._id)
  );
  const listsNotOnLocal = serverListInfo.filter(
    (list) =>
      !diffingLists.find(
        (diff) => !!diff.lists.find((existing) => existing._id === list._id)
      ) && !abbreviationLists.find((localList) => localList._id === list._id)
  );

  return { listsNotOnLocal, listsNotOnServer, diffingLists };
}

export const StyledToggleButton = styled(ToggleButton)({
  textTransform: "none",
  whiteSpace: "normal",
  display: "inline",
  "&.Mui-selected": {
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "green",
    },
  },
});

export const StyledDeleteToggleButton = styled(ToggleButton)({
  "&.Mui-selected": {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "red",
    },
  },
});

export function ChooseDiffingLists() {
  const { t } = useTranslation();
  const diffingLists = useListSync((state) => state.diffingLists);

  if (!diffingLists.length) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Accordion>
        <AccordionSummary>
          {t("Lists with the same name/id. Choose one to keep.")}{" "}
          {`(${diffingLists.length})`}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sx={{ textAlign: "center" }}>
            {diffingLists.map((diff) => (
              <DiffingLists diff={diff} key={diff.lists[0]._id} />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

function DiffingLists({ diff }: { diff: DiffingLists }) {
  const { t } = useTranslation();

  return (
    <Fragment key={diff.lists[0]._id}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          {diff.lists[0].name}
        </Grid>
        <ToggleButtonGroup
          value={diff.selected.toString()}
          exclusive
          onChange={(e, value) => {
            diff.selected = parseInt(value ?? diff.selected);
            useListSync.setState(({ diffingLists }) => ({
              diffingLists: [...diffingLists],
            }));
          }}
        >
          {diff.lists.map((list, idx) => {
            return (
              <StyledToggleButton
                key={idx}
                value={idx.toString()}
                disableRipple
              >
                {list.name !== diff.lists[0].name && (
                  <Typography variant="body2">
                    {t("Changed name to")}: {list.name}
                  </Typography>
                )}
                <Typography variant="body1">
                  {isServerList(list)
                    ? list.abbreviationCount
                    : list.abb2expanded.size}{" "}
                  {t("abbreviations")}
                </Typography>
                <Typography variant="body2">
                  {t("Last updated")}: {datetime(list.updatedAt)}
                </Typography>
              </StyledToggleButton>
            );
          })}
          <Tooltip title={t("Discard all versions")}>
            <StyledDeleteToggleButton value="-1" disableRipple>
              <Delete />
            </StyledDeleteToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </Grid>
    </Fragment>
  );
}
