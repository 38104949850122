import { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { JSONtoList } from "hooks/persisting-storage";
import { DataGrid, EditInPlace } from "ui";
import { addLists } from "contextproviders/Abbreviations/lists";
import { AbbreviationList } from "contextproviders/Abbreviations";

export default function ImportButton(): ReactElement {
  const { t } = useTranslation();
  const [lists, setLists] = useState<AbbreviationList[]>([]);
  const [selected, setSelected] = useState<AbbreviationList[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelected(lists);
  }, [lists]);

  return (
    <div>
      <input
        hidden
        accept=".json"
        id="inputimportfileid"
        type="file"
        onChange={async (e) => {
          const files = e.target.files;
          if (files) {
            setLoading(true);
            const text = await files[0].text();
            const obj = JSON.parse(text);
            const lists = JSONtoList(obj);
            setLists(lists);
            setLoading(false);
          }
        }}
      />
      <Button
        variant="text"
        color="inherit"
        onClick={() => {
          const elem = document.getElementById(
            "inputimportfileid"
          ) as HTMLInputElement | null;
          if (elem) {
            // Reset value, otherwise importing the same file two times in a row will not trigger change event
            elem.value = "";
            elem.click();
          }
        }}
      >
        {t("Import")}
      </Button>
      <Dialog open={loading || !!lists.length} onClose={() => setLists([])}>
        <DialogTitle>{t("Import lists")}</DialogTitle>
        <DialogContent>
          {loading ? (
            t("Loading list content")
          ) : (
            <DataGrid
              rows={lists}
              columns={[
                {
                  name: t("Name"),
                  field: "name",
                  Component({ row }) {
                    return (
                      <EditInPlace
                        text={row.name}
                        onEdit={(newName) => {
                          const newSelected = [...selected];
                          const list = newSelected.find(
                            (list) => list._id === row._id
                          );
                          if (list) {
                            list.name = newName;
                            setSelected(newSelected);
                          }
                        }}
                      />
                    );
                  },
                },
                {
                  name: t("Abbreviations"),
                  get(row) {
                    return row.abb2expanded.size;
                  },
                },
              ]}
              selected={selected}
              setSelected={setSelected}
              idField="_id"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLists([])}>{t("Cancel")}</Button>
          <Button
            onClick={() => {
              addLists(selected);
              setLists([]);
            }}
          >
            {t("Import")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
