import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { useState, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { create } from "zustand";

type ConfirmDialogStore = {
  title?: string | JSX.Element;
  content?: string | JSX.Element;
  resolve: (value: boolean) => void;
  open: boolean;
};

type ConfirmDialogProps = {
  title?: string | JSX.Element;
  content?: string | JSX.Element;
};

const useConfirmDialog = create<ConfirmDialogStore>((set, get) => ({
  title: undefined,
  content: undefined,
  resolve: () => {},
  open: false as boolean,
}));

export function confirm({ content, title }: ConfirmDialogProps) {
  return new Promise<boolean>((resolve) => {
    useConfirmDialog.setState({
      content,
      title,
      resolve(value) {
        resolve(value);
        useConfirmDialog.setState({ open: false });
      },
      open: true,
    });
  });
}

function ConfirmDialog() {
  const open = useConfirmDialog((state) => state.open);
  const title = useConfirmDialog((state) => state.title);
  const content = useConfirmDialog((state) => state.content);
  const resolve = useConfirmDialog((state) => state.resolve);
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      {title && (
        <DialogTitle>
          {typeof title === "string" ? t(title) : title}
        </DialogTitle>
      )}
      <DialogContent>
        {typeof content === "string" ? t(content) : content}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resolve(true);
          }}
        >
          {t("Yes")}
        </Button>
        <Button
          onClick={() => {
            resolve(false);
          }}
        >
          {t("No")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type InputDialogStore = {
  title?: string | JSX.Element;
  content?: string | JSX.Element;
  type?: string;
  label?: string;
  okLabel?: string;
  open: boolean;
  onInput: (input: string) => void;
};

interface InputDialogProps {
  title?: string | JSX.Element;
  content?: string | JSX.Element;
  type?: string;
  label?: string;
  okLabel?: string;
}

const useInputDialog = create<InputDialogStore>((set) => ({
  title: undefined,
  content: undefined,
  type: undefined,
  label: undefined,
  okLabel: undefined,
  open: false as boolean,
  onInput() {},
}));

export function input({
  content,
  title,
  type,
  label,
  okLabel,
}: InputDialogProps) {
  return new Promise<string>((resolve) => {
    useInputDialog.setState({
      content,
      title,
      type,
      label,
      okLabel,
      onInput(input) {
        resolve(input);
        useInputDialog.setState({ open: false });
      },
      open: true,
    });
  });
}

function InputDialog() {
  const title = useInputDialog((state) => state.title);
  const content = useInputDialog((state) => state.content);
  const type = useInputDialog((state) => state.type);
  const label = useInputDialog((state) => state.label);
  const okLabel = useInputDialog((state) => state.okLabel);
  const open = useInputDialog((state) => state.open);
  const onInput = useInputDialog((state) => state.onInput);

  const { t } = useTranslation();

  const [dialogInput, setDialogInput] = useState("");

  return (
    <Dialog open={open}>
      {title && (
        <DialogTitle>
          {typeof title === "string" ? t(title) : title}
        </DialogTitle>
      )}
      <DialogContent>
        {typeof content === "string" ? t(content) : content}
        <TextField
          fullWidth
          autoFocus
          label={label}
          value={dialogInput}
          onChange={(e) => setDialogInput(e.target.value)}
          type={type}
          sx={{ marginTop: 1 }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onInput(dialogInput);
              setDialogInput("");
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onInput(dialogInput);
            setDialogInput("");
          }}
        >
          {t(okLabel || "")}
        </Button>
        <Button
          onClick={() => {
            onInput("");
            setDialogInput("");
          }}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type FileDialogStore = {
  title?: string | JSX.Element;
  content?: string | JSX.Element;
  open: boolean;
  resolve: (file: FileList | null) => void;
};

interface FileDialogProps {
  title?: string | JSX.Element;
  content?: string | JSX.Element;
}

const useFileDialog = create<FileDialogStore>((set) => ({
  title: undefined,
  content: undefined,
  open: false as boolean,
  resolve(file: FileList | null) {},
}));

export function selectFile({ content, title }: FileDialogProps) {
  return new Promise<FileList | null>((resolve) => {
    useFileDialog.setState({
      content,
      title,
      resolve(file) {
        resolve(file);
        useFileDialog.setState({ open: false });
      },
      open: true,
    });
  });
}

function FileDialog() {
  const title = useFileDialog((state) => state.title);
  const content = useFileDialog((state) => state.content);
  const open = useFileDialog((state) => state.open);
  const resolve = useFileDialog((state) => state.resolve);
  const [file, setFile] = useState<FileList | null>(null);

  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      {title && (
        <DialogTitle>
          {typeof title === "string" ? t(title) : title}
        </DialogTitle>
      )}
      <DialogContent>
        {typeof content === "string" ? t(content) : content}
        <input type="file" onChange={(e) => setFile(e.target.files)} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resolve(file);
          }}
        >
          {t("Select")}
        </Button>
        <Button
          onClick={() => {
            resolve(null);
          }}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type InfoDialogStore = {
  title?: string | JSX.Element;
  content?: string | JSX.Element;
  open: boolean;
  resolve: () => void;
};

interface InfoDialogProps {
  title?: string | JSX.Element;
  content?: string | JSX.Element;
}

const useInfoDialog = create<InfoDialogStore>((set) => ({
  title: undefined,
  content: undefined,
  open: false as boolean,
  resolve() {},
}));

export function info({ content, title }: InfoDialogProps) {
  return new Promise<void>((resolve) => {
    useInfoDialog.setState({
      content,
      title,
      resolve() {
        resolve();
        useInfoDialog.setState({ open: false });
      },
      open: true,
    });
  });
}

function InfoDialog() {
  const title = useInfoDialog((state) => state.title);
  const content = useInfoDialog((state) => state.content);
  const open = useInfoDialog((state) => state.open);
  const resolve = useInfoDialog((state) => state.resolve);

  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      {title && (
        <DialogTitle>
          {typeof title === "string" ? t(title) : title}
        </DialogTitle>
      )}
      <DialogContent>
        {typeof content === "string" ? t(content) : content}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resolve();
          }}
        >
          {t("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function Dialogs() {
  return (
    <>
      <ConfirmDialog />
      <FileDialog />
      <InputDialog />
      <InfoDialog />
    </>
  );
}

type DialogPromise<T, U> = (
  resolve: (value: T) => void,
  reject: (value?: U) => void
) => ReactNode;

type DialogData = { node: ReactNode; reject: (value: any) => void; id: number };

type DialogContainerStore = {
  dialogs: DialogData[];
  dialog: <T, U>(dialogPromise: DialogPromise<T, U>) => Promise<T>;
};

function promiseWithResolvers<T>() {
  let resolve: (value: T) => void, reject: (value?: any) => void;
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  // @ts-ignore
  return { promise, resolve, reject };
}

let id_counter = 0;

const useDialogs = create<DialogContainerStore>((set, get) => ({
  dialogs: [] as DialogData[],
  async dialog<T, U>(dialogPromise: DialogPromise<T, U>) {
    const { promise, resolve, reject } = promiseWithResolvers<T>();
    const id = id_counter++;
    const node = dialogPromise(resolve, reject);
    set(({ dialogs }) => ({
      dialogs: [...dialogs, { node, reject, id }],
    }));

    const cleanup = async () => {
      try {
        await promise;
      } catch (err) {}
      set(({ dialogs }) => ({
        dialogs: dialogs.filter((data) => data.id !== id),
      }));
    };

    cleanup();

    return promise;
  },
}));

export function DialogContainer() {
  const dialogs = useDialogs((state) => state.dialogs);

  return (
    <>
      {dialogs.map((data) => (
        <Dialog key={data.id} open onClose={() => data.reject(undefined)}>
          {data.node}
        </Dialog>
      ))}
    </>
  );
}

export const dialog = useDialogs.getState().dialog;

export function yesNo(text: string) {
  return dialog((resolve, reject) => (
    <>
      <DialogTitle>{text}</DialogTitle>
      <DialogActions>
        <Button onClick={() => resolve(true)}>Ja</Button>
        <Button onClick={() => reject()}>Nej</Button>
      </DialogActions>
    </>
  ));
}
