import { ReactElement } from "react";
import { Snackbar, Button, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useRegisterSW } from "virtual:pwa-register/react";

/**
 * register serviceworker with `onFetchingUpdate` and `onFetchedUpdate` config
 *
 * toast when fetching new version.
 *
 * snackbar when new version is fetched.
 */
export function ServiceWorkerWrapper(): ReactElement {
  const { t } = useTranslation();

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered() {
      console.log("SW Registered");
    },
    onRegisterError(error) {
      console.log("Error registering SW:", error);
    },
  });

  return (
    <Snackbar
      open={needRefresh}
      message={t("New version is available!")}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      action={
        <>
          <Button
            variant="contained"
            size="small"
            onClick={() => updateServiceWorker(true)}
          >
            {t("Use latest")}
          </Button>
          <IconButton
            sx={{ marginLeft: 1 }}
            size="small"
            onClick={() => setNeedRefresh(false)}
          >
            <Close />
          </IconButton>
        </>
      }
    />
  );
}
