import { Button, ButtonProps, IconButton, Menu } from "@mui/material";
import React, {
  useState,
  Children,
  cloneElement,
  PropsWithChildren,
  useMemo,
} from "react";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

interface Props extends ButtonProps {
  label: JSX.Element | string;
  disableCloseOnPress?: boolean;
}

export function DropdownButton({
  children,
  label,
  disableCloseOnPress,
  ...props
}: PropsWithChildren<Props>) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const newChildren = useMemo(() => {
    if (disableCloseOnPress) {
      return children;
    }
    const childArray = Children.toArray(children);

    return childArray.map((e: any, idx) =>
      cloneElement(e, {
        onClick: () => {
          setAnchorEl(null);
          e.props.onClick();
        },
        key: idx,
      })
    );
  }, [children, disableCloseOnPress]);

  return (
    <>
      <Button
        {...props}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {label}
        {!!anchorEl ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(e: any) => {
          setAnchorEl(null);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {newChildren}
      </Menu>
    </>
  );
}

export function DropdownIconButton({
  children,
  label,
  ...props
}: PropsWithChildren<Props>) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const childArray = Children.toArray(children);

  const newChildren = childArray.map((e: any, idx) =>
    cloneElement(e, {
      onClick: () => {
        setAnchorEl(null);
        e.props.onClick();
      },
      key: idx,
    })
  );

  return (
    <>
      <IconButton
        {...props}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {label}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e: any) => {
          setAnchorEl(null);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {newChildren}
      </Menu>
    </>
  );
}
