import { Extension } from "@tiptap/core";
import { serverClockSync } from "login/ServerClockSync";
import { Plugin, PluginKey } from "prosemirror-state";
import { useRemoteInterpretation } from "settings/RemoteInterpretation";
import { ySyncPluginKey } from "y-prosemirror";
import { Selection } from "prosemirror-state";
import { useEditor } from "./EditorStore";
import { ReplaceStep } from "prosemirror-transform";

let cursorPosOnBlur = -1;

export const EventHandler = Extension.create({
  name: "eventHandler",
  onBlur() {
    const { room } = useRemoteInterpretation.getState();
    if (
      room ||
      this.editor.state.selection.from > this.editor.state.doc.content.size - 2
    ) {
      cursorPosOnBlur = -1;
    } else {
      cursorPosOnBlur = this.editor.state.selection.from;
    }
  },
  addCommands() {
    return {
      refocus:
        () =>
        ({ commands, chain }) => {
          if (cursorPosOnBlur === -1) {
            return commands.moveCursorToEnd();
          } else {
            return chain()
              .setTextSelection(cursorPosOnBlur)
              .focus()
              .scrollIntoView()
              .run();
          }
        },
    };
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("eventHandler"),
        appendTransaction(transactions, oldState, newState) {
          if (!transactions.length) {
            return null;
          }

          const transaction = transactions[transactions.length - 1];
          const isRemoteChange =
            transaction.getMeta(ySyncPluginKey)?.isChangeOrigin;
          const alreadyTriggered = transactions.reduce<boolean>(
            (prev, cur) =>
              !!prev || !!cur.getMeta("appendTransactionTimestamp"),
            false
          );

          if (
            isRemoteChange &&
            transaction.docChanged &&
            transaction.selection.empty
          ) {
            // Selection was at the end before transaction, so keep end of document in view
            const { editor } = useEditor.getState();
            if (
              (editor.isFocused || !document.hasFocus()) &&
              oldState.selection.from > oldState.doc.content.size - 2
            ) {
              return newState.tr
                .setSelection(Selection.atEnd(newState.doc))
                .scrollIntoView();
            } else if (document.hasFocus() && !this.editor?.isFocused) {
              editor.view.dom.parentElement?.scrollTo({
                top: 9999999999999,
              });
            }
          }

          if (
            !transaction.docChanged ||
            !transaction.selection.empty ||
            alreadyTriggered ||
            isRemoteChange
          ) {
            return;
          }

          const { pos, deleted } = transaction.mapping
            .invert()
            .mapResult(transaction.selection.from);
          if (deleted) {
            return;
          }

          const resolvedPosBefore = transaction.before.resolve(pos);
          if (resolvedPosBefore.pos > transaction.selection.$from.pos) {
            return;
          }

          const lineBefore = resolvedPosBefore.node(1);
          const currentLine = transaction.selection.$from.node(1);
          const nodePos = transaction.selection.$from.before(1);

          let attrs = null;
          if (
            lineBefore?.textContent?.length === 0 ||
            currentLine?.textContent?.length === 0
          ) {
            attrs = { start: transaction.time + serverClockSync.time };
          }
          if (transaction.doc.lastChild === currentLine) {
            // If we added a new line, always update start and end
            if (transaction.doc.childCount > transaction.before.childCount) {
              attrs = {
                ...attrs,
                start: transaction.time + serverClockSync.time,
                end: transaction.time + serverClockSync.time,
              };
            } else if (transaction.steps.length === 1) {
              // If we only added a space, avoid updating end
              const step = transaction.steps.at(0);
              if (
                !(step instanceof ReplaceStep) ||
                step.from !== step.to ||
                step.slice.size !== 1 ||
                step.slice.content.size !== 1 ||
                (step.slice.content.firstChild?.text !== " " &&
                  step.slice.content.firstChild?.text !== "\xA0")
              ) {
                attrs = {
                  ...attrs,
                  end: transaction.time + serverClockSync.time,
                };
              }
            } else {
              attrs = {
                ...attrs,
                end: transaction.time + serverClockSync.time,
              };
            }
          }
          if (attrs) {
            attrs = { ...currentLine.attrs, ...attrs };
            return newState.tr
              .setNodeMarkup(nodePos, undefined, attrs, undefined)
              .setStoredMarks(transaction.storedMarks)
              .setMeta("appendTransactionTimestamp", true)
              .setMeta(
                "appendTransactionHighlight",
                transactions.reduce<boolean>(
                  (prev, cur) =>
                    !!prev || !!cur.getMeta("appendTransactionHighlight"),
                  false
                )
              )
              .setMeta(
                "avoidAbbreviation",
                transactions.reduce<boolean>(
                  (prev, cur) => !!prev || !!cur.getMeta("avoidAbbreviation"),
                  false
                )
              );
          }
        },
      }),
    ];
  },
});
