import { useEditor } from "tiptap/EditorStore";
import { removeWordJoiner } from "utility";

export function editorSelectedWord() {
  const { editor } = useEditor.getState();
  const currentLine = editor.state.selection.$from.node(1);
  const start = editor.state.selection.$from.parentOffset;
  const end = editor.state.selection.$to.parentOffset;
  const text = currentLine?.textContent || "";

  if (start !== end) {
    return removeWordJoiner(text.slice(start, end)).trim();
  }

  return wordAndLineAtIndex(text, start, end)[0];
}

export function editorSelectedWordAndLine() {
  const { editor } = useEditor.getState();
  const currentLine = editor.state.selection.$from.node(1);
  const start = editor.state.selection.$from.parentOffset;
  const end = editor.state.selection.$to.parentOffset;
  const text = currentLine?.textContent || "";

  return wordAndLineAtIndex(text, start, end);
}

export function getKeyByValue(
  obj: Map<string, [number, string]>,
  value: string
) {
  const valueLowerCase = value.toLowerCase();
  for (const [key, [_updatedAt, expanded]] of obj.entries()) {
    if (valueLowerCase === expanded.toLowerCase()) {
      return key;
    }
  }
  return "";
}

export const wordSeparators = /[.,!?:;"\-()/\u00A0 ]/;

function clamp(value: number, lowerBound: number, upperBound: number): number {
  return Math.max(Math.min(value, upperBound), lowerBound);
}

/**
 * returns [word, line]
 * If start !== end both will be the selected text
 * else word being the whole word at index i, defined by separator /[.,!?:;"\-()/\u00A0 ]/
 * and line is the whole line ending with the selected word
 */
function wordAndLineAtIndex(
  text: string,
  start: number,
  end: number
): [string, string] {
  if (start !== end) {
    text = text.slice(start, end);
    end -= start;
    start = 0;
  }
  end = clamp(end, 0, text.length - 1);
  while (end > 0 && text[end].match(wordSeparators)) {
    --end;
  }
  while (end < text.length && !text[end].match(wordSeparators)) {
    ++end;
  }
  start = Math.max(0, end - 1);
  while (start > 0 && !text[start].match(wordSeparators)) {
    --start;
  }
  while (start < end && text[start].match(wordSeparators)) {
    ++start;
  }
  const line = removeWordJoiner(text.slice(0, end));
  const word = removeWordJoiner(text.slice(start, end));
  return [word, line];
}
