import { useEffect, useRef } from "react";

export function useOnMount(callback: () => void) {
  const ref = useRef(false);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      callback();
    }
  }, []);
}
