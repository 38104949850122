import { useAbbreviations } from "contextproviders/Abbreviations";
import { activateHotkey } from "contextproviders/Abbreviations/lists";
import { parseHotkey, HotKey } from "is-hotkey";
import { toast } from "react-toastify";
import { useSettingsMenuStore } from "settings/SettingsMenuStore";

const Bindings: Record<string, Array<() => void>> = {};

function makeHotkeyString(hotkey: HotKey) {
  return `${hotkey.key?.toLocaleLowerCase()}${+hotkey.altKey ? 1 : 0}${
    hotkey.ctrlKey ? 1 : 0
  }${+hotkey.metaKey ? 1 : 0}${+hotkey.shiftKey ? 1 : 0}`;
}

export function addHotkey(key: string, action: () => void) {
  const hotkey = parseHotkey(key, { byKey: true });
  const hotkeyString = makeHotkeyString(hotkey);
  if (!Bindings[hotkeyString]) {
    Bindings[hotkeyString] = [];
  }
  Bindings[hotkeyString].push(action);
}

export function removeHotkey(key: string) {
  try {
    const hotkey = parseHotkey(key, { byKey: true });
    const hotkeyString = makeHotkeyString(hotkey);
    const binding = Bindings[hotkeyString];
    if (binding) {
      binding.pop();
      if (!binding.length) {
        delete Bindings[hotkeyString];
      }
    }
  } catch (err) {}
}

export function triggerHotkey(key: string) {
  const hotkey = parseHotkey(key, { byKey: true });
  const hotkeyString = makeHotkeyString(hotkey);
  Bindings[hotkeyString]?.at(-1)?.();
}

window.addEventListener("keydown", (e) => {
  const hotkeyString = makeHotkeyString(e);
  const binding = Bindings[hotkeyString]?.at(-1);
  if (binding) {
    binding();
    e.preventDefault();
    e.stopPropagation();
  }
});

addHotkey("mod+w", () => {});
addHotkey("mod+h", () => {});
addHotkey("mod+t", () => {});
addHotkey("mod+shift+p", () => {});
addHotkey("F5", () => {});
addHotkey("F12", () => {
  useSettingsMenuStore.getState().toggleSettings();
});
addHotkey("mod+p", () => {});
addHotkey("mod+r", () => {});

Array.from(Array(10).keys()).forEach((index) => {
  const num = index === 9 ? 0 : index + 1;
  addHotkey(`mod+${num}`, () => {
    activateHotkey(index);
    toast.info(
      `Ctrl+${num} ${useAbbreviations.getState().listHotkeys[index].name}`
    );
  });
});
