import { getStats, postStatsChunk, postStatsChunks } from "./utils";
import { onTypedWord } from "./stats";
import { useLogin } from "ui";
import { useRemoteInterpretation } from "settings/RemoteInterpretation";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { get, set, del } from "idb-keyval";

export function emptyStatsChunk(sessionName = "none"): AbbrevationStats {
  return {
    abb: {},
    expanded: {},
    sessionName,
    createdAt: new Date().toUTCString(),
  };
}

export interface AbbrevationStats {
  /**
   * Every used abbreviation => what it expanded to and number of times it was typed
   */
  abb: Record<string, { expanded: string; count: number }>;
  /**
   * Every manually used expanded => what could have been written instead and number of times it was typed
   */
  expanded: Record<string, { abb: string; count: number }>;
  sessionName: string;
  createdAt: string;
}

///////////////////////////////////////////////////////////////////////////////

const UPDATE_INTERVAL = 5 * 60 * 1000;
//const UPDATE_INTERVAL = 30 * 1000;

const isEmpty = (obj: Record<string, any>) => Object.keys(obj).length === 0;

type AbbreviationStatisticsStore = {
  stats: AbbrevationStats[];
  localStats: AbbrevationStats[];
};

export const useAbbreviationStats = create<AbbreviationStatisticsStore>()(
  persist(
    (set) => ({
      stats: [] as AbbrevationStats[],
      localStats: [] as AbbrevationStats[],
    }),
    {
      name: "statistics",
      storage: {
        async getItem(name) {
          return (await get(name)) || null;
        },
        async setItem(name, value) {
          await set(name, value);
        },
        async removeItem(name) {
          await del(name);
        },
      },
    }
  )
);

type AbbreviationStatisticsLocalChunkStore = {
  localStatsChunk: AbbrevationStats;
};

export const useAbbreviationsLocalChunk =
  create<AbbreviationStatisticsLocalChunkStore>()(
    persist(
      (set) => ({
        localStatsChunk: emptyStatsChunk(),
      }),
      {
        name: "statistics-local",
        storage: {
          async getItem(name) {
            return (await get(name)) || null;
          },
          async setItem(name, value) {
            await set(name, value);
          },
          async removeItem(name) {
            await del(name);
          },
        },
      }
    )
  );

if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    if (newModule) {
      newModule.useAbbreviationsLocalChunk.setState(
        useAbbreviationsLocalChunk.getState()
      );
      newModule.useAbbreviationStats.setState(useAbbreviationStats.getState());
    }
  });
}

export function saveStatisticsOnTypedWord(
  typedSentence: string,
  typedWord: string
) {
  const newLocalStatsChunk = onTypedWord(typedSentence, typedWord);

  useAbbreviationsLocalChunk.setState({ localStatsChunk: newLocalStatsChunk });
}

function uploadStatsToServer() {
  const { localStats } = useAbbreviationStats.getState();
  const { localStatsChunk } = useAbbreviationsLocalChunk.getState();
  const { token } = useLogin.getState();
  //try to upload localStats if any. keep any failed for later.
  if (localStats.length > 0) {
    postStatsChunks(localStats, token).then((unsentLocalStats) => {
      useAbbreviationStats.setState({ localStats: unsentLocalStats });
    });
  }

  //try to upload latest localStatsChunk. store in localStats if failed.
  if (!isEmpty(localStatsChunk.abb) || !isEmpty(localStatsChunk.expanded)) {
    postStatsChunk(localStatsChunk, token)
      .then((res) => {
        if (!res.ok) {
          useAbbreviationStats.setState(({ localStats }) => ({
            localStats: [...localStats, localStatsChunk],
          }));
        }
      })
      .catch(() => {
        useAbbreviationStats.setState(({ localStats }) => ({
          localStats: [...localStats, localStatsChunk],
        }));
      });
  }

  //always update stats and clear localStatsChunk
  useAbbreviationStats.setState(({ stats }) => ({
    stats: [...stats, localStatsChunk],
  }));
  useAbbreviationsLocalChunk.setState({
    localStatsChunk: emptyStatsChunk(
      useRemoteInterpretation.getState().room?.name
    ),
  });
}

setTimeout(() => {
  uploadStatsToServer();
}, 2000);
setInterval(uploadStatsToServer, UPDATE_INTERVAL);

async function getAllStats() {
  const token = useLogin.getState().token;
  if (token) {
    const stats = await getStats(token);
    useAbbreviationStats.setState({ stats });
  } else {
    setTimeout(getAllStats, 5000);
  }
}

setTimeout(getAllStats, 2000);
