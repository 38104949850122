import { Extension } from "@tiptap/core";
import regexpInputRule from "./RegexpInputRule";

export const Capitalizer = Extension.create({
  name: "capitalizer",

  addInputRules() {
    return [
      regexpInputRule({
        find: /^([a-zåäö])$/,
        replace: (match) => {
          return match[0].toUpperCase();
        },
      }),
      regexpInputRule({
        find: /[.!?] [a-zåäö]$/,
        replace: (match) => {
          return match[0].toUpperCase();
        },
      }),
      regexpInputRule({
        find: /^- [a-zåäö]$/,
        replace: (match) => {
          return match[0].toUpperCase();
        },
      }),
      regexpInputRule({
        find: /^-[a-zåäö]$/,
        replace: (match) => {
          return match[0].toUpperCase();
        },
      }),
      regexpInputRule({
        find: /(^[A-ZÅÄÖ]\S*?: )([a-zåäö])$/,
        replace: (match) => {
          return match[1] + match[2].toUpperCase();
        },
      }),
      regexpInputRule({
        find: /(^[A-ZÅÄÖ]\S*? [A-ZÅÄÖ]\S*?: )([a-zåäö])$/,
        replace: (match) => {
          return match[1] + match[2].toUpperCase();
        },
      }),
    ];
  },
});
