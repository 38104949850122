import { t } from "i18next";
import { toast } from "react-toastify";

const disconnected: Map<string, NodeJS.Timeout> = new Map();

const delayBeforeShowingDisconnectToast = 2000;

export function alertDisconnect(name: string) {
  const existing = disconnected.get(name);
  if (existing) {
    clearTimeout(existing);
  }

  disconnected.set(
    name,
    setTimeout(() => {
      disconnected.delete(name);
      toast.info(t<string>("{n} has disconnected", { n: name }), {
        pauseOnFocusLoss: false,
      });
    }, delayBeforeShowingDisconnectToast)
  );
}

export function alertConnect(name: string) {
  const existing = disconnected.get(name);
  if (existing) {
    clearTimeout(existing);
  } else {
    toast.info(t<string>("{n} has connected", { n: name }), {
      pauseOnFocusLoss: false,
    });
  }
}
