import { Document, RPCMethod } from "rest-backend";
import { AsJSON } from "types";
import useSWR, { preload } from "swr";
import { toast } from "react-toastify";

type MockMongooseDocument<T> = {
  save: () => Promise<T>;
};

type MongooseDocumentToPOJO<T> = Omit<T, keyof Document<unknown, any, {}>> & {
  _id: string;
};

type ObjectId = {
  _bsontype: "ObjectID";
};

export type InputFor<T> = T extends RPCMethod<infer C, infer I, infer O>
  ? unknown extends I
    ? undefined
    : AsJSON<I>
  : never;

type ConvertMongooseDocument<O> = O extends (infer R)[]
  ? ConvertMongooseDocument<R>[]
  : O extends MockMongooseDocument<O>
  ? MongooseDocumentToPOJO<O>
  : O extends string | number | ObjectId
  ? O
  : O extends Map<string, infer U>
  ? Record<string, U>
  : {
      [Key in keyof O]: ConvertMongooseDocument<O[Key]>;
    };

export type OutputFor<T> = T extends RPCMethod<infer C, infer I, infer O>
  ? O extends undefined
    ? undefined
    : AsJSON<ConvertMongooseDocument<O>>
  : never;

export class QueryPromise<T> extends Promise<T> {
  errorHandler: (err: any) => void;

  constructor(
    executor: (
      resolve: (value: T) => void,
      reject: (value?: any) => void
    ) => void,
    errorHandler: (err: any) => void
  ) {
    super(executor);
    this.errorHandler = errorHandler;
  }

  public onError(handler?: (value: unknown) => any) {
    if (handler) {
      return this.catch((err) => {
        handler(err);
        throw err;
      });
    } else {
      return this.catch((err) => {
        this.errorHandler(err);
        throw err;
      });
    }
  }
}

type BackendError = {
  statusCode: number;
  message: string;
  validation: { [key: string]: string };
  errorType: "BackendError";
};

function isBackendError(err: any): err is BackendError {
  return err.errorType === "BackendError";
}

export class QueryClient<T extends { methods: Record<string, any> }> {
  private getToken?: () => string;
  private url: string;
  private errorHandler: (err: any) => void;

  constructor(
    url: string,
    errorHandler: (err: any) => void,
    getToken?: () => string
  ) {
    this.url = url;
    this.errorHandler = errorHandler;
    this.getToken = getToken;
    this.swrFetcher = this.swrFetcher.bind(this);
    this.useQuery = this.useQuery.bind(this);
  }

  public updateGetToken(getToken?: () => string) {
    this.getToken = getToken;
  }

  public query<U extends keyof T["methods"]>(
    ...args: undefined extends InputFor<T["methods"][U]>
      ? [methodName: U]
      : [methodName: U, args: InputFor<T["methods"][U]>]
  ): QueryPromise<OutputFor<T["methods"][U]>> {
    const headers: HeadersInit = {
      "Content-Type": "application/json",
    };
    if (this.getToken) {
      headers.Authorization = `Bearer ${this.getToken()}`;
    }

    return new QueryPromise<OutputFor<T["methods"][U]>>(
      async (resolve, reject) => {
        try {
          const response = await fetch(this.url, {
            method: "POST",
            headers,
            body: JSON.stringify({ method: args[0], args: args[1] }),
          });

          if (!response.ok) {
            let error = "";
            try {
              const data = await response.json();
              if (isBackendError(data)) {
                reject(data);
                return;
              }
              error = data.error;
            } catch (err) {
              reject(new Error(`${args[0] as string}: ${response.statusText}`));
            }
            reject(
              new Error(`${args[0] as string}: ${error || response.statusText}`)
            );
          }

          try {
            const json = await response.json();
            resolve(json);
          } catch (err) {
            resolve(undefined as any);
          }
        } catch (err) {
          reject(err);
        }
      },
      this.errorHandler
    );
  }

  public useQuery<U extends keyof T["methods"]>(
    ...args: undefined extends InputFor<T["methods"][U]>
      ? [methodName: U]
      : [methodName: U, args: InputFor<T["methods"][U]>]
  ) {
    return useSWR<OutputFor<T["methods"][U]>>(args, this.swrFetcher);
  }

  public preload<U extends keyof T["methods"]>(
    ...args: undefined extends InputFor<T["methods"][U]>
      ? [methodName: U]
      : [methodName: U, args: InputFor<T["methods"][U]>]
  ) {
    preload(args, this.swrFetcher);
  }

  private async swrFetcher([...params]: any): Promise<any> {
    const res = await this.query(...params);
    return res;
  }
}

type GetRouterType<T> = T extends QueryClient<infer R> ? R : never;

export type FromQuery<
  T extends QueryClient<{ methods: Record<string, any> }>,
  U extends keyof GetRouterType<T>["methods"]
> = T extends QueryClient<infer R> ? OutputFor<R["methods"][U]> : never;

export type ToQuery<
  T extends QueryClient<{ methods: Record<string, any> }>,
  U extends keyof GetRouterType<T>["methods"]
> = T extends QueryClient<infer R> ? InputFor<R["methods"][U]> : never;
