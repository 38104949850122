import { jump, usePrewrittenText } from "PreWritten";
import { useEffect, useRef } from "react";
import { useSettingsStore } from "settings/Appearance";
import { useEditor } from "tiptap/EditorStore";

export function PrewrittenTextPreview() {
  const active = usePrewrittenText((state) => state.active);
  const preview = usePrewrittenText((state) => state.preview);
  const text = usePrewrittenText((state) => state.text);
  const currentLine = usePrewrittenText((state) => state.currentLine);
  const currentWord = usePrewrittenText((state) => state.currentWord);
  const divRef = useRef<HTMLDivElement>(null);
  const currentWordRef = useRef<HTMLSpanElement>(null);
  const prewrittenTextSize = useSettingsStore(
    (state) => state.prewrittenTextSize
  );
  const prewrittenTextPreviewWidth = useSettingsStore(
    (state) => state.prewrittenTextPreviewWidth
  );
  const editor = useEditor((state) => state.editor);

  useEffect(() => {
    if (divRef.current && currentWordRef.current) {
      currentWordRef.current.scrollIntoView({ block: "center" });
    }
  });

  if (!active || !preview) {
    return null;
  }

  const markEndOfLine = !(text[currentLine] && text[currentLine][currentWord]);

  return (
    <div
      ref={divRef}
      style={{
        overflowY: "auto",
        backgroundColor: "black",
        color: "white",
        whiteSpace: "pre-wrap",
        borderLeft: "3px solid grey",
        fontSize: prewrittenTextSize,
        boxSizing: "border-box",
        width: `${prewrittenTextPreviewWidth}px`,
      }}
    >
      <div
        style={{
          padding: "4px",
        }}
      >
        {text.map((line, lineIdx) => (
          <div key={lineIdx}>
            {line.map((word, wordIdx) => {
              const selectedWord =
                currentLine === lineIdx && currentWord === wordIdx;
              const style = selectedWord
                ? { backgroundColor: "yellow", color: "black" }
                : undefined;
              const fixedWord = wordIdx === line.length - 1 ? word : word + " ";
              return (
                <span
                  ref={selectedWord ? currentWordRef : null}
                  key={wordIdx}
                  onClick={() => {
                    jump(lineIdx, wordIdx);
                    editor.commands.focus();
                  }}
                  style={style}
                >
                  {fixedWord}
                </span>
              );
            })}
            <span
              style={{
                backgroundColor:
                  currentLine === lineIdx && markEndOfLine
                    ? "yellow"
                    : undefined,
              }}
              onClick={(e) => {
                jump(lineIdx, line.length);
                editor.commands.focus();
              }}
            >
              {" "}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
