import { Dispatch, SetStateAction } from "react";
import { StoreApi, UseBoundStore, create } from "zustand";

export function MakeSetStateActionFromZustandStore<T, U extends keyof T>(
  store: UseBoundStore<StoreApi<T>>,
  member: U
) {
  return (value: T[U] | ((prev: T[U]) => T[U])) => {
    if (typeof value === "function") {
      const func = value as (prev: T[U]) => T[U];
      store.setState((prev) => ({ [member]: func(prev[member]) } as any));
    } else {
      store.setState({ [member]: value } as any);
    }
  };
}

export function MakeZustandState<T>(val: T) {
  const useStore = create<{ value: T; setValue: Dispatch<SetStateAction<T>> }>(
    (set) => ({
      value: val,
      setValue(valueOrFunction) {
        if (typeof valueOrFunction === "function") {
          const func = valueOrFunction as (prev: T) => T;
          set(({ value }) => ({ value: func(value) }));
        } else {
          set({ value: valueOrFunction });
        }
      },
    })
  );
  return () => {
    const value = useStore((state) => state.value);
    const setValue = useStore((state) => state.setValue);

    return [value, setValue] as const;
  };
}
