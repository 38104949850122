import { create } from "zustand";

type SettingsMenuStore = {
  showSettingsMenu: boolean;
  page: string;
  toggleSettings: () => void;
};

export const useSettingsMenuStore = create<SettingsMenuStore>((set) => ({
  showSettingsMenu: false,
  page: "Manage abbreviations",
  toggleSettings: () => {
    set(({ showSettingsMenu }) => ({ showSettingsMenu: !showSettingsMenu }));
  },
}));
