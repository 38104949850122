import { useState } from "react";
import {
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ImportButton from "./buttons/ImportButton";
import NewListDialogButton from "./buttons/NewListDialogButton";
import CloudIcon from "@mui/icons-material/Cloud";
import ComputerIcon from "@mui/icons-material/Computer";

import ManageListAbbreviations from "./ManageListAbbreviations";
import ManageLocalLists from "./ManageLocalLists";
import ManageServerLists from "./ManageServerLists";
import { Paper, Section, Title, Toolbar } from "ui";
import ExportButton, { clickHref } from "./buttons/ExportButton";
import { useAbbreviations, useLists } from "contextproviders/Abbreviations";
import { CopyAll, ExpandMore } from "@mui/icons-material";
import { useLogin } from "ui";
import { listToJSON } from "hooks/persisting-storage";

export default function ManageLists() {
  const { t } = useTranslation();
  const accordion = useAbbreviations((state) => state.accordion);
  const selected = useAbbreviations((state) => state.selected);
  const first_name = useLogin((state) => state.user?.first_name);
  const last_name = useLogin((state) => state.user?.last_name);
  const abbreviationLists = useLists((state) => state.abbreviationLists);
  const setSelected = useAbbreviations((state) => state.setSelected);
  const [viewLocalLists, setViewLocalLists] = useState(true);
  const [searchstr, setSearchstr] = useState("");

  return (
    <>
      <Paper xs>
        <Accordion
          expanded={accordion}
          onChange={(_e, expanded) =>
            useAbbreviations.setState({ accordion: expanded })
          }
          disableGutters
          sx={{ margin: 0, padding: 0 }}
        >
          <Toolbar>
            <Section>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Title>{t("Manage lists")}</Title>
              </AccordionSummary>
              <Tooltip title={t("Local lists")}>
                <Button
                  color="inherit"
                  onClick={() => setViewLocalLists(true)}
                  variant={viewLocalLists ? "outlined" : "text"}
                >
                  <ComputerIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t("Server lists")}>
                <Button
                  color="inherit"
                  onClick={() => setViewLocalLists(false)}
                  variant={!viewLocalLists ? "outlined" : "text"}
                >
                  <CloudIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t("Backup all local lists")}>
                <Button
                  color="inherit"
                  onClick={() => {
                    const downloadName = `${first_name}_${last_name}_${new Date().toLocaleDateString()}_list-backup.json`;
                    const blob = new Blob(
                      [
                        JSON.stringify(
                          abbreviationLists.map((list) => listToJSON(list))
                        ),
                      ],
                      {
                        type: "application/json",
                      }
                    );
                    clickHref(downloadName, blob);
                  }}
                >
                  <CopyAll />
                </Button>
              </Tooltip>
            </Section>
            <Section>
              <TextField
                label={t("Search")}
                variant="standard"
                size="small"
                value={searchstr}
                onChange={(e) => setSearchstr(e.target.value)}
                autoComplete="off"
              />
            </Section>
            <Section>
              {!!selected.length && <ExportButton lists={selected} />}
              <NewListDialogButton />
              <ImportButton />
            </Section>
          </Toolbar>
          <AccordionDetails>
            {viewLocalLists ? (
              <ManageLocalLists
                selected={selected}
                setSelected={setSelected}
                searchstr={searchstr}
              />
            ) : (
              <ManageServerLists searchstr={searchstr} />
            )}
          </AccordionDetails>
        </Accordion>
      </Paper>
      {!!selected.length && viewLocalLists && (
        <ManageListAbbreviations lists={selected} />
      )}
    </>
  );
}
