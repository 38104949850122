import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LanguageNames, LanguageString } from "types";
import { Paper, Toolbar } from "ui";
import { useSettingsStore } from "./Appearance";

export function Language() {
  const { t } = useTranslation();
  const language = useSettingsStore((state) => state.language);
  const setLanguage = useSettingsStore((state) => state.setLanguage);

  return (
    <Paper xs>
      <Toolbar>
        <Typography variant="h6">{t("Language")}</Typography>
      </Toolbar>
      <Grid container spacing={1} padding={1}>
        {Object.keys(LanguageNames).map<JSX.Element>((lang) => (
          <Grid item key={lang}>
            <Button
              variant={lang === language ? "contained" : "outlined"}
              onClick={() => setLanguage(lang)}
            >
              {LanguageNames[lang as LanguageString]}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}
