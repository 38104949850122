import { Button, Grid, Tooltip } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { triggerHotkey } from "keybindings/KeyBindings";
import { usePrewrittenText } from "PreWritten";
import { useTranslation } from "react-i18next";
import { useSettingsStore } from "settings/Appearance";
import { useRemoteInterpretation } from "settings/RemoteInterpretation";
import { useEditor } from "tiptap/EditorStore";
import { info } from "ui";

interface HotkeyButtonProps {
  tooltip: string;
  onClick?: () => void;
  hide?: boolean;
  text: string;
}

function HotkeyButton({ tooltip, onClick, hide, text }: HotkeyButtonProps) {
  const { t } = useTranslation();
  const editor = useEditor((state) => state.editor);
  if (hide) {
    return null;
  }
  return (
    <Grid item xs="auto" height={40}>
      <Tooltip title={t(tooltip)} placement="left">
        <Button
          onClick={
            onClick ||
            (() => {
              editor.commands.focus();
              triggerHotkey(text);
            })
          }
          variant="contained"
          sx={{ color: "white" }}
          size="small"
        >
          {t(text)}
        </Button>
      </Tooltip>
    </Grid>
  );
}

export function HotkeyBar() {
  const showHotkeyBar = useSettingsStore((state) => state.showHotkeyBar);
  const active = usePrewrittenText((state) => state.active);
  const room = useRemoteInterpretation((state) => state.room);
  const editor = useEditor((state) => state.editor);

  if (!showHotkeyBar) {
    return null;
  }
  return (
    <Grid
      xs="auto"
      sx={{ background: blueGrey["500"] }}
      direction="column"
      padding={1}
      container
      item
    >
      <HotkeyButton
        onClick={async () => {
          await info({
            title: "Hotkey bar hidden",
            content:
              "You can show the hotkey bar again by pressing F12 and going to Appearance.",
          });
          useSettingsStore.setState({ showHotkeyBar: false });
        }}
        tooltip="Hide this sidebar"
        text="Hide"
      />
      <HotkeyButton
        tooltip="Move cursor to the end of the text"
        text="Esc"
        onClick={() => {
          editor.commands.focus("end", {
            scrollIntoView: true,
          });
        }}
      />
      <HotkeyButton tooltip="Lookup abbrevation for selected word" text="F1" />
      <HotkeyButton tooltip="Pre-written text" text="F2" />
      <HotkeyButton
        hide={!active}
        tooltip="Show/Hide prewritten text preview"
        text="F3"
      />
      <HotkeyButton
        tooltip="Clear all text"
        text="F4"
        onClick={() => {
          editor.chain().clearAllText().focus().run();
        }}
      />
      <HotkeyButton tooltip="Decrease text size" text="F6" />
      <HotkeyButton tooltip="Increase text size" text="F7" />
      <HotkeyButton tooltip="Change color scheme" text="F8" />
      <HotkeyButton tooltip="Quick add abbreviation in bottom list" text="F9" />
      <HotkeyButton
        tooltip="Quick add abbreviation in other lists"
        text="F10"
      />
      <HotkeyButton tooltip="Open settings" text="F12" />
      <HotkeyButton
        hide={!room}
        tooltip="Send a chat message to another interpreter or listener"
        text="ctrl+r"
      />
      <HotkeyButton
        hide={!room}
        tooltip="Set an API key for use with zoom/teams etc."
        text="ctrl+p"
      />
    </Grid>
  );
}
