import { CopyAll, Delete } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useLogin } from "ui";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useEditor } from "tiptap/EditorStore";
import { Field, Form, input, SubmitButton } from "ui";
import { datetime } from "utility";
import { Session, sessionQuery, useRemoteInterpretation } from ".";
import { TranscriptButton } from "./TranscriptButton";
import { useMemo } from "react";

export function ConnectedSessionInfo({ room }: { room: Session }) {
  const { t } = useTranslation();
  const local = useRemoteInterpretation((state) => state.local);
  const localIPs = useRemoteInterpretation((state) => state.localIPs);
  const selectedLocalIP = useRemoteInterpretation(
    (state) => state.selectedLocalIP
  );
  const leaveRoom = useRemoteInterpretation((state) => state.leaveRoom);
  const charsPerLine = useRemoteInterpretation((state) => state.charsPerLine);
  const linesPerBlock = useRemoteInterpretation((state) => state.linesPerBlock);
  const listeners = useEditor((state) => state.listeners);
  const interpreters = useEditor((state) => state.interpreters);

  const shortName = room.shortName;

  const dataUrl = useMemo(
    () =>
      `${import.meta.env.REACT_APP_CAPTIONS_URL}/${
        room.shortName ? room.shortName : room._id
      }`,
    [room]
  );

  return (
    <>
      <Grid container item md direction="column" spacing={1}>
        <Grid container item xs justifyContent="space-around">
          <Typography variant="h6">{room.name}</Typography>
          <Button onClick={leaveRoom}>{t("Disconnect")}</Button>
        </Grid>
        {local && (
          <Grid item xs>
            <FormControl>
              <InputLabel>{t("Local IP to use")}</InputLabel>
              <Select
                value={selectedLocalIP}
                onChange={(e) =>
                  useRemoteInterpretation.setState({
                    selectedLocalIP: e.target.value,
                  })
                }
                label={t("Local IP to use")}
              >
                {localIPs.map((ip) => (
                  <MenuItem value={ip}>{ip}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {!room.disabled.includes("Distans") && (
          <Grid item xs>
            <CopyableTextField
              text={
                local
                  ? `http://${selectedLocalIP}:3069`
                  : `${import.meta.env.REACT_APP_LISTENER_URL}/${
                      shortName ? shortName : room._id
                    }`
              }
              label={t("Remote Interpretation")}
            />
          </Grid>
        )}
        {!room.disabled.includes("Livetext") && (
          <Grid item xs>
            <CopyableTextField
              text={
                local
                  ? `http://${selectedLocalIP}:3066`
                  : `${import.meta.env.REACT_APP_LIVETEXT_URL}/${
                      shortName ? shortName : room._id
                    }`
              }
              label={t("Live text")}
            />
          </Grid>
        )}
        {!local && (
          <Grid item xs>
            <Accordion>
              <AccordionSummary>
                {t(
                  "Data links (used for polling captions, for programs like vMix)"
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Stack gap={1}>
                  <Stack direction="row" gap={1}>
                    <TextField
                      value={charsPerLine}
                      onChange={(e) =>
                        useRemoteInterpretation.setState({
                          charsPerLine: parseInt(e.target.value),
                        })
                      }
                      type="number"
                      label={t("Characters per line")}
                    />
                    <TextField
                      value={linesPerBlock}
                      onChange={(e) =>
                        useRemoteInterpretation.setState({
                          linesPerBlock: parseInt(e.target.value),
                        })
                      }
                      type="number"
                      label={t("Lines per block")}
                    />
                  </Stack>
                  <CopyableTextField
                    text={`${dataUrl}?charsPerLine=${
                      charsPerLine || 40
                    }&linesPerBlock=${linesPerBlock || 2}`}
                    label={t("JSON - array of strings")}
                  />
                  <CopyableTextField
                    text={`${dataUrl}?format=vmixjson&charsPerLine=${
                      charsPerLine || 40
                    }&linesPerBlock=${linesPerBlock || 2}`}
                    label={t("JSON - array of objects (adapted for vMix)")}
                  />
                  <CopyableTextField
                    text={`${dataUrl}?format=vmixxml&charsPerLine=${
                      charsPerLine || 40
                    }&linesPerBlock=${linesPerBlock || 2}`}
                    label={t("XML (adapted for vMix)")}
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        <Grid container item xs alignItems="center" spacing={1}>
          <Grid item xs="auto">
            <Typography>{t("Short name")}:</Typography>
          </Grid>
          {!!shortName && (
            <Grid item xs>
              <Chip
                label={shortName}
                onDelete={async () => {
                  const newRoom = await sessionQuery.query("updateSession", {
                    id: room._id,
                    session: {
                      shortName: "",
                    },
                  });
                  if (newRoom) {
                    useRemoteInterpretation.setState({ room: newRoom });
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid container item xs justifyContent="space-around">
          {!room.disabled.includes("Transcription") && (
            <Grid item xs="auto">
              <TranscriptButton />
            </Grid>
          )}
          {!local && (
            <Grid item xs="auto">
              <Button
                onClick={async () => {
                  const name = (
                    await input({
                      title: "Set short name",
                      label: "Short name",
                    })
                  ).toLowerCase();

                  if (name.match(/[^\w-]/)) {
                    toast.error(
                      t("Invalid short name. Only a-z, 0-9, _ and - is allowed")
                    );
                    return;
                  }

                  if (name) {
                    try {
                      const newRoom = await sessionQuery.query(
                        "updateSession",
                        {
                          id: room._id,
                          session: {
                            shortName: name,
                          },
                        }
                      );
                      if (newRoom) {
                        useRemoteInterpretation.setState({ room: newRoom });
                      }
                    } catch (err) {
                      if (err instanceof Error) {
                        if (
                          err.message.startsWith(
                            "updateSession: Short name already in use"
                          )
                        ) {
                          toast.error(
                            t("Short name already in use by another session")
                          );
                        }
                      }
                    }
                  }
                }}
              >
                {t("Set short name")}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container item xs spacing={1} padding={1}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {t<string>("Time since last change {duration}", {
              duration: new Date(useEditor.getState().timeSinceLastChange)
                .toISOString()
                .substring(11, 19),
            })}
          </Grid>
          <Grid item xs>
            {t("{n} interpreters", { count: interpreters.length })}:{" "}
            {interpreters.join(", ")}
          </Grid>
          <Grid item xs>
            {t("{n} listeners", { count: listeners.length })}:{" "}
            {listeners.join(", ")}
          </Grid>
        </Grid>
        <SessionInfoBlocks room={room} />
      </Grid>
    </>
  );
}

function SessionInfoBlocks({ room }: { room: Session }) {
  const user = useLogin((state) => state.user);

  return (
    <Grid container item xs spacing={1} padding={1}>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      {room.info?.map((info, at) => (
        <Card sx={{ margin: 1 }}>
          <CardHeader
            title={info.name}
            subheader={datetime(info.createdAt)}
            action={
              info.user === user?._id && (
                <IconButton
                  onClick={async () => {
                    const newRoom = await sessionQuery.query(
                      "deleteSessionInfo",
                      {
                        id: room._id,
                        at,
                      }
                    );
                    useRemoteInterpretation.setState({ room: newRoom });
                  }}
                >
                  <Delete />
                </IconButton>
              )
            }
          />
          <CardContent>
            <Stack gap={1}>
              {info.text.map((text) => (
                <Typography variant="body2" whiteSpace="pre-wrap">
                  {text}
                </Typography>
              ))}
            </Stack>
          </CardContent>
        </Card>
      ))}
      <Grid item xs={12}>
        <Form
          initialValues={{ text: "" }}
          onSubmit={async ({ text }, helpers) => {
            const newRoom = await sessionQuery.query("addSessionInfo", {
              id: room._id,
              text: text
                .split("\n\n")
                .filter((t) => t)
                .map((t) => t.trim()),
            });
            useRemoteInterpretation.setState({ room: newRoom });
            helpers.resetForm();
          }}
        >
          <Field multiline rows={4} name="text" fullWidth />
          <SubmitButton label="Lägg till info" />
        </Form>
      </Grid>
    </Grid>
  );
}

function CopyableTextField({ text, label }: { text: string; label: string }) {
  const { t } = useTranslation();

  return (
    <TextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(text);
                toast.success(`${t(label)} ${t("copied")}`);
              }}
            >
              <CopyAll />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={text}
      fullWidth
      label={label}
    />
  );
}
