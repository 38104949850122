import React, { useState, useContext, useCallback, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { modifyKeys } from "contextproviders/Abbreviations/lists";
import { AbbreviationList } from "contextproviders/Abbreviations";

interface Props {
  lists: AbbreviationList[];
  children: React.ReactNode;
}

export default function AddAbbreviationButton({ lists, children }: Props) {
  const { t } = useTranslation();
  const [abb, setAbb] = useState("");
  const [expanded, setExpanded] = useState("");
  const [open, setOpen] = useState(false);
  const [listId, setListId] = useState(lists[0]?._id ?? "");

  const handleClose = useCallback(() => {
    setAbb("");
    setExpanded("");
    setOpen(false);
  }, []);

  const handleCreate = useCallback(() => {
    modifyKeys({ add: [{ listId, abb, word: expanded }] });
    handleClose();
  }, [handleClose, listId, abb, expanded]);

  useEffect(() => {
    setListId(lists[0]?._id ?? "");
  }, [lists]);

  return (
    <div>
      <Button variant="text" onClick={() => setOpen(true)} color="inherit">
        {children}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("Create new abbreviation")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1} padding={1}>
            {lists.length > 1 && (
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <Typography>{t("List")}</Typography>
                <Select
                  value={listId}
                  onChange={(e) => setListId(e.target.value)}
                >
                  {lists.map((list) => (
                    <MenuItem key={list._id} value={list._id}>
                      {list.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                onKeyPress={(e) => e.key === "Enter" && handleCreate()}
                autoFocus
                margin="dense"
                id="abbreviationKey"
                label={t("Abbreviation")}
                type="text"
                variant="standard"
                value={abb}
                onChange={(e) => setAbb(e.target.value)}
              />
              <TextField
                onKeyPress={(e) => e.key === "Enter" && handleCreate()}
                margin="dense"
                id="abbreviationValue"
                label={t("Word")}
                type="text"
                variant="standard"
                value={expanded}
                onChange={(e) => setExpanded(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <Button onClick={handleCreate}>{t("Create")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
