import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ExpandMore } from "@mui/icons-material";
import { useLogin } from "ui";
import { confirm, DropdownButton, input, Paper, Section, Toolbar } from "ui";
import { useOnMount } from "hooks/onMount";
import { create } from "zustand";
import { useEditor } from "tiptap/EditorStore";
import { SessionRouter } from "pwa-server";
import { ConnectedSessionInfo } from "./ConnectedSessionInfo";
import { FromQuery, QueryClient } from "query-client";

type RemoteInterpretationStore = {
  room: Session | null;
  interpreters: string[];
  listeners: string[];
  sessions: Session[];
  local: string;
  localIPs: string[];
  selectedLocalIP: string;
  categories: string[];
  charsPerLine: number;
  linesPerBlock: number;
  leaveRoom: () => void;
  joinRoom: (
    room: Session,
    local?: string,
    localIPs?: string[],
    selectedLocalIP?: string
  ) => void;
};

export const useRemoteInterpretation = create<RemoteInterpretationStore>(
  (set) => ({
    room: null as Session | null,
    interpreters: [] as string[],
    listeners: [] as string[],
    sessions: [] as Session[],
    local: "",
    localIPs: [] as string[],
    selectedLocalIP: "",
    categories: [] as string[],
    charsPerLine: 40,
    linesPerBlock: 2,
    leaveRoom() {
      set({ room: null, local: "" });
      useEditor.getState().disconnect();
    },
    joinRoom(room, local = "", localIPs = [], selectedLocalIP = "") {
      set({ room, local, localIPs, selectedLocalIP });
      useEditor.getState().connect(room._id);
    },
  })
);

if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    if (newModule) {
      newModule.useRemoteInterpretation.setState(
        useRemoteInterpretation.getState()
      );
    }
  });
}

export const sessionQuery = new QueryClient<SessionRouter>(
  `${import.meta.env.REACT_APP_SESSIONS_URL}/rpc`,
  () => {},
  () => useLogin.getState().token
);

export type Session = FromQuery<typeof sessionQuery, "getMySessions">[number];

function makeCategories(sessions: Session[]) {
  return [
    ...new Set(
      sessions
        .map((session) => session.category)
        .filter((category): category is string => !!category)
    ),
  ];
}

export async function getSessions() {
  const { token } = useLogin.getState();
  const { local } = useRemoteInterpretation.getState();
  if (token && !local) {
    try {
      const sessions = await sessionQuery.query("getMySessions");

      const categories = makeCategories(sessions);
      useRemoteInterpretation.setState({
        sessions,
        categories,
      });
    } catch (err: any) {
      toast.error(err.toString());
    }
  }
}

async function removeSession(id: string) {
  try {
    await sessionQuery.query("deleteSession", { id });
    getSessions();
  } catch (err: any) {
    toast.error(err.toString());
  }
}

export function RemoteInterpretation() {
  const { t } = useTranslation();
  const local = useRemoteInterpretation((state) => state.local);
  const joinRoom = useRemoteInterpretation((state) => state.joinRoom);

  useOnMount(() => {
    getSessions();
  });

  const connectToLan = useCallback(
    async (url: string) => {
      try {
        const response = await fetch(`http://${url}:3068/status`, {
          method: "GET",
        });

        if (response.status === 200) {
          const { ips } = await response.json();
          joinRoom(
            {
              _id: "local",
              name: t("Local server at {url}", { url }),
              disabled: ["SRT"],
              date: "",
              recurring: false,
              shortName: "",
              belongsTo: "",
              saveTranscript: false,
              info: [],
              category: "",
              organizations: [],
              interpreters: [],
              groups: [],
            },
            url,
            ips,
            ips?.[0] || ""
          );
          toast.success(t("Connected to LAN server"));
        } else {
          toast.error(t("Unexpected response from LAN server"));
        }
      } catch (err) {
        toast.error(t("Could not reach LAN server"));
      }
    },
    [joinRoom]
  );

  return (
    <>
      <Paper xs>
        <Toolbar>
          <Section>
            <Typography variant="h6">
              {local ? t("Over LAN") : t("Over internet")}
            </Typography>
          </Section>
          {!local && (
            <Section>
              <DropdownButton color="inherit" label={t("LAN Server")}>
                <MenuItem
                  onClick={() => {
                    window.location.href = "itls://localserver";
                    toast.info(
                      <>
                        <div>
                          {t(
                            "Om servern inte startar behöver du ladda hem och installera den på din datorn först."
                          )}
                        </div>
                        <div>
                          <Button
                            variant="text"
                            color="info"
                            href="https://backend.illumitype.se/versioncontrol/lanserver/latest/windows"
                            target="_blank"
                          >
                            {t("Download LAN server")}
                          </Button>
                        </div>
                      </>
                    );
                  }}
                >
                  {t("Start server")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    connectToLan("localhost");
                  }}
                >
                  {t("Connect to server on this computer")}
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    const url = await input({
                      title: t("Connect to LAN server"),
                      label: "IP",
                      content: (
                        <>
                          <Typography>
                            {t(
                              "Make sure to allow unsafe content in your browser settings and enter the IP of the computer running the server."
                            )}
                          </Typography>
                        </>
                      ),
                    });

                    if (!url) {
                      return;
                    }

                    connectToLan(url);
                  }}
                >
                  {t("Connect to server using IP")}
                </MenuItem>
              </DropdownButton>
            </Section>
          )}
        </Toolbar>
        <Grid container spacing={1} padding={2} direction="column">
          <SessionList />
        </Grid>
      </Paper>
    </>
  );
}

function SessionInfo({ session }: { session: Session }) {
  const { t } = useTranslation();
  const user = useLogin((state) => state.user);
  const joinRoom = useRemoteInterpretation((state) => state.joinRoom);

  return (
    <Grid
      key={session._id}
      container
      spacing={1}
      borderTop={1}
      borderColor="grey.500"
    >
      <Grid item xs>
        {session.name}
      </Grid>
      {(user?.roles.includes("sessions") ||
        user?.roles.includes("ourSessions")) && (
        <Grid item xs="auto">
          <Button
            onClick={async () => {
              const res = await confirm({
                title: t("Remove"),
                content: t("Do you want to remove {sessionName}?", {
                  sessionName: session.name,
                }),
              });

              if (res) {
                removeSession(session._id);
              }
            }}
          >
            {t("Remove")}
          </Button>
        </Grid>
      )}
      <Grid item xs="auto">
        <Button onClick={() => joinRoom(session)}>{t("Connect")}</Button>
      </Grid>
    </Grid>
  );
}

function SessionList() {
  const { t } = useTranslation();
  const room = useRemoteInterpretation((state) => state.room);
  const sessions = useRemoteInterpretation((state) => state.sessions);
  const token = useLogin((state) => state.token);
  const [search, setSearch] = useState("");

  const filteredSessions = useMemo(() => {
    return sessions.filter((sess) =>
      sess.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [sessions, search]);

  const others = useMemo(
    () =>
      filteredSessions
        .filter((sess) => !sess.category)
        .map((sess) => <SessionInfo key={sess._id} session={sess} />),
    [filteredSessions]
  );

  const today = useMemo(() => {
    const date = new Date(Date.now()).toLocaleDateString();
    return filteredSessions
      .filter((sess) => !sess.recurring && sess.date === date)
      .map((sess) => <SessionInfo key={sess._id} session={sess} />);
  }, [filteredSessions]);

  const categories = useMemo(() => {
    const newCategories: Record<string, boolean> = {};
    filteredSessions.forEach((sess) => {
      if (sess.category) {
        newCategories[sess.category] = true;
      }
    });
    return Object.keys(newCategories);
  }, [filteredSessions]);

  if (room !== null) {
    return <ConnectedSessionInfo room={room} />;
  }

  if (!token) {
    return (
      <Typography>
        {t(
          "You are in offline mode. To run in online mode, make sure you have internet access and restart the app."
        )}
      </Typography>
    );
  }

  return (
    <>
      <Grid container item xs justifyContent="space-around">
        <Button onClick={getSessions}>{t("Update")}</Button>
        <CreateSession />
      </Grid>
      {sessions.length > 0 && (
        <>
          <Grid container item xs justifyContent="center" marginBottom={2}>
            <Typography variant="h5">{t("Sessions")}</Typography>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              label={t("Search")}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
        </>
      )}
      {sessions.length === 0 && (
        <Grid container item md justifyContent="center">
          <Typography variant="h6">{t("There are no sessions")}</Typography>
        </Grid>
      )}
      {today.length > 0 && (
        <>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">{t("Today")}</Typography>
            </AccordionSummary>
            <AccordionDetails>{today}</AccordionDetails>
          </Accordion>
        </>
      )}
      {categories.map((category) => (
        <React.Fragment key={category}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">{category}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {filteredSessions
                .filter((sess) => sess.category === category)
                .map((sess) => (
                  <SessionInfo key={sess._id} session={sess} />
                ))}
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      ))}
      {others.length > 0 && (
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">{t("Others")}</Typography>
            </AccordionSummary>
            <AccordionDetails>{others}</AccordionDetails>
          </Accordion>
        </>
      )}
    </>
  );
}

function CreateSession() {
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [name, setName] = useState("");
  const [date, setDate] = useState(new Date(Date.now()).toLocaleDateString());
  const [category, setCategory] = useState("");
  const [recurring, setRecurring] = useState(false);
  const { t } = useTranslation();
  const categories = useRemoteInterpretation((state) => state.categories);
  const user = useLogin((state) => state.user);

  const create = async () => {
    if (name) {
      try {
        await sessionQuery.query("createSession", {
          name,
          interpreters: [],
          organizations: [user!.organization],
          groups: [],
          date,
          category,
          recurring,
          disabled: [],
        });

        getSessions();
        setShowCreateDialog(false);
      } catch (err: any) {
        toast.error(err.toString());
      }
    }
  };

  useEffect(() => {
    if (!showCreateDialog) {
      setName("");
      setCategory("");
      setDate(new Date(Date.now()).toLocaleDateString());
      setRecurring(false);
    }
  }, [showCreateDialog]);

  if (
    !user?.roles.includes("sessions") &&
    !user?.roles.includes("ourSessions")
  ) {
    return null;
  }

  return (
    <>
      <Button onClick={() => setShowCreateDialog(true)}>
        {t("Create new session")}
      </Button>
      <Dialog
        open={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
      >
        <DialogTitle>{t("Create new session")}</DialogTitle>
        <DialogContent>
          <Grid container padding={1} spacing={1} direction="column">
            <Grid item md>
              <TextField
                value={name}
                label={t("Name")}
                onChange={(e) => setName(e.target.value)}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    create();
                  }
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                type="date"
                label={t("Date")}
                focused
                onChange={(e) => setDate(e.target.value)}
                value={date}
              />
            </Grid>
            <Grid item xs>
              <Autocomplete
                disablePortal
                options={categories}
                freeSolo={true}
                sx={{ width: 300 }}
                inputValue={category}
                onInputChange={(event, value) => {
                  setCategory(value);
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={t("Category")}
                    // onKeyUp instead of down to allow inputValue
                    // to update before triggering when selecting a value
                    // from the list and pressing enter
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        create();
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs>
              <FormControlLabel
                label={t("Recurring")}
                control={
                  <Checkbox
                    value={recurring}
                    onChange={(e) => setRecurring(e.target.checked)}
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCreateDialog(false)}>
            {t("Cancel")}
          </Button>
          <Button onClick={create}>{t("Create")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
