import {
  sessionQuery,
  useRemoteInterpretation,
} from "settings/RemoteInterpretation";
import { localSessionQuery } from "settings/RemoteInterpretation/TranscriptButton";
import { useLogin } from "ui";

const syncTimeAdjusts: Array<number> = [];
let syncTimeAdjustIndex = 0;

export const serverClockSync = { time: 0 };

function updateServerClockSync() {
  const sortedArray = [...syncTimeAdjusts];
  sortedArray.sort();

  const middleIndex = Math.floor(syncTimeAdjusts.length / 2);
  const median = syncTimeAdjusts[middleIndex];

  const arrayWithoutOutliers = sortedArray.filter(
    (val) => val < median + 5000 && val > median - 5000
  );

  const average =
    arrayWithoutOutliers.reduce((prev, cur) => prev + cur, 0) /
    arrayWithoutOutliers.length;

  serverClockSync.time = Math.round(average);
}

export async function getTimestampFromServer() {
  try {
    const { local } = useRemoteInterpretation.getState();
    const { token, offlineToken } = useLogin.getState();
    if (!local && offlineToken) {
      return;
    } else if (!token) {
      return;
    }
    const beforeSend = Date.now();
    const { time } = local
      ? await localSessionQuery.query("getTime")
      : await sessionQuery.query("getTime");
    const afterSend = Date.now();
    const halfResponseTime = Math.round(afterSend - beforeSend);

    const expectedTime = beforeSend + halfResponseTime;

    if (!time) {
      throw new Error("Invalid timestamp response from server");
    }

    const newAdjust = time - expectedTime;

    if (syncTimeAdjusts.length < 10) {
      syncTimeAdjusts.push(newAdjust);
    } else {
      syncTimeAdjusts[syncTimeAdjustIndex++] = newAdjust;
      if (syncTimeAdjustIndex >= 10) {
        syncTimeAdjustIndex = 0;
      }
    }
    updateServerClockSync();
  } catch (error) {
    console.log("Error getting server timestamp", error);
  }
}
