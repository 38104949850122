import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Delete, ColorLens } from "@mui/icons-material";
import { SetStateAction, useState } from "react";
import {
  red,
  green,
  blue,
  yellow,
  purple,
  orange,
  teal,
  grey,
} from "@mui/material/colors";
import { confirm } from "ui";
import {
  DataGrid,
  GridColumns,
  Paper,
  Toolbar,
  Title,
  ButtonPreventDefault,
} from "ui";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import i18next from "i18next";

export const colors = {
  Black: "black",
  White: "white",
  Red: red["600"],
  Green: green["600"],
  Blue: blue["600"],
  Yellow: yellow["600"],
  Purple: purple["600"],
  Orange: orange["600"],
  Teal: teal["600"],
  Gray: grey["600"],
  "Chroma Green": "#00FF12",
};

type ColorString = keyof typeof colors;

interface ColorScheme {
  background: ColorString;
  text: ColorString;
  id: string;
}

export const defaultColorSchemes = [
  { background: "Black", text: "White" },
  {
    background: "Black",
    text: "Yellow",
  },
  {
    background: "Blue",
    text: "Yellow",
  },
  {
    background: "White",
    text: "Black",
  },
  {
    background: "Yellow",
    text: "Black",
  },
  {
    background: "Yellow",
    text: "Blue",
  },
].map((scheme) => ({
  ...scheme,
  id: `${scheme.background}${scheme.text}`,
})) as ColorScheme[];

type SettingsStore = {
  colorSchemes: ColorScheme[];
  theme: string;
  fontSize: number;
  padding: number;
  paddingTop: number;
  paddingBottom: number;
  paddingRight: number;
  paddingLeft: number;
  separatePadding: "all" | "separate";
  fontFace: string;
  currentColorScheme: ColorScheme;
  prewrittenTextSize: number;
  showActiveInterpreter: boolean;
  showSessionStatus: boolean;
  prewrittenTextPreviewWidth: number;
  showHotkeyBar: boolean;
  language: string;
  showActiveTextStyle: boolean;
  setLanguage: (language: string) => void;
  toggleColorScheme: () => void;
  increaseFontSize: () => void;
  decreaseFontSize: () => void;
};

export const useSettingsStore = create<SettingsStore>()(
  persist(
    (set, get) => ({
      colorSchemes: defaultColorSchemes,
      theme: "",
      fontSize: 64,
      padding: 5,
      paddingTop: 5,
      paddingBottom: 5,
      paddingRight: 5,
      paddingLeft: 5,
      separatePadding: "all",
      fontFace: "Arial",
      currentColorScheme: defaultColorSchemes[0],
      prewrittenTextSize: 24,
      showActiveInterpreter: false as boolean,
      showSessionStatus: true as boolean,
      prewrittenTextPreviewWidth: 400,
      showHotkeyBar: true as boolean,
      language: "en",
      showActiveTextStyle: true,
      setLanguage(language) {
        set({ language });
        i18next.changeLanguage(language);
      },
      toggleColorScheme() {
        const { colorSchemes, currentColorScheme } = get();
        const index = colorSchemes.findIndex(
          (val) => val.id === currentColorScheme.id
        );
        if (index === -1) {
          set({ currentColorScheme: defaultColorSchemes[0] });
        } else if (index + 1 < colorSchemes.length) {
          set({ currentColorScheme: colorSchemes[index + 1] });
        } else {
          set({ currentColorScheme: colorSchemes[0] });
        }
      },
      increaseFontSize() {
        set(({ fontSize }) => ({ fontSize: Math.min(fontSize + 1, 120) }));
      },
      decreaseFontSize() {
        set(({ fontSize }) => ({ fontSize: Math.max(fontSize - 1, 12) }));
      },
    }),
    {
      name: "settings",
      version: 2,
      onRehydrateStorage() {
        return (state, error) => {
          if (i18next.isInitialized) {
            console.log("onRehydrateSettings", state, error);
            i18next.changeLanguage(state?.language || "en");
          } else {
            i18next.on("initialized", () =>
              i18next.changeLanguage(state?.language || "en")
            );
          }
        };
      },
      migrate(persistedState, version) {
        if (version === 0) {
          const oldState: SettingsStore = persistedState as SettingsStore;
          return {
            ...oldState,
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 5,
            paddingLeft: 5,
            separatePadding: "all",
            showActiveTextStyle: true,
          } as SettingsStore;
        } else if (version === 1) {
          const oldState: SettingsStore = persistedState as SettingsStore;
          return { ...oldState, showActiveTextStyle: true };
        }
        return persistedState as SettingsStore;
      },
    }
  )
);

if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    if (newModule) {
      newModule.useSettingsStore.setState(useSettingsStore.getState());
    }
  });
}

export function Appearance() {
  const colorSchemes = useSettingsStore((state) => state.colorSchemes);
  const theme = useSettingsStore((state) => state.theme);
  const fontSize = useSettingsStore((state) => state.fontSize);
  const padding = useSettingsStore((state) => state.padding);
  const paddingTop = useSettingsStore((state) => state.paddingTop);
  const paddingBottom = useSettingsStore((state) => state.paddingBottom);
  const paddingRight = useSettingsStore((state) => state.paddingRight);
  const paddingLeft = useSettingsStore((state) => state.paddingLeft);
  const separatePadding = useSettingsStore((state) => state.separatePadding);
  const fontFace = useSettingsStore((state) => state.fontFace);
  const currentColorScheme = useSettingsStore(
    (state) => state.currentColorScheme
  );
  const prewrittenTextSize = useSettingsStore(
    (state) => state.prewrittenTextSize
  );
  const showActiveInterpreter = useSettingsStore(
    (state) => state.showActiveInterpreter
  );
  const showSessionStatus = useSettingsStore(
    (state) => state.showSessionStatus
  );
  const prewrittenTextPreviewWidth = useSettingsStore(
    (state) => state.prewrittenTextPreviewWidth
  );
  const showHotkeyBar = useSettingsStore((state) => state.showHotkeyBar);
  const showActiveTextStyle = useSettingsStore(
    (state) => state.showActiveTextStyle
  );

  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const { t } = useTranslation();

  const colorSchemesColumns: GridColumns<ColorScheme> = [
    {
      name: t("Background color"),
      field: "background",
      disableSort: true,
      width: 160,
    },
    {
      name: t("Text color"),
      field: "text",
      disableSort: true,
      width: 160,
    },
    {
      name: "delete",
      noHeader: true,
      disableSort: true,
      Component({ row }) {
        return (
          <ButtonPreventDefault
            onClick={async (e) => {
              const background = row.background;
              const text = row.id;
              const confirmed = await confirm({
                title: "Remove color schema",
                content: `$t(Do you want to delete) $t(${background}) / $t(${text})`,
              });
              if (confirmed) {
                const newSchemes = colorSchemes.filter(
                  (scheme) => scheme.id !== row.id
                );
                useSettingsStore.setState({ colorSchemes: newSchemes });
              }
            }}
          >
            <Delete />
          </ButtonPreventDefault>
        );
      },
    },
  ];

  return (
    <>
      <Paper item xs={12} md={12} lg={6}>
        <Toolbar>
          <Title>{t("Appearance")}</Title>
        </Toolbar>
        <Grid container spacing={1} padding={1}>
          <Grid item xs={6}>
            <TextField
              select
              label={t("Theme")}
              value={theme}
              onChange={(e) =>
                useSettingsStore.setState({ theme: e.target.value })
              }
              fullWidth
            >
              <MenuItem value="Illuminate">Illuminate</MenuItem>
              <MenuItem
                style={{ backgroundColor: "black", color: "white" }}
                value="Dark chapel"
              >
                Dark chapel
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label={t("Text size")}
              value={fontSize}
              onChange={(e) =>
                useSettingsStore.setState({
                  fontSize: parseInt(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>{t("Give margin for")}</Typography>
            <RadioGroup
              row
              value={separatePadding}
              onChange={(e) =>
                useSettingsStore.setState({
                  separatePadding: e.target.value as "all" | "separate",
                })
              }
            >
              <FormControlLabel
                value="all"
                control={<Radio />}
                label={t("All sides")}
              />
              <FormControlLabel
                value="separate"
                control={<Radio />}
                label={t("Each side")}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={6} container gap={1}>
            {separatePadding === "all" ? (
              <TextField
                fullWidth
                type="number"
                label={t("Margin")}
                value={padding}
                onChange={(e) =>
                  useSettingsStore.setState({
                    padding: parseInt(e.target.value),
                  })
                }
              />
            ) : (
              <>
                <TextField
                  fullWidth
                  type="number"
                  label={t("Margin top")}
                  value={paddingTop}
                  onChange={(e) =>
                    useSettingsStore.setState({
                      paddingTop: parseInt(e.target.value),
                    })
                  }
                />
                <TextField
                  fullWidth
                  type="number"
                  label={t("Margin bottom")}
                  value={paddingBottom}
                  onChange={(e) =>
                    useSettingsStore.setState({
                      paddingBottom: parseInt(e.target.value),
                    })
                  }
                />
                <TextField
                  fullWidth
                  type="number"
                  label={t("Margin left")}
                  value={paddingLeft}
                  onChange={(e) =>
                    useSettingsStore.setState({
                      paddingLeft: parseInt(e.target.value),
                    })
                  }
                />
                <TextField
                  fullWidth
                  type="number"
                  label={t("Margin right")}
                  value={paddingRight}
                  onChange={(e) =>
                    useSettingsStore.setState({
                      paddingRight: parseInt(e.target.value),
                    })
                  }
                />
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputProps={{ style: { fontFamily: fontFace.toLowerCase() } }}
              fullWidth
              select
              label={t("Font face")}
              value={fontFace}
              onChange={(e) =>
                useSettingsStore.setState({ fontFace: e.target.value })
              }
            >
              <MenuItem style={{ fontFamily: "arial" }} value="Arial">
                Arial
              </MenuItem>
              <MenuItem
                style={{ fontFamily: "times new roman" }}
                value="Times new Roman"
              >
                Times new Roman
              </MenuItem>
              <MenuItem style={{ fontFamily: "monospace" }} value="Monospace">
                Monospace ({t("Shows livetexting blocks")})
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label={t("Pre-written preview text size")}
              value={prewrittenTextSize}
              onChange={(e) =>
                useSettingsStore.setState({
                  prewrittenTextSize: parseInt(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label={t("Pre-written preview width")}
              value={prewrittenTextPreviewWidth}
              onChange={(e) =>
                useSettingsStore.setState({
                  prewrittenTextPreviewWidth: parseInt(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showActiveInterpreter}
                  onChange={(e) =>
                    useSettingsStore.setState({
                      showActiveInterpreter: e.target.checked,
                    })
                  }
                />
              }
              label={t("Show active interpreter")}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showSessionStatus}
                  onChange={(e) =>
                    useSettingsStore.setState({
                      showSessionStatus: e.target.checked,
                    })
                  }
                />
              }
              label={t("Show session status")}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showHotkeyBar}
                  onChange={(e) =>
                    useSettingsStore.setState({
                      showHotkeyBar: e.target.checked,
                    })
                  }
                />
              }
              label={t("Show hotkey bar")}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showActiveTextStyle}
                  onChange={(e) =>
                    useSettingsStore.setState({
                      showActiveTextStyle: e.target.checked,
                    })
                  }
                />
              }
              label={t("Show active text style")}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper item xs={12} md={12} lg={6}>
        <Toolbar>
          <Title>{t("Color Schemes")}</Title>
          <Button color="inherit" onClick={() => setShowCreateDialog(true)}>
            {t("New")}
            <ColorLens />
          </Button>
        </Toolbar>
        <DataGrid
          singleSelect
          rows={colorSchemes}
          columns={colorSchemesColumns}
          selected={currentColorScheme}
          setSelected={(value: SetStateAction<ColorScheme>) => {
            if (typeof value === "function") {
              useSettingsStore.setState(({ currentColorScheme }) => ({
                currentColorScheme: value(currentColorScheme),
              }));
            } else {
              useSettingsStore.setState({ currentColorScheme: value });
            }
          }}
          idField="id"
        />
      </Paper>
      <CreateDialog
        show={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
      />
    </>
  );
}

function CreateDialog({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const [background, setBackground] = useState<ColorString>("Black");
  const [text, setText] = useState<ColorString>("White");
  const colorSchemes = useSettingsStore((state) => state.colorSchemes);
  const { t } = useTranslation();

  return (
    <Dialog open={show} onClose={onClose}>
      <DialogTitle>{t("New color scheme")}</DialogTitle>
      <DialogContent>
        <Grid padding={1} container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>{t("Background color")}</InputLabel>
              <Select
                value={background}
                label={t("Background color")}
                onChange={(e) => setBackground(e.target.value as ColorString)}
              >
                {Object.keys(colors).map((color) => (
                  <MenuItem key={color} value={color}>
                    {t(color)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>{t("Text color")}</InputLabel>
              <Select
                value={text}
                label={t("Text color")}
                onChange={(e) => setText(e.target.value as ColorString)}
              >
                {Object.keys(colors).map((color) => (
                  <MenuItem key={color} value={color}>
                    {t(color)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            const newColorSchemes = [...colorSchemes];
            newColorSchemes.push({
              background,
              text,
              id: `${background}${text}`,
            });
            useSettingsStore.setState({ colorSchemes: newColorSchemes });
            onClose();
          }}
        >
          {t("Add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
