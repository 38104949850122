import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
} from "@mui/material";
import React from "react";

export function ButtonPreventDefault({ onClick, ...props }: ButtonProps) {
  return (
    <Button
      {...props}
      onClick={
        onClick
          ? (e) => {
              onClick(e);
              e.preventDefault();
              e.stopPropagation();
            }
          : undefined
      }
    ></Button>
  );
}

export function IconButtonPreventDefault({
  onClick,
  ...props
}: IconButtonProps) {
  return (
    <IconButton
      {...props}
      onClick={
        onClick
          ? (e) => {
              onClick(e);
              e.preventDefault();
              e.stopPropagation();
            }
          : undefined
      }
    ></IconButton>
  );
}
