import Paragraph from "@tiptap/extension-paragraph";

export default Paragraph.extend({
  addAttributes() {
    return {
      start: {
        default: 0,
      },
      end: {
        default: 0,
      },
    };
  },
});
