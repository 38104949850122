import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useLists } from "contextproviders/Abbreviations";
import { modifyKeys } from "contextproviders/Abbreviations/lists";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownButton, confirm } from "ui";

type Props = PropsWithChildren<{
  selected: {
    name: string;
    id: string;
    listId: string;
    abb: string;
    word: string;
    updatedAt: number;
  }[];
}>;

export function MoveAbbreviationsButton({ selected }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const abbreviationLists = useLists((state) => state.abbreviationLists);
  const [listId, setListId] = useState(abbreviationLists[0]?._id ?? "");

  return (
    <>
      <DropdownButton color="inherit" label={t("Handle selected")}>
        <MenuItem
          onClick={async () => {
            const confirmRemove = await confirm({
              title: t("Delete {n} abbreviations?", { count: selected.length }),
            });

            if (confirmRemove) {
              modifyKeys({
                remove: selected,
              });
            }
          }}
        >
          {t("Delete")}
        </MenuItem>
        <MenuItem onClick={() => setOpen(true)}>{t("Move or copy")}</MenuItem>
      </DropdownButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {t("Move or copy {n} abbreviations", { count: selected.length })}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Typography>{t("To")}</Typography>
              <Select
                value={listId}
                onChange={(e) => setListId(e.target.value)}
              >
                {abbreviationLists.map((list) => (
                  <MenuItem key={list._id} value={list._id}>
                    {list.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <Button
                onClick={() => {
                  modifyKeys({
                    add: selected.map((abb) => ({
                      ...abb,
                      listId,
                    })),
                    remove: selected,
                  });
                }}
              >
                {t("Move")}
              </Button>
              <Button
                onClick={() => {
                  modifyKeys({
                    remove: [],
                    add: selected.map((abb) => ({
                      ...abb,
                      listId,
                    })),
                  });
                }}
              >
                {t("Copy")}
              </Button>
              <Button onClick={() => setOpen(false)}>{t("Cancel")}</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
