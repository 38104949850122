import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Paper,
} from "@mui/material";
import { useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { colors, useSettingsStore } from "settings/Appearance";
import { getTimestampFromServer } from "./ServerClockSync";
import { Buffer } from "buffer";
import { useInstallPrompt } from "installprompt/InstallPrompt";
import { syncWithServer } from "serversync";
import { JWK, useLogin } from "ui";

useLogin.getState().initialize({
  key: JSON.parse(import.meta.env.REACT_APP_JWT_PUBLIC_KEY) as JWK,
  onLogin(user) {
    syncWithServer(user._id);
  },
  allowOfflineUsage: true,
});

setInterval(() => {
  getTimestampFromServer();
}, 10000);

function onEnter(callback: () => void) {
  return (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      callback();
    }
  };
}

export function LoginPage() {
  const [email, setEmail] = useState("");
  const [emailHelper, setEmailHelper] = useState("");
  const [password, setPassword] = useState("");
  const [passwordHelper, setPasswordHelper] = useState("");
  const [error, setError] = useState("");

  const { t } = useTranslation();
  const currentColorScheme = useSettingsStore(
    (state) => state.currentColorScheme
  );

  const getUser = useLogin((state) => state.getUser);
  const loading = useLogin((state) => state.loading);
  const rememberMe = useLogin((state) => state.rememberMe);
  const beforeInstall = useInstallPrompt((state) => state.beforeInstall);

  const login = async () => {
    if (!email) {
      setEmailHelper(t("You need to provide an email"));
    } else if (!password) {
      setPasswordHelper("You need to provide a password");
    } else {
      setError("");
      const authString = Buffer.from(`${email}:${password}`, "utf8").toString(
        "base64"
      );
      const reason = await getUser(rememberMe, `Basic ${authString}`);
      if (reason) {
        setError(reason);
      }
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          backgroundColor: colors[currentColorScheme.background],
          color: colors[currentColorScheme.text],
        }}
      >
        <Grid
          height="100vh"
          container
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        backgroundColor: colors[currentColorScheme.background],
        color: colors[currentColorScheme.text],
      }}
    >
      <Container maxWidth="md">
        <Grid container padding={2} justifyContent="center">
          <Paper>
            <Grid
              container
              direction="column"
              alignItems="center"
              padding={2}
              spacing={2}
            >
              <Grid item xs>
                <Typography variant="h5">
                  {t("Welcome to IllumiType PWA")}
                </Typography>
              </Grid>
              <Grid item xs>
                <TextField
                  label={t("Email")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={onEnter(login)}
                  helperText={emailHelper}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  type="password"
                  label={t("Password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={onEnter(login)}
                  helperText={passwordHelper}
                />
                <Grid item xs>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rememberMe}
                          onChange={(e) =>
                            useLogin.setState({ rememberMe: e.target.checked })
                          }
                        />
                      }
                      label={t("Remember me")}
                    />
                  </FormGroup>
                </Grid>
                {error && (
                  <Grid item xs sx={{ color: "red" }}>
                    {error}
                  </Grid>
                )}
                <Grid item xs>
                  <Button onClick={login}>{t("Log in")}</Button>
                </Grid>
              </Grid>
              {beforeInstall && (
                <Grid item xs={12}>
                  <Button onClick={() => beforeInstall.prompt()}>
                    {t("Install")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Container>
    </div>
  );
}
