import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Hidden,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import { Paper, Toolbar } from "ui";
import { ExpandMore } from "@mui/icons-material";
import {
  AbbreviationsStore,
  useAbbreviations,
} from "contextproviders/Abbreviations";
import {
  activateHotkey,
  movePosition,
  nameFromId,
  toggleActive,
} from "contextproviders/Abbreviations/lists";

export default function ListPriority() {
  const { t } = useTranslation();

  return (
    <Paper md={12} sm="auto">
      <Hidden mdDown>
        <Toolbar>
          <Typography variant="h6">{t("Hotkey lists")}</Typography>
        </Toolbar>
        <Box marginTop={1}>
          <DragableListOrder />
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Accordion disableGutters>
          <Toolbar>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">{t("Hotkey lists")}</Typography>
            </AccordionSummary>
          </Toolbar>
          <AccordionDetails>
            <DragableListOrder />
          </AccordionDetails>
        </Accordion>
      </Hidden>
    </Paper>
  );
}

function DragableListOrder() {
  const { t } = useTranslation();
  const listHotkeys = useAbbreviations((state) => state.listHotkeys);
  const activeHotkeyId = useAbbreviations((state) => state.activeHotkeyId);
  const activeListIds = useAbbreviations((state) => state.activeListIds);

  const [dragId, setDragId] = useState("");
  const [dragOverId, setDragOverId] = useState("");

  const handleDrag = (e: React.DragEvent<HTMLTableRowElement>, id: string) => {
    e.stopPropagation();
    e.preventDefault();
    setDragId(id);
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLTableRowElement>,
    id: string
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (id !== dragOverId) {
      setDragOverId(id);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (dragId !== dragOverId) {
      movePosition(dragId, dragOverId);
      setDragId("");
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl>
            <InputLabel id="select-hotkey-label">{t("Hotkey")}</InputLabel>
            <Select
              labelId="select-hotkey-label"
              id="select-hotkey-select"
              value={activeHotkeyId}
              label={t("Hotkey")}
              onChange={(e) => activateHotkey(e.target.value as number)}
            >
              {listHotkeys.map((listhotkey, i) => (
                <MenuItem key={listhotkey.ids.join()} value={i}>
                  {hotkeyLabelFromIndex(i)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <TextField
            label={t("Name")}
            value={listHotkeys[activeHotkeyId].name}
            autoComplete="off"
            onChange={(e) => {
              listHotkeys[activeHotkeyId].name = e.target.value;
              useAbbreviations.setState({ listHotkeys: [...listHotkeys] });
            }}
          />
        </Grid>
      </Grid>
      {activeListIds.length === 0 ? (
        <Box padding={2}>
          <Typography variant="body2">{t("No active lists")}</Typography>
        </Box>
      ) : (
        <TableContainer>
          <Table size="medium">
            <TableBody>
              {activeListIds.map((listId) => {
                const name = nameFromId(listId);
                return (
                  <TableRow
                    key={listId}
                    hover
                    draggable="true"
                    onDrag={(e) => handleDrag(e, listId)}
                    onDragOver={(e) => handleDragOver(e, listId)}
                    onDrop={(e) => handleDrop(e)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{name}</TableCell>
                    <TableCell padding="none" width={60}>
                      <Button onClick={() => toggleActive(listId)} size="small">
                        <ClearIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

function hotkeyLabelFromIndex(i: number): string {
  return `Ctrl+${i === 9 ? 0 : i + 1}`;
}
