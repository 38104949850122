import { CircularProgress, Grid, Typography } from "@mui/material";
import {
  AbbreviationList,
  listAreLoaded,
  useLists,
} from "contextproviders/Abbreviations";
import {
  addLists,
  removeList,
  updateListId,
} from "contextproviders/Abbreviations/lists";
import { JSONtoList, listToJSON } from "hooks/persisting-storage";
import i18next from "i18next";
import { SyncRouter } from "lists";
import { useLogin } from "ui";
import { toast } from "react-toastify";
import { confirm } from "ui";
import { create } from "zustand";
import { getDiffingLists, isServerList, useListSync } from "./lists";
import { getDiffingTexts, isServerText, useTextSync } from "./texts";
import { get, set } from "idb-keyval";
import {
  addText,
  removeText,
  textsAreLoaded,
  updateTextId,
  usePrewrittenText,
} from "PreWritten";
import { QueryClient, FromQuery } from "query-client";

const listsServer = import.meta.env.REACT_APP_LISTS_URL;

export const syncQuery = new QueryClient<SyncRouter>(
  `${listsServer}/rpc`,
  () => {},
  () => useLogin.getState().token
);

export type ServerList = FromQuery<typeof syncQuery, "getListInfo">[number];

export type RemovedAbbreviations = FromQuery<typeof syncQuery, "createList">;

export type DiffingLists = {
  lists: (AbbreviationList | ServerList)[];
  selected: number;
};

type SyncStore = {
  active: boolean;
  open: boolean;
  checkDiffs: () => void;
  startSync: () => any;
  updateServerInfo: () => Promise<void>;
};

export async function createList(
  list: AbbreviationList
): Promise<string[] | void> {
  const { serverListInfo } = useListSync.getState();
  const { active } = useSync.getState();
  if (
    active &&
    !serverListInfo.find((serverList) => serverList._id === list._id)
  ) {
    const { id, removed } = await syncQuery.query(
      "createList",
      listToJSON(list)
    );
    updateListId(list._id, id);
    return removed;
  }
}

export const useSync = create<SyncStore>((set, get) => ({
  open: false,
  active: false,
  async startSync() {
    set({ active: false, open: false });
    const {
      upload: listUpload,
      download: listDownload,
      diffingLists,
    } = useListSync.getState();
    const {
      upload: textUpload,
      download: textDownload,
      diffingTexts,
    } = useTextSync.getState();
    const { user } = useLogin.getState();
    const totalCount =
      listUpload.length +
      listDownload.length +
      diffingLists.reduce((prev, cur) => cur.lists.length + prev, 0) +
      textUpload.length +
      textDownload.length +
      diffingTexts.reduce((prev, cur) => cur.texts.length + prev, 0);
    if (totalCount) {
      const infotoast = toast(
        <div>
          <CircularProgress size={20} sx={{ marginRight: 2 }} />
          {
            i18next.t("Syncing {totalCount} lists and texts with server", {
              totalCount,
            }) as string
          }
        </div>,
        {
          progress: 0,
          hideProgressBar: false,
          autoClose: false,
        }
      );
      let count = 0;
      for (const { lists, selected } of diffingLists) {
        const selectedList = lists[selected];
        if (selectedList) {
          if (isServerList(selectedList)) {
            listDownload.push(selectedList);
          } else {
            listUpload.push(selectedList);
          }
        }
        for (const list of lists) {
          if (list !== selectedList) {
            if (isServerList(list)) {
              await syncQuery.query("removeList", { id: list._id });
            } else {
              removeList(list._id);
            }
            toast.update(infotoast, { progress: ++count / totalCount });
          }
        }
      }

      for (const { texts, selected } of diffingTexts) {
        const selectedText = texts[selected];
        if (selectedText) {
          if (isServerText(selectedText)) {
            textDownload.push(selectedText);
          } else {
            textUpload.push(selectedText);
          }
        }
        for (const text of texts) {
          if (text !== selectedText) {
            if (isServerText(text)) {
              await syncQuery.query("removeText", { id: text._id });
            } else {
              removeText(text._id);
            }
            toast.update(infotoast, { progress: ++count / totalCount });
          }
        }
      }

      const removedAbbreviations: { id: string; removed: string[] }[] = [];
      for (const list of listUpload) {
        const { id, removed } = await syncQuery.query(
          "createList",
          listToJSON(list)
        );
        if (removed.length) {
          removedAbbreviations.push({ id, removed });
        }
        updateListId(list._id, id);
        toast.update(infotoast, { progress: ++count / totalCount });
      }

      for (const list of listDownload) {
        const listAsJSON = await syncQuery.query("getList", { id: list._id });
        const newList = JSONtoList({ ...listAsJSON, id: listAsJSON._id });
        addLists(newList);
        toast.update(infotoast, { progress: ++count / totalCount });
      }

      for (const text of textUpload) {
        const id = await syncQuery.query("createText", text);
        updateTextId(text._id, id);
        toast.update(infotoast, { progress: ++count / totalCount });
      }

      for (const text of textDownload) {
        const { user, ...newText } = await syncQuery.query("getText", {
          id: text._id,
        });
        addText(newText);
        toast.update(infotoast, { progress: ++count / totalCount });
      }
      toast.update(infotoast, {
        autoClose: 5000,
        progress: 0,
        type: "success",
        render() {
          return i18next.t("Synced {totalCount} lists and texts with server", {
            totalCount,
          }) as string;
        },
      });

      useListSync.setState({ removedAbbreviations });
    }
    useLists.setState({ user: user!._id });
    usePrewrittenText.setState({ user: user!._id });
    set({ active: true });
  },
  async checkDiffs() {
    const { listsNotOnLocal, listsNotOnServer, diffingLists } =
      await getDiffingLists();
    const { textsNotOnLocal, textsNotOnServer, diffingTexts } =
      await getDiffingTexts();
    if (
      !![
        listsNotOnLocal,
        listsNotOnServer,
        diffingLists,
        textsNotOnLocal,
        textsNotOnServer,
        diffingTexts,
      ].find((arr) => !!arr.length)
    ) {
      useListSync.setState({
        listsNotOnLocal,
        listsNotOnServer,
        diffingLists,
        upload: listsNotOnServer,
        download: listsNotOnLocal,
      });
      useTextSync.setState({
        textsNotOnLocal,
        textsNotOnServer,
        diffingTexts,
        upload: textsNotOnServer,
        download: textsNotOnLocal,
      });
      set({ open: true });
    } else {
      const { user } = useLogin.getState();
      useLists.setState({ user: user!._id });
      usePrewrittenText.setState({ user: user!._id });
      set({ active: true });
    }
  },
  async updateServerInfo() {
    const serverListInfo = await syncQuery.query("getListInfo");
    const serverTextInfo = await syncQuery.query("getTextInfo");
    useListSync.setState({ serverListInfo });
    useTextSync.setState({ serverTextInfo });
  },
}));

if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    if (newModule) {
      newModule.useSync.setState(useSync.getState());
    }
  });
}

export async function syncWithServer(newId: string) {
  await listAreLoaded;
  await textsAreLoaded;
  useSync.setState({ active: false });
  const { updateServerInfo, checkDiffs } = useSync.getState();
  const { abbreviationLists, user: listUser } = useLists.getState();
  const { collection, user: textUser } = usePrewrittenText.getState();

  if (!!listUser && listUser !== newId) {
    await set(
      `lists-coldstore-${listUser}`,
      abbreviationLists.map((list) => listToJSON(list))
    );
    const storedLists = await get(`lists-coldstore-${newId}`);
    useLists.setState({
      abbreviationLists: storedLists ? JSONtoList(storedLists) : [],
      user: newId,
    });
  }

  if (textUser && textUser !== newId) {
    await set(`texts-coldstore-${textUser}`, collection);
    const storedTexts = await get(`texts-coldstore-${newId}`);
    usePrewrittenText.setState({
      collection: storedTexts || [],
      user: newId,
    });
  }

  await updateServerInfo();

  if (!listUser && abbreviationLists.length) {
    const firstTimeSync = await confirm({
      title: i18next.t("Do you want to sync lists from this computer?"),
      content: (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>
              {
                i18next.t(
                  "It looks like you haven't synced lists from this computer before. You have {count} local lists that have not been saved on the server. Do you want to start syncing your lists now?",
                  { count: abbreviationLists.length }
                ) as string
              }
            </Typography>
          </Grid>
        </Grid>
      ),
    });

    if (firstTimeSync) {
      checkDiffs();
    }
  } else {
    checkDiffs();
  }
}
