import { Delete } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { PrewrittenText, usePrewrittenText } from "PreWritten";
import { useTranslation } from "react-i18next";
import { syncQuery } from "serversync";
import { FromQuery } from "query-client";
import { datetime } from "utility";
import { create } from "zustand";
import { StyledDeleteToggleButton, StyledToggleButton } from "./lists";

export type ServerText = FromQuery<typeof syncQuery, "getTextInfo">[number];

export type DiffingText = {
  texts: (ServerText | PrewrittenText)[];
  selected: number;
};

type TextSyncStore = {
  serverTextInfo: ServerText[];
  textsNotOnLocal: ServerText[];
  textsNotOnServer: PrewrittenText[];
  diffingTexts: DiffingText[];
  download: ServerText[];
  upload: PrewrittenText[];
};

export const useTextSync = create<TextSyncStore>((set, get) => ({
  serverTextInfo: [],
  textsNotOnLocal: [],
  textsNotOnServer: [],
  diffingTexts: [],
  download: [],
  upload: [],
}));

if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    if (newModule) {
      newModule.useTextSync.setState(useTextSync.getState());
    }
  });
}

export async function getDiffingTexts() {
  const { serverTextInfo } = useTextSync.getState();
  const { collection } = usePrewrittenText.getState();

  const diffingTexts: DiffingText[] = [];

  collection.forEach((localText) => {
    if (
      !!diffingTexts.find(
        (existingDiff) =>
          !!existingDiff.texts.find(
            (existing) => existing._id === localText._id
          )
      )
    ) {
      return;
    }
    const diff: DiffingText = {
      texts: [],
      selected: 0,
    };

    diff.texts.push(
      ...serverTextInfo.filter(
        (serverText) =>
          serverText.name === localText.name || serverText._id === localText._id
      )
    );
    diff.texts.push(
      ...collection.filter(
        (otherLocal) =>
          otherLocal._id !== localText._id && otherLocal.name === localText.name
      )
    );

    if (
      diff.texts.length === 1 &&
      diff.texts[0]._id === localText._id &&
      diff.texts[0].updatedAt === localText.updatedAt
    ) {
      return;
    }

    if (diff.texts.length) {
      diff.texts.push(localText);
      diff.texts.forEach((text, idx) => {
        if (text.updatedAt > diff.texts[diff.selected].updatedAt) {
          diff.selected = idx;
        }
      });
      diffingTexts.push(diff);
    }
  });

  const textsNotOnServer = collection.filter(
    (text) =>
      !diffingTexts.find(
        (diff) => !!diff.texts.find((existing) => existing._id === text._id)
      ) && !serverTextInfo.find((serverText) => serverText._id === text._id)
  );
  const textsNotOnLocal = serverTextInfo.filter(
    (text) =>
      !diffingTexts.find(
        (diff) => !!diff.texts.find((existing) => existing._id === text._id)
      ) && !collection.find((localText) => localText._id === text._id)
  );

  return { textsNotOnLocal, textsNotOnServer, diffingTexts };
}

export function isServerText(
  obj: ServerText | PrewrittenText
): obj is ServerText {
  return !!(obj as ServerText)?.user;
}

export function ChooseDiffingTexts() {
  const diffingTexts = useTextSync((state) => state.diffingTexts);
  const { t } = useTranslation();

  if (!diffingTexts.length) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Accordion>
        <AccordionSummary>
          {t("Texts with the same name/id. Choose one to keep.")}{" "}
          {`(${diffingTexts.length})`}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sx={{ textAlign: "center" }}>
            {diffingTexts.map((diff) => (
              <Grid item xs={12} key={diff.texts[0]._id}>
                <Grid item xs={12}>
                  {diff.texts[0].name}
                </Grid>
                <ToggleButtonGroup
                  value={diff.selected.toString()}
                  exclusive
                  onChange={(e, value) => {
                    diff.selected = parseInt(value ?? diff.selected);
                    useTextSync.setState({ diffingTexts: [...diffingTexts] });
                  }}
                >
                  {diff.texts.map((text, idx) => {
                    return (
                      <StyledToggleButton
                        key={idx}
                        value={idx.toString()}
                        disableRipple
                      >
                        {text.name !== diff.texts[0].name && (
                          <Typography variant="body2">
                            {t("Changed name to")}: {text.name}
                          </Typography>
                        )}
                        <Typography variant="body1">
                          {text.text?.length} {t("characters")}
                        </Typography>
                        <Typography variant="body2">
                          {t("Last updated")}: {datetime(text.updatedAt)}
                        </Typography>
                      </StyledToggleButton>
                    );
                  })}
                  <Tooltip title={t("Discard all versions")}>
                    <StyledDeleteToggleButton value="-1" disableRipple>
                      <Delete />
                    </StyledDeleteToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
