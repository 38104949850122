import { useState, useMemo, useCallback, useEffect } from "react";
import { TextField, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAbbreviationButton from "./AddAbbreviationButton";
import { ButtonPreventDefault, confirm } from "ui";
import { datetime } from "utility";
import {
  DataGrid,
  GridColumns,
  Paper,
  Title,
  Toolbar,
  EditInPlace,
  Section,
} from "ui";
import { MoveAbbreviationsButton } from "./MoveAbbreviationsButton";
import { AbbreviationList } from "contextproviders/Abbreviations";
import { modifyKeys } from "contextproviders/Abbreviations/lists";

interface Props {
  lists: AbbreviationList[];
}

type FilterInput = {
  abb: string;
  word: string;
};

const filters = {
  includes:
    (search: string) =>
    ({ abb, word }: FilterInput) =>
      abb.toLowerCase().includes(search.toLowerCase()) ||
      word.toLowerCase().includes(search.toLowerCase()),
  "starts with":
    (search: string) =>
    ({ abb, word }: FilterInput) =>
      abb.toLowerCase().startsWith(search.toLowerCase()) ||
      word.toLowerCase().startsWith(search.toLowerCase()),
  "ends with":
    (search: string) =>
    ({ abb, word }: FilterInput) =>
      abb.toLowerCase().endsWith(search.toLowerCase()) ||
      word.toLowerCase().endsWith(search.toLowerCase()),
  "only multi-word":
    (search: string) =>
    ({ abb, word }: FilterInput) =>
      word.includes(" ") &&
      (abb.toLowerCase().includes(search.toLowerCase()) ||
        word.toLowerCase().includes(search.toLowerCase())),
} as const;

const searchTypes = Object.keys(filters) as Array<keyof typeof filters>;

export default function ManageList({ lists }: Props) {
  const { t } = useTranslation();
  const [searchstr, setSearchstr] = useState("");
  const [selected, setSelected] = useState<typeof abbexpanded>([]);
  const [searchType, setSearchType] =
    useState<typeof searchTypes[number]>("includes");

  const abbexpanded = useMemo(() => {
    return lists.flatMap((list) =>
      [...list.abb2expanded.entries()].map((val) => ({
        name: list.name,
        id: `${list._id}${val[0]}`,
        listId: list._id,
        abb: val[0],
        word: val[1][1],
        updatedAt: val[1][0],
      }))
    );
  }, [lists]);

  useEffect(() => {
    setSelected(
      (prev) =>
        prev
          .map((sel) => {
            const exists = lists
              .find((list) => list._id === sel.listId)
              ?.abb2expanded.get(sel.abb);
            if (exists) {
              return { ...sel, word: exists[1] };
            }
            return null;
          })
          .filter((sel) => !!sel) as typeof abbexpanded
    );
  }, [lists]);

  const listNames = useMemo(
    () =>
      lists.reduce<string>(
        (prev, cur) => (prev ? `${prev}, ${cur.name}` : cur.name),
        ""
      ),
    [lists]
  );

  const filter = useCallback(filters[searchType](searchstr), [
    searchstr,
    searchType,
  ]);

  const columns = useMemo(() => {
    const c: GridColumns<typeof abbexpanded[0]> = [
      {
        name: t("Abbreviation"),
        field: "abb",
        Component({ row }) {
          return (
            <EditInPlace
              text={row.abb}
              onEdit={(newAbb) => {
                modifyKeys({ remove: [row], add: [{ ...row, abb: newAbb }] });
              }}
            />
          );
        },
      },
      {
        name: t("Word"),
        field: "word",
        Component({ row: { listId, word, abb } }) {
          return (
            <EditInPlace
              text={word}
              onEdit={(newWord) =>
                modifyKeys({ add: [{ listId, abb, word: newWord }] })
              }
            />
          );
        },
      },
      {
        name: t("Modified"),
        field: "updatedAt",
        get(row) {
          return datetime(row.updatedAt);
        },
      },
      {
        name: "Delete",
        noHeader: true,
        disableSort: true,
        Component({ row: { listId, abb, word } }) {
          return (
            <ButtonPreventDefault
              onClick={async () => {
                const confirmed = await confirm({
                  title: t("Delete abbreviation"),
                  content: `${t("Do you want to delete")} ${abb}`,
                });
                if (confirmed) {
                  modifyKeys({ remove: [{ listId, abb, word }] });
                }
              }}
            >
              <DeleteIcon />
            </ButtonPreventDefault>
          );
        },
      },
    ];
    if (lists.length > 1) {
      c.splice(2, 0, { name: t("List"), field: "name" });
    }
    return c;
  }, [lists]);

  return (
    <Paper width="100%">
      <Toolbar>
        <Title>
          {searchstr ? `${listNames} (${t("filtered")})` : `${listNames}`}
        </Title>
        <Section>
          <TextField
            label={t("Search")}
            variant="standard"
            size="small"
            value={searchstr}
            onChange={(e) => setSearchstr(e.target.value)}
            autoFocus
          />
          <Select
            value={searchType}
            onChange={(e) =>
              setSearchType(e.target.value as typeof searchTypes[number])
            }
          >
            {searchTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {t(type)}
              </MenuItem>
            ))}
          </Select>
        </Section>
        <Section>
          {!!selected.length && <MoveAbbreviationsButton selected={selected} />}
        </Section>
        <Section>
          <AddAbbreviationButton lists={lists}>
            {t("Add abbreviation")}
          </AddAbbreviationButton>
        </Section>
      </Toolbar>
      <DataGrid
        rows={abbexpanded}
        filter={filter}
        jumpToPage
        selected={selected}
        setSelected={setSelected}
        columns={columns}
        defaultSortBy={t("Modified")}
        idField="id"
      />
    </Paper>
  );
}
