import {
  Grid,
  GridProps,
  Paper as MuiPaper,
  Toolbar as MuiToolbar,
  Typography,
} from "@mui/material";
import React, { PropsWithChildren } from "react";

export function Paper({ children, ...props }: PropsWithChildren<GridProps>) {
  return (
    <Grid item {...props}>
      <MuiPaper>{children}</MuiPaper>
    </Grid>
  );
}

export function Toolbar({ children }: PropsWithChildren<{}>) {
  return (
    <MuiToolbar
      style={{
        backgroundColor: "#ffc107",
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        {children}
      </Grid>
    </MuiToolbar>
  );
}

export function Section({ children }: PropsWithChildren<{}>) {
  return (
    <Grid item container xs="auto" gap={1} alignItems="center">
      {children}
    </Grid>
  );
}

export function Title({ children }: PropsWithChildren<{}>) {
  return <Typography variant="h6">{children}</Typography>;
}
