import { Extension } from "@tiptap/react";
import { Doc } from "yjs";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { Session } from "settings/RemoteInterpretation";
import {
  goBack,
  jumpBackwardSentence,
  jumpBackwardWord,
  jumpForwardSentence,
  jumpForwardWord,
  nextSentence,
  nextWord,
  usePrewrittenText,
} from "PreWritten";
import { useEditor } from "./EditorStore";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    customCommands: {
      /**
       * Move cursor to end of document
       */
      moveCursorToEnd: () => ReturnType;
      /**
       * Abbreviate word at current selection, then add a word-joiner after
       */
      addWordJoiner: () => ReturnType;
      /**
       * Insert word from pre-written text
       */
      insertWord: () => ReturnType;
      /**
       * Go a word back in pre-written text
       */
      removeWord: () => ReturnType;
      /**
       * Insert line from pre-written text
       */
      insertLine: () => ReturnType;
      /**
       * Go back a line in pre-written text
       */
      removeLine: () => ReturnType;
      /**
       * Jump forward a word in pre-written text
       */
      jumpWordForward: () => ReturnType;
      /**
       * Jump backwards a word in pre-written text
       */
      jumpWordBack: () => ReturnType;
      /**
       * Jump forward a line in pre-written text
       */
      jumpLineForward: () => ReturnType;
      /**
       * Jump backwards a line in pre-written text
       */
      jumpLineBack: () => ReturnType;
      /**
       * Insert a text-string but replace \n with simulated
       * enter-presses to get proper paragraphs
       */
      insertTextWithLinebreaks: (text: string) => ReturnType;
      /**
       * Focus the editor and put the cursor back where it was based on last onBlur
       * This is done automatically if event.relatedTarget is set, but that's not the case
       * when giving focus back after closing the settings menu for example
       */
      refocus: () => ReturnType;
      /**
       * Clear the text view from text by moving current text into transcription and then emptying the content
       */
      clearAllText: () => ReturnType;
    };
  }
}

export default Extension.create({
  name: "customCommands",
  addCommands() {
    return {
      moveCursorToEnd:
        () =>
        ({ chain }) =>
          chain().focus("end").scrollIntoView().run(),
      addWordJoiner:
        () =>
        ({ chain }) =>
          chain().abbreviateAtSelection().insertContent("\u2060").run(),
      insertWord:
        () =>
        ({ chain }) => {
          if (!usePrewrittenText.getState().active) {
            return false;
          }

          return chain()
            .focus()
            .moveCursorToEnd()
            .command(({ commands, editor }) => {
              const lastLine = editor.state.doc.lastChild!.textContent;
              const word = nextWord();
              if (!word) {
                return true;
              }
              if (
                word.startsWith("\n") ||
                lastLine === "" ||
                lastLine.endsWith(" ")
              ) {
                return commands.insertTextWithLinebreaks(word);
              } else {
                return commands.insertTextWithLinebreaks(" " + word);
              }
            })
            .run();
        },
      removeWord:
        () =>
        ({ chain }) => {
          if (!usePrewrittenText.getState().active) {
            return false;
          }

          return chain()
            .focus()
            .moveCursorToEnd()
            .command(({ tr, commands }) => {
              const lastLine = tr.doc.lastChild!;
              const pos = tr.selection.$from.pos;
              const lineStartIndex = pos - tr.selection.$from.parentOffset;
              const adjust = tr.selection.$from.pos - pos;
              let start = pos;
              let content = "";
              while (start > 0 && start > lineStartIndex) {
                content = tr.doc.textBetween(start, pos);
                if (content.trim() !== "" && content.search(/^. /) !== -1) {
                  content = content.slice(1);
                  ++start;
                  break;
                }
                --start;
              }

              if (start === 0 || start === lineStartIndex) {
                goBack("\n" + lastLine.textContent);
                return commands.deleteNode("paragraph");
              }

              goBack(content);
              tr.deleteRange(start + adjust, pos + adjust);
              return true;
            })
            .run();
        },
      insertLine:
        () =>
        ({ chain }) => {
          if (!usePrewrittenText.getState().active) {
            return false;
          }

          const lastLine = this.editor.state.doc.lastChild!.textContent;
          const word = nextSentence();
          if (!word) {
            return true;
          }
          if (
            word.startsWith("\n") ||
            lastLine === "" ||
            lastLine.endsWith(" ")
          ) {
            return chain()
              .moveCursorToEnd()
              .insertTextWithLinebreaks(word)
              .run();
          } else {
            return chain()
              .moveCursorToEnd()
              .insertTextWithLinebreaks(" " + word)
              .run();
          }
        },
      removeLine:
        () =>
        ({ chain }) => {
          if (!usePrewrittenText.getState().active) {
            return false;
          }

          const lastLine = this.editor.state.doc.lastChild!;
          goBack("\n" + lastLine.textContent);
          return chain().moveCursorToEnd().deleteNode("paragraph").run();
        },
      insertTextWithLinebreaks:
        (text: string) =>
        ({ chain }) => {
          if (!text) {
            return true;
          }

          const lines = text.split("\n");
          let myChain = chain();
          for (let i = 0; i < lines.length; ++i) {
            if (lines[i]) {
              myChain = myChain.insertContent(lines[i]);
            }
            if (i !== lines.length - 1) {
              myChain = myChain.enter();
            }
          }
          return myChain.run();
        },
      clearAllText:
        () =>
        ({ commands }) => {
          let content = [];
          const { ydoc } = useEditor.getState();
          const current = ydoc.getXmlFragment("current");
          for (let i = 0; i < current.length; ++i) {
            content.push(current.get(i).toJSON());
          }
          const transcription = ydoc.getArray("transcription");
          const size = transcription.length;
          commands.setContent("");
          transcription.insert(size, content);
          return true;
        },
    };
  },
  addKeyboardShortcuts() {
    return {
      Escape: ({ editor }) => editor.commands.moveCursorToEnd(),
      ArrowLeft: ({ editor }) => editor.commands.removeWord(),
      ArrowRight: ({ editor }) => editor.commands.insertWord(),
      ArrowUp: ({ editor }) => editor.commands.removeLine(),
      ArrowDown: ({ editor }) => editor.commands.insertLine(),
      "alt-ArrowLeft": () => {
        jumpBackwardWord();
        return true;
      },
      "alt-ArrowRight": () => {
        jumpForwardWord();
        return true;
      },
      "alt-ArrowDown": () => {
        jumpForwardSentence();
        return true;
      },
      "alt-ArrowUp": () => {
        jumpBackwardSentence();
        return true;
      },
      "mod-Enter": () => {
        const { provider, ydoc } = useEditor.getState();
        if (provider) {
          const state = provider.awareness.getStates();
          const stateData = Array.from<any>(state.entries());
          const current = provider.awareness.getLocalState()?.change;
          const data = Array.from<any>(state.values());
          const numberOfChangeRequests = data.filter(
            (val) => val.change
          ).length;
          if (current) {
            provider.awareness.setLocalStateField("change", false);
          } else if (numberOfChangeRequests > 0) {
            const active = ydoc.getText("active");
            const myId = ydoc.clientID;
            if (active.toJSON() === myId.toString()) {
              const other = stateData.find(
                (val) => val[0] !== myId && val[1]?.["change"]
              );
              const otherId = other?.[0].toString();
              if (otherId) {
                active.delete(0, active.length);
                active.insert(0, otherId);
              }
            } else {
              active.delete(0, active.length);
              active.insert(0, myId.toString());
            }
            provider.awareness.setLocalStateField("changeTime", Date.now());
          } else {
            provider.awareness.setLocalStateField("change", true);
          }
        }
        return true;
      },
    };
  },
});
