import { HocuspocusProvider } from "@hocuspocus/provider";
import { Editor } from "@tiptap/react";
import { create } from "zustand";
import Chat, { ChatMessage } from "./Chat";
import * as Y from "yjs";
import Paragraph from "./Paragraph";
import { Capitalizer } from "./Capitalizer";
import { Abbreviations } from "./Abbreviations";
import Highlight from "./Highlight";
import { EventHandler } from "./ProsemirrorPlugins";
import CustomCommands from "./CustomCommands";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import { useRemoteInterpretation } from "settings/RemoteInterpretation";
import { useLogin } from "ui";
import { toast } from "react-toastify";
import { Unauthorized } from "@hocuspocus/common";
import { t } from "i18next";
import Collaboration from "@tiptap/extension-collaboration";
import { alertConnect, alertDisconnect } from "./ClientsConnected";

type EditorStore = {
  interpreters: string[];
  listeners: string[];
  timeSinceLastChange: number;
  lastChangeTime: number;
  ydoc: Y.Doc;
  provider: HocuspocusProvider | null;
  editor: Editor;
  messages: Array<ChatMessage>;
  status: string;
  active: boolean;
  change: boolean;
  bold: boolean;
  italic: boolean;
  connect: (room: string) => void;
  disconnect: () => void;
};

export const useEditor = create<EditorStore>((set, get) => {
  const initialYDoc = new Y.Doc();
  return {
    interpreters: [] as string[],
    listeners: [] as string[],
    lastChangeTime: 0,
    timeSinceLastChange: 0,
    ydoc: initialYDoc,
    provider: null,
    editor: new Editor({
      extensions: [
        Document,
        Paragraph,
        Text,
        Capitalizer,
        Abbreviations,
        Bold,
        Italic,
        Highlight,
        EventHandler,
        CustomCommands.configure({
          doc: initialYDoc,
        }),
      ],
      autofocus: "end",
      onTransaction({ editor }) {
        set({
          bold: editor.isActive("bold"),
          italic: editor.isActive("italic"),
        });
      },
    }),
    messages: [] as Array<ChatMessage>,
    status: "initializing",
    active: false,
    change: false,
    bold: false,
    italic: false,
    connect(name) {
      const { provider, editor } = get();

      if (provider) {
        return;
      }

      editor.destroy();

      const { local } = useRemoteInterpretation.getState();
      const { user, token, offlineToken, getUser, rememberMe } =
        useLogin.getState();
      const { leaveRoom } = useRemoteInterpretation.getState();

      const newYDoc = new Y.Doc();
      const active = newYDoc.getText("active");

      active.observe(() =>
        set({ active: active.toJSON() === newYDoc.clientID.toString() })
      );

      const newProvider = new HocuspocusProvider({
        url: local
          ? `ws://${local}:3067`
          : import.meta.env.REACT_APP_SERVER_URL!,
        name,
        document: newYDoc,
        broadcast: false,
        parameters: {
          // If we don't add any parameters, the auth token is not sent either?
          token: local ? offlineToken : token,
        },
        token: local ? offlineToken : token,
        delay: 1000,
        factor: 1,
        maxAttempts: 10,
        timeout: 20000,
        connect: false,
        onAuthenticationFailed: () => {
          toast.error(
            t<string>(
              "Authentication with the server failed. If the error persists, try to restart the app."
            )
          );
          leaveRoom();
          getUser(rememberMe, rememberMe ? "" : `Bearer ${token}`);
        },
        onClose(data) {
          if (data.event.code === Unauthorized.code) {
            toast.error(
              t<string>(
                "Looks like you connected from a different device. Your license can only have one active connection at a time, so this connection was closed."
              ),
              { autoClose: false }
            );
            leaveRoom();
            return;
          }
        },
        onStatus({ status }) {
          set({ status });
        },
        onSynced(data) {
          if (data.state) {
            const name =
              user?.first_name && user?.last_name
                ? `${user.first_name} ${user.last_name}`
                : "Unknown";
            get().provider?.awareness.setLocalStateField("interpreter", name);
          }
        },
        onAwarenessChange() {
          const states = newProvider.awareness.getStates();
          const { ydoc, lastChangeTime, provider } = get();
          const data: any[] = Array.from(states.values());
          const clients = Array.from(states.keys());

          const interpreters = data
            .filter((val) => val.interpreter)
            .map((val) => val.interpreter);
          const listeners = data
            .filter((val) => val.listener)
            .map((val) => val.listener);
          if (provider?.synced) {
            const active = ydoc.getText("active");
            if (interpreters.length === 1) {
              active.delete(0, active.length);
              active.insert(0, ydoc.clientID.toString());
            } else if (
              interpreters.length &&
              !clients.includes(parseInt(active.toJSON()))
            ) {
              active.delete(0, active.length);
              active.insert(0, ydoc.clientID.toString());
            }
          }

          useEditor.setState(({ listeners: prev }) => {
            if (provider?.synced) {
              const connected = listeners.filter((l) => !prev.includes(l));
              const disconnected = prev.filter((l) => !listeners.includes(l));

              for (const listener of connected) {
                alertConnect(listener);
              }

              for (const listener of disconnected) {
                alertDisconnect(listener);
              }
            }

            return { interpreters, listeners };
          });

          const numberOfChangeRequests = data.filter(
            (val) => val.change
          ).length;
          const times: number[] = data
            .map((val) => parseInt(val.changeTime))
            .filter((val) => val);
          const latest = Math.max(...times);
          if (latest > lastChangeTime) {
            // Without settimeout the function seems to yield, causing unspecified order
            // for updating the border color
            setTimeout(() =>
              newProvider.awareness.setLocalStateField("change", false)
            );
            set({ lastChangeTime: latest });
          } else if (numberOfChangeRequests > 0) {
            useEditor.setState({ change: true });
          } else {
            useEditor.setState({ change: false });
          }
        },
      });

      set({
        editor: new Editor({
          extensions: [
            Document,
            Paragraph,
            Text,
            Capitalizer,
            Abbreviations,
            Bold,
            Italic,
            Highlight,
            EventHandler,
            Chat,
            CustomCommands,
            Collaboration.configure({
              document: newYDoc,
              field: "current",
            }),
          ],
          autofocus: "end",
          onTransaction({ editor }) {
            set({
              bold: editor.isActive("bold"),
              italic: editor.isActive("italic"),
            });
          },
        }),
        provider: newProvider,
        ydoc: newYDoc,
        active: false,
        change: false,
      });

      newProvider.connect().catch((err) => {
        toast.error(t<string>("You were disconnected from the session"));
        useRemoteInterpretation.getState().leaveRoom();
      });

      const chatArray = newYDoc.getArray("chat");
      chatArray.observe((event) => {
        set({ messages: event.target.toJSON() });
      });
    },
    disconnect() {
      const { provider, editor, ydoc } = get();
      if (!provider) {
        return;
      }

      const currentContent = editor.getHTML();
      provider.disconnect();
      editor.destroy();

      useEditor.setState({
        editor: new Editor({
          extensions: [
            Document,
            Paragraph,
            Text,
            Capitalizer,
            Abbreviations,
            Bold,
            Italic,
            Highlight,
            EventHandler,
            CustomCommands.configure({
              doc: ydoc,
            }),
          ],
          autofocus: "end",
          onTransaction({ editor }) {
            set({
              bold: editor.isActive("bold"),
              italic: editor.isActive("italic"),
            });
          },
        }),
        interpreters: [],
        listeners: [],
        provider: null,
        messages: [],
        lastChangeTime: 0,
        active: false,
        change: false,
      });

      if (currentContent) {
        get().editor.commands.setContent(currentContent);
      }
    },
  };
});

if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    if (newModule) {
      newModule.useEditor.setState(useEditor.getState());
    }
  });
}

setInterval(() => {
  const { lastChangeTime, timeSinceLastChange } = useEditor.getState();
  if (lastChangeTime) {
    useEditor.setState({
      timeSinceLastChange: Date.now() - lastChangeTime,
    });
  } else if (timeSinceLastChange) {
    useEditor.setState({ timeSinceLastChange: 0 });
  }
}, 1000);
