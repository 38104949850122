import { AbbreviationList } from "contextproviders/Abbreviations";
import { listToJSON } from "hooks/persisting-storage";

const listsServer = import.meta.env.REACT_APP_LISTS_URL;

export interface AbbreviationListInfo extends AbbreviationList {
  readRights: string[];
  writeRights: string[];
}

export async function getListsInfo(
  token: string
): Promise<AbbreviationListInfo[]> {
  return await fetch(`${listsServer}/lists/info`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json());
}

export async function getList(token: string, listId: string) {
  return await fetch(`${listsServer}/lists/${listId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json());
}

export async function deleteList(token: string, listId: string) {
  return await fetch(`${listsServer}/lists/${listId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function uploadList(
  token: string,
  list: AbbreviationList,
  readRights: string[],
  writeRights: string[]
) {
  const jsonList = listToJSON(list);
  return await fetch(`${listsServer}/lists`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ list: { ...jsonList, readRights, writeRights } }),
  });
}

export function descendingStringComparator(a: string, b: string) {
  const A = a.toLowerCase();
  const B = b.toLowerCase();
  if (B < A) {
    return -1;
  }
  if (B > A) {
    return 1;
  }
  return 0;
}

export function descendingDatestringComparator(a: string, b: string) {
  const A = new Date(a);
  const B = new Date(b);
  if (B < A) {
    return -1;
  }
  if (B > A) {
    return 1;
  }
  return 0;
}
