import { useMemo } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { addHotkey } from "keybindings/KeyBindings";
import { useTranslation } from "react-i18next";
import { editorSelectedWord, getKeyByValue } from "word-lookup/wordLookup";
import { useAbbreviations } from "contextproviders/Abbreviations";
import {
  listFromId,
  modifyKeys,
  nameFromId,
  nextActiveListIdExceptLast,
} from "contextproviders/Abbreviations/lists";
import { create } from "zustand";
import { useEditor } from "tiptap/EditorStore";

type QuickAddStore = {
  abb: string;
  word: string;
  f10ID: string;
  listId: string;
};

const useQuickAdd = create<QuickAddStore>((set, get) => ({
  abb: "",
  word: "",
  f10ID: "",
  listId: "",
}));

if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    if (newModule) {
      newModule.useQuickAdd.setState(useQuickAdd.getState());
    }
  });
}

function nextQuickAddList() {
  const { listId, f10ID } = useQuickAdd.getState();
  const { activeListIds } = useAbbreviations.getState();
  if (!listId) {
    const newId = activeListIds.includes(f10ID)
      ? f10ID
      : nextActiveListIdExceptLast(f10ID);
    useQuickAdd.setState({
      listId: newId,
      f10ID: newId,
      word: editorSelectedWord(),
    });
  } else {
    const newId =
      listId !== f10ID && activeListIds.includes(f10ID)
        ? f10ID
        : nextActiveListIdExceptLast(f10ID);
    useQuickAdd.setState({ listId: newId, f10ID: newId });
  }
}

function lastQuickAddList() {
  const { listId } = useQuickAdd.getState();
  const { activeListIds } = useAbbreviations.getState();
  if (!listId) {
    useQuickAdd.setState({
      listId: activeListIds.at(-1),
      word: editorSelectedWord(),
    });
  } else {
    useQuickAdd.setState({ listId: activeListIds.at(-1) });
  }
}

function close() {
  const { editor } = useEditor.getState();
  useQuickAdd.setState({
    abb: "",
    word: "",
    listId: "",
  });
  editor.commands.refocus();
}

function addAbbreviation() {
  const { listId, abb, word } = useQuickAdd.getState();
  requestAnimationFrame(() => {
    modifyKeys({ add: [{ listId, abb, word }] });
  });
  close();
}

export function QuickAddAbbreviation() {
  const { t } = useTranslation();
  const mergedList = useAbbreviations((state) => state.mergedList);
  const activeListIds = useAbbreviations((state) => state.activeListIds);
  const abb = useQuickAdd((state) => state.abb);
  const word = useQuickAdd((state) => state.word);

  const listId = useQuickAdd((state) => state.listId);

  const list = useMemo(() => {
    return listFromId(listId);
  }, [listId]);

  //check if typed abb maps to something (in selected list or mergedlist aka all active lists)
  const existingExpanded = useMemo(() => {
    return list && (list.abb2expanded.get(abb)?.[1] || "");
  }, [abb, list]);

  const existingExpanded_merged = useMemo(() => {
    return mergedList.abb2expanded.get(abb)?.[1] || "";
  }, [abb, mergedList]);

  const abbHelperText = useMemo(() => {
    if (existingExpanded) {
      return `[${abb} = ${existingExpanded}]`;
    } else if (existingExpanded_merged) {
      return `[${abb} = ${existingExpanded_merged}] (hotkey)`;
    } else {
      return "";
    }
  }, [abb, existingExpanded, existingExpanded_merged]);

  //check if typed expanded maps from something (in selected list or mergedlist aka all active lists)
  const existingAbb = useMemo(() => {
    return list && (getKeyByValue(list.abb2expanded, word) || "");
  }, [word, list]);

  const existingAbb_merged = useMemo(() => {
    return getKeyByValue(mergedList.abb2expanded, word) || "";
  }, [word, mergedList]);

  const expandedHelperText = useMemo(() => {
    if (existingAbb) {
      return `[${existingAbb} = ${word}]`;
    } else if (existingAbb_merged) {
      return `[${existingAbb_merged} = ${word}] (hotkey)`;
    } else {
      return "";
    }
  }, [word, existingAbb, existingAbb_merged]);

  return (
    <Dialog
      open={!!listId}
      onClose={close}
      sx={{
        display: "flex",
        justifyContent: "right",
      }}
      PaperProps={{ sx: { alignSelf: "flex-start" } }}
      transitionDuration={0}
      disableRestoreFocus
    >
      <DialogTitle>{`${t("List")}: ${nameFromId(listId)}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("Create new abbreviation")}</DialogContentText>
        <TextField
          onKeyDown={(e) => {
            e.key === "Enter" && addAbbreviation();
          }}
          autoFocus
          autoComplete="off"
          margin="dense"
          id="abbreviationKey"
          label={t("Abbreviation")}
          type="text"
          variant="standard"
          value={abb}
          helperText={abbHelperText}
          onChange={(e) => useQuickAdd.setState({ abb: e.target.value })}
        />
        <TextField
          onKeyDown={(e) => e.key === "Enter" && addAbbreviation()}
          autoComplete="off"
          margin="dense"
          id="abbreviationValue"
          label={t("Word")}
          type="text"
          variant="standard"
          value={word}
          helperText={expandedHelperText}
          onChange={(e) => useQuickAdd.setState({ word: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t("Cancel")}</Button>
        <Button onClick={addAbbreviation}>{t("Create")}</Button>
      </DialogActions>
    </Dialog>
  );
}

addHotkey("F9", () => {
  lastQuickAddList();
});
addHotkey("F10", () => {
  nextQuickAddList();
});
