import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useLogin } from "ui";
import { SessionRouter } from "pwa-server";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { DropdownButton } from "ui";
import { sessionQuery, useRemoteInterpretation } from ".";
import { removeWordJoiner } from "utility";
import { QueryClient } from "query-client";

type Transcript = Array<{
  start: number;
  stop: number;
  html: string;
}>;

type SRT = Array<{
  lines: Array<string>;
  start: string;
  stop: string;
}>;

export let localSessionQuery = createLocalSessionQuery();

function createLocalSessionQuery(url: string = "localhost") {
  return new QueryClient<SessionRouter>(
    `http://${url}:3068/rpc`,
    () => {},
    () => useLogin.getState().offlineToken
  );
}

export function updateLocalSessionQuery(url?: string) {
  localSessionQuery = createLocalSessionQuery(url);
}

export function TranscriptButton() {
  const { t } = useTranslation();
  const local = useRemoteInterpretation((state) => state.local);
  const room = useRemoteInterpretation((state) => state.room);

  const token = useLogin((state) => state.token);

  const [charsPerLine, setCharsPerLine] = useState(40);
  const [timeAdjust, setTimeAdjust] = useState("");
  const [transcript, setTranscript] = useState<Transcript | null>(null);
  const [srt, setSrt] = useState("");

  const [text, setText] = useState("");

  const url = useMemo(() => {
    const div = document.createElement("div");
    div.innerHTML = text || srt;
    const blob = new Blob([removeWordJoiner(div.innerText)]);
    return URL.createObjectURL(blob);
  }, [text, srt]);

  if (room === null) {
    return null;
  }

  const onClose = () => {
    setText("");
    setSrt("");
    setCharsPerLine(40);
    setTimeAdjust("");
  };

  return (
    <>
      <DropdownButton label={t("Transcription")}>
        <MenuItem
          onClick={async () => {
            const ts = local
              ? await localSessionQuery.query("getTranscript", {
                  id: room._id,
                })
              : await sessionQuery.query("getTranscript", {
                  id: room._id,
                });
            let plainText = "";
            ts.forEach((line) => {
              plainText += line.html + "\n";
            });

            setText(plainText);
          }}
        >
          {t("Plain transcription")}
        </MenuItem>
        {!room.disabled.includes("SRT") && (
          <MenuItem
            onClick={async () => {
              const ts = await sessionQuery.query("getTranscript", {
                id: room._id,
              });
              setTranscript(ts);
            }}
          >
            {t("SRT transcription")}
          </MenuItem>
        )}
        {!room.disabled.includes("SRT") && (
          <MenuItem
            onClick={async () => {
              const ts = await sessionQuery.query("getTranscript", {
                id: room._id,
              });

              const response = await fetch(
                `${
                  import.meta.env.REACT_APP_TRANSCRIPT_BACKEND_URL
                }/transcript`,
                {
                  method: "POST",
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    name: room.name,
                    text: ts,
                    organizations: room.organizations,
                    interpreters: room.interpreters,
                    groups: room.groups,
                  }),
                }
              );

              if (response.status === 200) {
                toast.success(t("Transcription saved on server"));
              } else {
                toast.error(
                  t("Something went wrong, could not save transcription")
                );
              }
            }}
          >
            {t("Save on server")}
          </MenuItem>
        )}
      </DropdownButton>
      <Dialog open={!!transcript} onClose={() => setTranscript(null)}>
        <DialogTitle>{t("Show SRT transcription")}</DialogTitle>
        <DialogContent>
          <Grid container padding={1} spacing={1}>
            <Grid item xs>
              <TextField
                type="number"
                label={t("Characters per line")}
                value={charsPerLine}
                onChange={(e) => setCharsPerLine(parseInt(e.target.value))}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label={t("Time adjustment")}
                value={timeAdjust}
                onChange={(e) => setTimeAdjust(e.target.value)}
              />
            </Grid>
          </Grid>
          <Typography variant="body2">
            {t("Format for time adjustment is {format}", {
              format: "'7h12m24s114ms'",
            })}
          </Typography>
          <Typography variant="body2">{t("Example:")}</Typography>
          <Typography variant="body2">
            {t("2m12s = 2 minutes and 12 seconds")}
          </Typography>
          <Typography variant="body2">
            {t("-1h512ms = minus 1 hour and 512 milliseconds")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setTranscript(null);
              const response = await fetch(
                `${import.meta.env.REACT_APP_TRANSCRIPT_BACKEND_URL}/srt`,
                {
                  method: "POST",
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    text: transcript,
                    charsPerLine,
                    timeAdjust,
                  }),
                }
              );

              const srt: SRT = await response.json();

              let newSrt = "";
              let blockNumber = 1;
              srt.forEach((block) => {
                if (block.lines.length && block.lines[0]) {
                  newSrt += `${blockNumber++}\n${block.start} --> ${
                    block.stop
                  }\n`;
                  for (const line of block.lines) {
                    newSrt += `${line}\n`;
                  }
                  newSrt += "\n";
                }
              });

              setSrt(newSrt);
            }}
          >
            {t("Get")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!text || !!srt} onClose={onClose}>
        <DialogContent>
          <div
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{ __html: text || srt }}
          />
        </DialogContent>
        <DialogActions>
          <Button href={url} download={`${room.name}.${srt ? "srt" : "txt"}`}>
            {t("Save")}
          </Button>
          <Button onClick={onClose}>{t("Close")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
