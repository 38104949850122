export type ColorString =
  | "Black"
  | "White"
  | "Red"
  | "Green"
  | "Blue"
  | "Yellow"
  | "Purple"
  | "Orange"
  | "Teal"
  | "Gray"
  | "Chroma Green";

export type ThemeString = "Illuminate" | "DarkChapel";

export type FontString = "Arial" | "Times new Roman" | "Monospace";

export const LanguageNames = {
  sv: "Svenska",
  en: "English",
};

export type LanguageString = keyof typeof LanguageNames;

interface ColorScheme {
  background: ColorString;
  text: ColorString;
  id: string;
}

export interface Settings {
  colorschemes: ColorScheme[];
  theme: ThemeString;
  fontSize: number;
  padding: number;
  fontFace: FontString;
  selectedColorScheme: ColorScheme;
  prewrittentextsize: number;
  showactiveinterpreter: boolean;
  showsessionstatus: boolean;
  prewrittenTextPreviewWidth: number;
  language: LanguageString;
  userId: string;
  objectHash: string;
}

const defaultColors: Omit<ColorScheme, "id">[] = [
  { background: "Black", text: "White" },
  {
    background: "Black",
    text: "Yellow",
  },
  {
    background: "Blue",
    text: "Yellow",
  },
  {
    background: "White",
    text: "Black",
  },
  {
    background: "Yellow",
    text: "Black",
  },
  {
    background: "Yellow",
    text: "Blue",
  },
];

const defaultColorSchemes = defaultColors.map((scheme) => ({
  ...scheme,
  id: `${scheme.background}${scheme.text}`,
}));

export const defaultSettings: Settings = {
  colorschemes: defaultColorSchemes,
  theme: "Illuminate",
  fontSize: 64,
  padding: 5,
  fontFace: "Arial",
  selectedColorScheme: defaultColorSchemes[0],
  prewrittentextsize: 24,
  showactiveinterpreter: false,
  showsessionstatus: true,
  prewrittenTextPreviewWidth: 400,
  language: "en",
  userId: "",
  objectHash: "",
};
