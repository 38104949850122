import { PropsWithChildren } from "react";
import { Typography, Button, Box, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { datetimelocalString } from "./utils";
import { Paper, Toolbar } from "ui";
import { create } from "zustand";

type FilterOptionsStore = {
  allSessions: string[];
  selectedSession: string;
  fromDate: Date;
  toDate: Date;
};

const fromDate = new Date();
const toDate = new Date();

fromDate.setHours(fromDate.getHours() - 2);
toDate.setHours(toDate.getHours() + 2);

export const useFilterOptions = create<FilterOptionsStore>((set) => ({
  allSessions: ["all", "none"],
  selectedSession: "all",
  fromDate,
  toDate,
}));

if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    if (newModule) {
      newModule.useFilterOptions.setState(useFilterOptions.getState());
    }
  });
}

export default function FilterOptions({ children }: PropsWithChildren<{}>) {
  const allSessions = useFilterOptions((state) => state.allSessions);
  const selectedSession = useFilterOptions((state) => state.selectedSession);
  const fromDate = useFilterOptions((state) => state.fromDate);
  const toDate = useFilterOptions((state) => state.toDate);

  const { t } = useTranslation();
  return (
    <Paper width="100%">
      <Toolbar>
        <Typography variant="h6">{t("Abbreviation Statistics")}</Typography>
      </Toolbar>
      <Box p={1}>
        <Grid container spacing={2}>
          <Grid
            container
            item
            spacing={2}
            xs={12}
            lg={6}
            flexDirection="column"
          >
            <Grid container item spacing={1}>
              <Grid item>
                <Typography variant="h6">{t("Session")}</Typography>
              </Grid>
              {allSessions.map((name) => (
                <Grid item key={name}>
                  <Button
                    variant={
                      name === selectedSession ? "contained" : "outlined"
                    }
                    onClick={() =>
                      useFilterOptions.setState({ selectedSession: name })
                    }
                  >
                    {t(name)}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <Grid container item spacing={1}>
              <Grid item>
                <Typography>{t("From")}</Typography>
                <TextField
                  type="datetime-local"
                  value={datetimelocalString(fromDate)}
                  onChange={(e) => {
                    if (e.target.value) {
                      useFilterOptions.setState({
                        fromDate: new Date(e.target.value),
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <Typography>{t("To")}</Typography>
                <TextField
                  type="datetime-local"
                  value={datetimelocalString(toDate)}
                  onChange={(e) => {
                    if (e.target.value) {
                      useFilterOptions.setState({
                        toDate: new Date(e.target.value),
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
