import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import sv from "./sv.json";

const resources = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
} as const;

/**
 * react-i18next with resources type augmented.
 *
 * see: https://react.i18next.com/latest/typescript#create-a-declaration-file
 */

declare module "i18next" {
  export interface CustomTypeOptions {
    //resources: typeof resources["en"];
    //uncomment above to type the { t } = useTranslation() function. (with keys as in en.json)
    returnNull: false;
  }
}

export function i18nInit() {
  i18n.use(initReactI18next).init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
      prefix: "{",
      suffix: "}",
    },
    resources,
  });
}
