import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  ChangeEvent,
  DetailedHTMLProps,
  HTMLAttributes,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { addHotkey, removeHotkey } from "keybindings/KeyBindings";
import {
  addText,
  PrewrittenText,
  removeText,
  start,
  toggleDialog,
  togglePreview,
  usePrewrittenText,
} from "PreWritten";
import { confirm, IconButtonPreventDefault } from "ui";
import { Delete, Edit } from "@mui/icons-material";
import { t } from "i18next";
import { useEditor } from "tiptap/EditorStore";
import { syncQuery } from "serversync";
import { toast } from "react-toastify";

const columns: GridColDef<PrewrittenText>[] = [
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    flex: 1,
  },
  {
    field: "text",
    sortable: false,
    renderCell({ row }) {
      return (
        <IconButtonPreventDefault
          onClick={() => {
            usePrewrittenText.setState({ edit: { ...row } });
          }}
        >
          <Edit />
        </IconButtonPreventDefault>
      );
    },
    width: 50,
  },
  {
    field: "_id",
    sortable: false,
    renderCell({ row }) {
      return (
        <IconButtonPreventDefault
          onClick={async () => {
            const result = await confirm({
              title: t("Are you sure you want to remove {n}?", {
                n: row.name,
              }),
            });
            if (result) {
              removeText(row._id);
            }
          }}
        >
          <Delete />
        </IconButtonPreventDefault>
      );
    },
    width: 50,
  },
];

export function PrewrittenTextDialog() {
  const collection = usePrewrittenText((state) => state.collection);
  const active = usePrewrittenText((state) => state.active);
  const preview = usePrewrittenText((state) => state.preview);
  const dialogOpen = usePrewrittenText((state) => state.dialogOpen);
  const edit = usePrewrittenText((state) => state.edit);
  const editor = useEditor((state) => state.editor);
  const { t } = useTranslation();

  const [create, setCreate] = useState(false);
  const [name, setName] = useState("");
  const [text, setText] = useState("");

  const [search, setSearch] = useState("");

  const [selected, setSelected] = useState<PrewrittenText | null>(null);
  const [page, setPage] = useState(0);

  const filteredCollection = useMemo(
    () =>
      collection.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      ),
    [collection, search]
  );

  const onCreate = () => {
    setCreate(false);
    let content = text;
    if (!content && !editor.isEmpty) {
      const jsonContent = editor.getJSON();
      content =
        jsonContent.content?.reduce((prev, current) => {
          return `${prev}${
            current.content?.reduce((prev, current) => {
              return `${prev}${current.text}`;
            }, "") || ""
          }\n`;
        }, "") || "";
    }
    if (content) {
      addText({ name, updatedAt: Date.now(), _id: uuidv4(), text: content });
    }
    setName("");
    setText("");
  };

  const onSelect = useCallback(() => {
    if (selected) {
      start(selected.text);
      toggleDialog();
    }
  }, [selected]);

  useEffect(() => {
    if (dialogOpen && !create && !edit) {
      addHotkey("Enter", () => {
        onSelect();
      });

      addHotkey("ArrowDown", () => {
        if (!selected) {
          setSelected(filteredCollection[0]);
        } else {
          const index = filteredCollection.indexOf(selected);
          const newSelected = filteredCollection[index + 1];
          !!newSelected && setSelected(newSelected);
        }
      });

      addHotkey("ArrowUp", () => {
        if (!selected) {
          setSelected(filteredCollection[0]);
        } else {
          const index = filteredCollection.indexOf(selected);
          const newSelected = filteredCollection[index - 1];
          !!newSelected && setSelected(newSelected);
        }
      });

      addHotkey("ArrowRight", () => {
        const index = selected ? collection.indexOf(selected) : 0;
        const newIndex = Math.min(index + 5, collection.length - 1);
        setSelected(collection[newIndex]);
      });

      addHotkey("ArrowLeft", () => {
        const index = selected ? collection.indexOf(selected) : 0;
        const newIndex = Math.max(index - 5, 0);
        setSelected(collection[newIndex]);
      });

      return () => {
        removeHotkey("Enter");
        removeHotkey("ArrowDown");
        removeHotkey("ArrowUp");
        removeHotkey("ArrowRight");
        removeHotkey("ArrowLeft");
      };
    }
  }, [dialogOpen, create, selected, filteredCollection, collection, edit]);

  useEffect(() => {
    if (selected) {
      const index = filteredCollection.indexOf(selected);
      const newPage = Math.max(Math.floor(index / 5), 0);
      if (page !== newPage) {
        setPage(newPage);
      }
    }
    // eslint-disable-next-line
  }, [selected, filteredCollection]);

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={toggleDialog}
        fullWidth
        disableRestoreFocus
      >
        <DialogTitle>{t("Pre-written text")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1} padding={1} direction="column">
            <Grid item xs>
              <TextField
                label={t("Search")}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                autoFocus
              />
            </Grid>
            <Grid item xs>
              <DataGrid
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                columns={columns}
                rows={filteredCollection}
                autoHeight
                rowsPerPageOptions={[5]}
                pageSize={5}
                disableColumnMenu
                disableColumnFilter
                headerHeight={0}
                pagination
                localeText={{
                  MuiTablePagination: {
                    labelDisplayedRows: ({ from, to, count }) =>
                      `${from} - ${to} ${t("of")} ${count}`,
                  },
                  noRowsLabel: t("List is empty"),
                }}
                getRowId={(row) => row._id}
                selectionModel={selected ? [selected._id] : []}
                onSelectionModelChange={([id]) => {
                  const newSelection =
                    collection.find((pwt) => pwt._id === id) || null;
                  setSelected(newSelection);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item xs="auto">
              <Button onClick={() => setCreate(true)}>{t("Create new")}</Button>
            </Grid>
            {active && (
              <Grid item xs="auto">
                <Button onClick={togglePreview}>
                  {t(preview ? "Disable preview" : "Enable preview")}
                </Button>
              </Grid>
            )}
            <Grid item xs="auto">
              <Button onClick={onSelect}>{t("Select")}</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen && create} onClose={() => setCreate(false)}>
        <DialogTitle>{t("Create new pre-written text")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1} padding={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                label={t("Name")}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                multiline
                rows={3}
                value={text}
                placeholder={t(
                  "Leave this empty to use content from the text view"
                )}
                onChange={(e) => setText(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCreate}>{t("Create")}</Button>
        </DialogActions>
      </Dialog>
      {!!edit && (
        <Dialog
          open={dialogOpen}
          fullScreen
          onClose={() => usePrewrittenText.setState({ edit: null })}
        >
          <DialogTitle>
            {t("Update")} {edit.name}
          </DialogTitle>
          <DialogContent>
            <Stack padding={1} spacing={1}>
              <TextField
                label={t("Name")}
                value={edit.name}
                onChange={(e) =>
                  usePrewrittenText.setState({
                    edit: { ...edit, name: e.target.value },
                  })
                }
              />
              <TextField
                autoFocus
                fullWidth
                multiline
                value={edit.text}
                onChange={(e) =>
                  usePrewrittenText.setState({
                    edit: { ...edit, text: e.target.value },
                  })
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => usePrewrittenText.setState({ edit: null })}>
              {t("Cancel")}
            </Button>
            <Button
              onClick={async () => {
                const now = Date.now();
                const updatedText = await syncQuery.query("updateText", {
                  id: edit._id,
                  text: {
                    text: edit.text,
                    name: edit.name,
                    updatedAt: now,
                  },
                });

                if (updatedText._id === edit._id) {
                  usePrewrittenText.setState(({ collection }) => ({
                    collection: [
                      ...collection.filter((text) => text._id !== edit._id),
                      { ...edit, updatedAt: now },
                    ],
                    edit: null,
                  }));
                } else {
                  toast.error(t("Failed to update text"));
                  usePrewrittenText.setState({ edit: null });
                }
              }}
            >
              {t("Update")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
