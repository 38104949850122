import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  addEmptyList,
  addListFromMerged,
} from "contextproviders/Abbreviations/lists";

export default function NewListDialogButton() {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = () => {
    if (checked) {
      addListFromMerged(value);
    } else {
      addEmptyList(value);
    }

    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setValue("");
    }
  }, [open]);

  return (
    <div>
      <Button variant="text" onClick={handleClickOpen} color="inherit">
        {t("New list")}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("New list")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("Create new list")}</DialogContentText>
          <FormGroup>
            <FormControlLabel
              disabled={false}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              }
              label={t("Fill with abbreviations from active lists")}
            />
          </FormGroup>

          <TextField
            onKeyPress={(e) => e.key === "Enter" && handleCreate()}
            autoFocus
            margin="dense"
            id="name"
            label={t("Name")}
            type="text"
            fullWidth
            variant="standard"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <Button onClick={handleCreate}>{t("Create")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
