import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Paper, Title, Toolbar } from "ui";
import { Keybindings, useKeybindings } from "./KeybindingsStore";

const keyBindingsText: Keybindings = {
  abbreviateWithoutSpace: "Abbreviate in place",
  //spaceWithoutAbbreviating: "Add space (or hidden space) without abbreviating",
  lookupWord: "Lookup abbreviation for current word",
  togglePrewrittenDialog: "Toggle pre-written text dialog",
  clearEditor: "Clear all text in editor",
  decreaseFontSize: "Decrease font size",
  increaseFontSize: "Increase font size",
  cycleColorSchema: "Cycle color schemas",
};

const keyBindingsKeys = Object.entries(keyBindingsText) as [
  keyof Keybindings,
  string
][];

export function KeybindingsSettings() {
  const { t } = useTranslation();
  const currentBindings = useKeybindings((state) => state);
  return (
    <>
      <Paper item xs={12}>
        <Toolbar>
          <Title>{t("Key bindings")}</Title>
        </Toolbar>
        <Grid container spacing={1} padding={1}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {t("Press a binding to change it")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {t("MOD is Ctrl on a PC keyboard and Meta on a Mac")}
          </Grid>
          {keyBindingsKeys.map(([binding, text]) => (
            <Grid item xs={12} container alignItems="center" gap={1} key={text}>
              <Grid item xs="auto" sx={{ minWidth: 200 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    currentBindings.catchKeyForBinding(binding);
                  }}
                >
                  {currentBindings[binding]}
                </Button>
              </Grid>
              <Grid item xs>
                <Typography>{t(text)}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Dialog open={currentBindings.showModal}>
        <DialogContent>
          <Typography>{t("Press any button")}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
